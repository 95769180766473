import React, { useEffect, useState } from 'react'
import { CreateWhatsappAPI, DeleteWhatsappAPI, getWhatsappApi, resetWhatsAppGateway, testwhatAppGateway } from '../services/apiServices.js';
import { MDBAlert, MDBBtn, MDBCol, MDBDatatable, MDBIcon, MDBInput, MDBInputGroup, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow, MDBTextArea } from 'mdb-react-ui-kit';
import {
    isEmpty,
} from "../validators/helper.js";
import PageError from './PageError.js';
import ReactGA from 'react-ga';

function WhatsappGateway() {
    // const [inputText, setInputText] = useState("");
    const [apiError, setApiError] = useState(false);
    const [Alerttxt, setAlerttxt] = useState("");
    const [whatsappgateway, setwhatsappgateway] = useState([]);
    const [editwhatsappgateway, setEditwhatsappgateway] = useState(false);
    const [form1, setForm1] = useState(
        {
            name: 'WATI',
            accesstoken: "",
            gymid: "",
            _id: "",
            apiendpoint: "",
            primary: true
        },
    );
    const toggleShow2 = () => setEditwhatsappgateway(!editwhatsappgateway);
    const Setvalue = () => setForm1({ name: "", accesstoken: "", gymid: "", _id: "", apiendpoint: "", primary: true });
    const [addGateWay, setAddGateWay] = useState(false);
    const toggleShow4 = () => setAddGateWay(!addGateWay);
    const [error, setError] = useState({});
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const toggleShow5 = () => setDeleteConfirm(!deleteConfirm);
    const [ResetToggle, setResetToggle] = useState(false);
    const [testToken, setTestToken] = useState(false)
    const [refresh, setRefresh] = useState(false)
    // const []
    // let inputHandler = (e) => {
    //     var lowerCase = e.target.value.toLowerCase();
    //     setInputText(lowerCase);
    // };

    const handleReload = () => {
        window.location.reload();
    }
    console.log(form1);
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);

    async function DeleteApi(e) {
        if (form1._id && form1._id !== "") {
            await DeleteWhatsappAPI(form1._id);

            // setwhatsappgateway(prevState => {
            //     const updatedValue = prevState.filter(obj => obj._id !== form1._id);
            //     return updatedValue;
            // });
            setDeleteConfirm(false);
            Setvalue();
            setRefresh(true)
        }
        // await googleAnalytic("whatsappGateway", 'Whatsapp Templete', 'Delete Button', 'Whatsapp Api Deleted')
        // ReactGA.event({
        //     category: 'Sms Templete',
        //     action: 'Delete Button',
        //     label: 'Sms Api Deleted',
        // });
        setDeleteConfirm(false);
        Setvalue();
    }

    async function AddAPIsms() {
        let gymid = JSON.parse(localStorage.getItem("loggedUserInfo")).gymid;
        console.log(form1);
        const updates = {
            name: form1.name,
            senderid: form1.senderid,
            type: form1.type,
            accesstoken: form1.accesstoken,
            gymid: gymid,
            id: form1._id ? form1._id : -1,
            apiendpoint: form1.apiendpoint,
            primary: form1.primary,
        }
        console.log(updates);
        let errors = validateForm();

        if (errors === true) {
            const data = {
                endpoint: form1.apiendpoint,
                token: form1.accesstoken
            }
            const res = await testwhatAppGateway(data, gymid)

            console.log(res);

            if (res.status === 200) {
                console.log("it is valid");
                let saverep = await CreateWhatsappAPI(updates, gymid);
                if (saverep === "false") {
                    setApiError(true)
                    return;
                }
                const recinfo = await saverep.json();
                console.log(recinfo);
                setAlerttxt("Gateway added successfully")
                const response = await getWhatsappApi(gymid);
                const smsapi = await response.json();
                setForm1({})
                console.log(smsapi);
                if (smsapi.length > 0) {
                    setwhatsappgateway(smsapi);
                }
                setAddGateWay(false);
                setEditwhatsappgateway(false);
                Setvalue();
            } else {
                setTestToken(true)
            }
            // console.log(recinfo);

        } else {
            setError(errors)
            console.log(errors);
        }

    }
    useEffect(() => {
        Setvalue();
        fetchData();

    }, [refresh]);
    function updateForm1(value) {
        return setForm1((prev) => {
            return { ...prev, ...value };
        });
    }
    async function resetTempleteHandler(e) {
        setResetToggle(true);
        e.preventDefault();
        let gymid = JSON.parse(localStorage.getItem("loggedUserInfo")).gymid;
        let saverep = await resetWhatsAppGateway(gymid);
        let response = await saverep.json();
        console.log(response);
        setForm1((prev) => {
            return { ...prev, ...response.whatsappgateway };
        });
    }
    async function fetchData() {
        if (localStorage.getItem("loggedUserInfo")) {
            const response = await getWhatsappApi(
                JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
            );
            // if(response){
            //     return;
            // }
            if (response === "false") {
                setApiError(true)
                return;
            }

            const gateway = response && await response.json();

            console.log(gateway);
            if (gateway.length > 0) {
                Setvalue();
                setwhatsappgateway(gateway);
            }
            // else {
            // await AddAPIsms();
            // }

        }
        setRefresh(false)

    }

    const whatsAppapigate = whatsappgateway.filter((el) => {
        let updatedInfo = el;
        // if (inputText !== "") {
        //     updatedInfo = updatedInfo &&
        //         (el.name && el.name.toLowerCase().includes(inputText))
        // }
        // if (planNme != "") {
        //     updatedInfo = updatedInfo &&
        //         (el.type && el.type.includes(planNme))
        // }
        return updatedInfo;
    });
    let tmrowA = [];

    whatsAppapigate.forEach((row, index) => {
        let rarray = [];
        rarray.push(index + 1);
        rarray.push(row.name);
        // rarray.push(row.type);
        rarray.push(row.accesstoken);
        rarray.push(row.apiendpoint);
        rarray.push(row.primary === true ? "Enable" : "Disable")
        rarray.push(
            <><div>
                <MDBIcon icon='pen' className='me-4 fs-6 text-secondary ' onClick={() => { toggleShow2(); setForm1(row); }} />
                <MDBIcon icon='trash' className='fs-6 text-secondary' onClick={() => { toggleShow5(); setForm1(row); }} />
            </div></>
        );
        tmrowA.push(rarray);
    });
    const whatsappgateway1 = {
        columns: [
            "S.no",
            "Name",
            // "Type",
            "Access Token",
            "Api Endpoint",
            "primary",
            "Actions",
        ],
        rows: tmrowA,
    };
    let validateForm = (e) => {
        console.log("Validating form...");
        let errors = {};
        if (isEmpty(form1.name)) {
            errors.name = "Name can't be blank";
        }

        if (isEmpty(form1.accesstoken)) {
            errors.accesstoken = "accesstoken can't be blank";
        }

        setError(errors);
        if (isEmpty(errors)) {
            return true;
        } else {
            console.log("errorrrr")
            return errors;
        }
    };
    // console.log(error.accesstoken);
    return (
        <> {apiError ? <PageError handleReload={handleReload} /> :

            <div>

                <div className='sms-gatewaytable'>
                    <div className='d-flex align-items-center justify-content-end mob-block mb-2'>

                        <MDBBtn className='ms-2' color='success' onClick={toggleShow4}>
                            <MDBIcon fas icon="plus" className='me-2' />Add
                        </MDBBtn>
                    </div>
                    <MDBDatatable searchLabel="Search by SMS Gateway Details" fixedHeader maxHeight='70vh' hover data={whatsappgateway1} className="Transaction__table sno" />
                </div>
                <MDBModal show={editwhatsappgateway} setShow={setEditwhatsappgateway} open={editwhatsappgateway} setOpen={setEditwhatsappgateway} tabIndex='-1'>
                    <MDBModalDialog centered>
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle><MDBIcon fas icon="pen" className='me-2' /> Edit WhatsApp Gateway</MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={toggleShow2}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <MDBRow>
                                    <MDBCol>
                                        <label>Name<span className="text-danger">*</span></label>
                                        <MDBInputGroup className='mb-3' size='lg'>
                                            <input className={form1.name === "" ? 'is-invalid form-control' : 'form-control'} type='text' placeholder="Name"
                                                value={form1.name}
                                                onChange={(e) => updateForm1({ name: e.target.value })} />

                                        </MDBInputGroup>
                                        {error.name && (
                                            <>
                                                <p className="clr-red">{error.name}</p>
                                            </>
                                        )}
                                    </MDBCol>

                                </MDBRow>

                                <label className=' mt-4'>API Key<span className="text-danger">*</span></label>
                                <MDBTextArea id='textAreaExample' rows={2}
                                    className={form1.accesstoken === "" ? 'is-invalid ' : ''}
                                    value={form1.accesstoken}
                                    onChange={(e) => updateForm1({ accesstoken: e.target.value })}
                                />

                                {error.accesstoken && (
                                    <>
                                        <p className="clr-red">{error.accesstoken}</p>
                                    </>
                                )}

                                <label className=' mt-4'>API Endpoint<span className="text-danger">*</span></label>
                                <MDBTextArea id='textAreaExample' rows={2}
                                    className={form1.apiendpoint === "" ? 'is-invalid ' : ''}
                                    value={form1.apiendpoint}
                                    onChange={(e) => updateForm1({ apiendpoint: e.target.value })}
                                />
                                <div className="col-md-4 gym-section gym-half d-flex flex-row align-items-center   my-0">
                                    <label className="mb-0 switchlabel">Primary:</label>
                                    <label className="switch">
                                        <input className="form-control" type="checkbox"
                                            disabled={form1.primary}
                                            checked={form1.primary} onChange={() => updateForm1({ primary: !form1.primary })} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </MDBModalBody>
                            <MDBModalFooter className='pb-2 pe-2'>
                                <MDBBtn color='warning' onClick={(e) => resetTempleteHandler(e)}>Reset</MDBBtn>
                                <MDBBtn color='secondary' onClick={() => { setForm1({}); toggleShow2(); Setvalue(); }}>
                                    Close
                                </MDBBtn>
                                <MDBBtn onClick={AddAPIsms}>Save</MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
                <MDBModal show={addGateWay} setShow={setAddGateWay} open={addGateWay} setOpen={setAddGateWay} tabIndex='-1'>
                    <MDBModalDialog centered>
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle><MDBIcon fas icon="plus" className='me-2 fs-6' /> Add WhatsApp Gateway</MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={toggleShow4}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <MDBRow>
                                    <MDBCol>

                                        <label>Name<span className="text-danger">*</span></label>
                                        <MDBInputGroup className='mb-3' size='lg'>
                                            <input className={form1.name == "" ? 'is-invalid form-control' : 'form-control'} type='text' placeholder="Name"
                                                value={form1.name}
                                                onChange={(e) => updateForm1({ name: e.target.value })} />
                                        </MDBInputGroup>
                                    </MDBCol>

                                </MDBRow>

                                <label className=' mt-4'>API Key<span className="text-danger">*</span></label>
                                <MDBTextArea id='textAreaExample' rows={2}
                                    value={form1.accesstoken}
                                    className={form1.accesstoken == "" ? 'is-invalid ' : ''}
                                    onChange={(e) => updateForm1({ accesstoken: e.target.value })}
                                />

                                <label className=' mt-4'>API Endpoint<span className="text-danger">*</span></label>
                                <MDBTextArea id='textAreaExample' rows={2}
                                    className={form1.apiendpoint === "" ? 'is-invalid ' : ''}
                                    value={form1.apiendpoint}
                                    onChange={(e) => updateForm1({ apiendpoint: e.target.value })}
                                />
                                <div className="col-md-4 gym-section gym-half d-flex flex-row align-items-center   my-0">
                                    <label className="mb-0 switchlabel">Primary:</label>
                                    <label className="switch">
                                        <input className="form-control" type="checkbox" disabled={whatsAppapigate.length > 0 ? false : true} checked={form1.primary} onChange={() => updateForm1({ primary: !form1.primary })} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn color='secondary' onClick={() => { toggleShow4(); Setvalue() }}>
                                    Close
                                </MDBBtn>
                                <MDBBtn onClick={AddAPIsms}>Add</MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
                <MDBModal show={deleteConfirm} setShow={setDeleteConfirm} open={deleteConfirm} setOpen={setDeleteConfirm} tabIndex='-1'>
                    <MDBModalDialog size="sm" centered>
                        <MDBModalContent>
                            <MDBModalBody>
                                <strong>
                                    Are you sure you want to delete this whatsApp Gateway??
                                </strong>
                            </MDBModalBody>

                            <MDBModalFooter>
                                <MDBBtn color='secondary' onClick={() => { toggleShow5(); Setvalue() }}>
                                    Cancel
                                </MDBBtn>
                                <MDBBtn onClick={(e) => DeleteApi(e)}>Delete</MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
                <MDBAlert
                    color='success'
                    autohide
                    position='top-right'
                    delay={2000}
                    appendToBody
                    open={ResetToggle}
                    onClose={() => setResetToggle(false)}
                >Reset Done successfully
                </MDBAlert>

            </div>}
            <MDBAlert
                color="success"
                autohide
                position='top-center'
                delay={5000}
                appendToBody
                open={Alerttxt != ""}
                onClose={() => setAlerttxt("")}
            >
                {Alerttxt}
            </MDBAlert>
            <MDBAlert
                color="danger"
                autohide
                position='top-center'
                delay={4000}
                appendToBody
                open={testToken}
                onClose={() => setTestToken(false)}
            >
                Please Enter valid Gateway
            </MDBAlert>
        </>
    )
}

export default WhatsappGateway