import { MDBAlert, MDBAutocomplete } from "mdb-react-ui-kit";
import { React, useEffect, useState } from "react";
import { browserName, browserVersion } from "react-device-detect";
import { format } from "date-fns";
import ReactDOMServer from "react-dom/server";
import BillInvoice from "../views/BillInvoice.js";
import { logEvent, sendPaymentLink, getGBussinessInfo, uploadInvoice, getInvoicehtmlcontent, uploadInvoicePaymentlink, } from "../services/apiServices.js";
import logo from "../components/images/GymLogo.png";
import rupe from "./../components/images/Rupee.png"
const PaymentLink = (props) => {
    const [number, setNumber] = useState("");
    const [Alerttxt, setAlerttxt] = useState("");
    const [EmptyNumberValidation, setEmptyNumberValidation] = useState(false);
    const [userdata, setUserdata] = useState([])
    const [imageLoaded, setImageLoaded] = useState(true);
    const logourl = (localStorage.getItem("loggedUserInfo") ? (JSON.parse(localStorage.getItem("loggedUserInfo")).gymlogoUrl ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymlogoUrl : logo) : logo)
    const [transactiond, settransactiond] = useState(localStorage.getItem("transactionId"))
    const gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
    const [apiError, setApiError] = useState(false);
    useEffect(() => {

        const handleImageLoad = () => {
            setImageLoaded(true);
        };

        const handleImageError = () => {
            setImageLoaded(false);
        };
        const imgElement = document.createElement("img");
        imgElement.onload = handleImageLoad;
        imgElement.onerror = handleImageError;
        imgElement.src = logourl;

        return () => {
            imgElement.onload = null;
            imgElement.onerror = null;
        };
    }, [logourl]);
    let membersArray = props.selecteditems.reduce((accumulator, currentItem) => {
        if (currentItem.members && currentItem.members.length > 0) {
            let uniqueNumbers = [];
            let modifiedMembers = currentItem.members.filter((member) => {
                if (member.number == "") {
                    return false;
                }
                if (!accumulator.some((obj) => obj.number === member.number) && !uniqueNumbers.includes(member.number)) {
                    uniqueNumbers.push(member.number);
                    return true;
                }
                return false;
            });
            return accumulator.concat(modifiedMembers);
        } else {
            return accumulator;
        }
    }, []);

    async function printhandler(tselItems, tmdata) {
        let listeditem = tselItems;
        console.log(listeditem);
        const data = userdata;

        const billdata = listeditem.map((value, index) => {
            const taxIncluded = value.Item.taxcheck ? "Included" : "Excluded";
            return {
                sno: index + 1,
                name: value.Item.name,
                qty: value.quantity,
                price: value.Item.price * value.quantity,
                check: value.Item.taxcheck,
                discount: value.members && value.members.reduce((memberAcc, member) =>
                    memberAcc + (member.discount ? member.discount.amount : 0), 0),
                overAllTax: value.taxamounts ? value.taxamounts : [{ TaxCheck: `${taxIncluded}` }],
                cost: value.Item.taxcheck ?
                    parseFloat((value.Item.price * value.quantity) /
                        (1 + (value.taxamounts.reduce((acc, obj) => acc +
                            (obj.percentage ? (parseFloat(obj.percentage) / 100) : 0), 0)))).toFixed(2)
                    : value.Item.price * value.quantity,

                discountper: value.members.reduce((memberAcc, member) => {
                    const discountValue = member.discount ? member.discount.EnterValue : 0;
                    const actualamount = value.Item.taxcheck ?
                        parseFloat((value.Item.price * value.quantity) /
                            (1 + (value.taxamounts.reduce((acc, obj) => acc +
                                (obj.percentage ? (parseFloat(obj.percentage) / 100) : 0), 0)))).toFixed(2)
                        : value.Item.price * value.quantity;

                    const memberDiscount = member.discount && member.discount.discountType === "percent" ?
                        discountValue : (discountValue / actualamount) * 100;

                    return memberAcc + memberDiscount;
                }, 0),
            };
        });

        console.log(billdata);

        let userdetails = {
            name: data.firstName + " " + data.lastName,
            number: data.contactNumber,
            state: data.state,
            business: data.businessName,
            city: data.city,
            address: data.address,
            membername: listeditem.length > 0 && listeditem[0].members.length > 0 ? (listeditem[0].members[0].fname + ' ' + listeditem[0].members[0].lname) : "",
            membernumber: listeditem.length > 0 && listeditem[0].members.length > 0 ? listeditem[0].members[0].number : "",
            memberaddress: listeditem.length > 0 && listeditem[0].members.length > 0 ? listeditem[0].members[0].address : "",
            membercity: listeditem.length > 0 && listeditem[0].members.length > 0 ? listeditem[0].members[0].city : "",
            membercuntry: listeditem.length > 0 && listeditem[0].members.length > 0 ? listeditem[0].members[0].country : "",
            invoice: parseFloat(data._id) + format(new Date(), "MM"),
            gst: data.dgsttax.length > 0 && data.dgsttax[0] === 1 ? data.gstnumber : "",
        }
        console.log(userdetails);
        let taxdetails = data;
        let date = format(new Date(), "dd-MM-yyyy");
        let payObj = {
            dueAmount: 0,
            paidAmount: tmdata.isplan && tmdata.isplan === 3 ? tmdata.totalPrice : props.price,
            totalPrice: tmdata.isplan && tmdata.isplan === 3 ? tmdata.totalPrice : props.price,
        }
        const updates = {
            logourl: logourl,
            userdetails: userdetails,
            taxdetails: taxdetails,
            payObj: payObj,
            billdata: billdata,
            transactiond: transactiond,
            totalPrice: payObj.totalPrice,
            date: date
        }
        const htmlContent = await getInvoicehtmlcontent(updates, transactiond)
        if (htmlContent === "false") {
            setApiError(true)
            return
        }
        const record = await htmlContent.json();

        console.log(record, '9999999999999999999');
       
        /**
          * Upload Invoice to server
          */
        try {
            const saverep = await uploadInvoicePaymentlink(
                { htmlContent: record.htmlContent, invoiceId: transactiond, Gid: gymid },
                transactiond,
            );
            console.log("uploaded...");
        } catch (e) {
            console.log("Errore::::::::::::::::: " + e);
        }
    }

    async function sendPaymentLinkHandler(e) {
        e.preventDefault();
        let tsdata = JSON.parse(localStorage.getItem("selItems"));
        let tmdata = JSON.parse(localStorage.getItem("allData"));
        console.log(tsdata);
        console.log(tmdata);
        let transDetails = [];
        if (tmdata.isplan && tmdata.isplan === 3) {
            tsdata.map((obj) => {
                let newItem = {
                    isplan: obj.isplan,
                    token: obj.token,
                    tid: obj.ref_tId,
                    price: obj.price,
                    createdate: props.createdate,
                    staffid: JSON.parse(localStorage.getItem("loggedUserInfo"))._id,
                };
                transDetails.push(newItem);
            })
        } else {
            tsdata.map((obj) => {
                obj.members.map((member) => {
                    let newItem = {
                        planid: obj.Item._id,
                        isplan: obj.isplan,
                        memberid: member._id,
                        startdate: obj.idate,
                        quantity: obj.quantity,
                        taxamounts: obj.taxamounts,
                        extend: obj.extendmember && obj.extendmember.includes(member._id),
                        createdate: new Date(tmdata.transDate),
                        staffid: JSON.parse(localStorage.getItem("loggedUserInfo"))._id,
                    };
                    if (member && member.discount !== null) {
                        newItem.discount = member.discount;
                    }
                    transDetails.push(newItem);
                })
            })
        }
        console.log(transDetails);
        let tid = localStorage.getItem("transactionId");
        let gymid = JSON.parse(localStorage.getItem("loggedUserInfo")).gymid;
        let filterData = membersArray.filter((member) => member.number === number);
        let memberarr = membersArray.length > 0 ? membersArray[0] : props.selecteditems[0].members[0]
        let membObject = filterData.length > 0 ? filterData[0] : memberarr;
        let membName = membObject.fname + " " + membObject.lname;
        let updates = {
            number: number,
            amount: props.price,
            gymid: gymid,
            tid: tid,
            membName: membName,
            clientUrl: window.location.origin,
            transdetails: transDetails,
        };
        console.log(updates);
        if (number != "") {
            let response = await sendPaymentLink(gymid, updates);
            if (response.status === 200) {
                let resObj = await response.json();
                console.log(resObj);
                console.log("Encrypted payment url::", resObj.encodePaymentLink);
                console.log("generated payment url::", resObj.genratedpaymentLink);
                let flag = `Payment Link Sent Successfully (${resObj.response.number})`;
                if (resObj.error) {
                    if (resObj.error == "error in generating link") {
                        setAlerttxt("Failed to generate payment link. try again");

                    } else {
                        setAlerttxt("Failed to send SMS of payment link. try again");
                    }
                }
                if (resObj.response && resObj.response._id) {
                    setAlerttxt("payment link sent");
                    props.paymentLinkSent(resObj.response._id);
                    printhandler(tsdata, tmdata);
                } else {
                    flag = `Payment Link Failed (${resObj.response.number})`;
                    console.log("link not sent to user");
                }
                let Eventtype = "payment Link";
                let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : '';
                let ip = localStorage.getItem("ipaddress");
                let browserinfo = browserName + "-" + browserVersion + ".0.0";
                logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);
            }
        } else {
            setEmptyNumberValidation(true);
        }
    }

    const defaultData = membersArray.length > 0 ? membersArray.map((member) => (member.number)) : [];
    const [data, setData] = useState(defaultData);
    const onSearch = (value) => {
        setNumber(value);
        setData(defaultData.filter((item) => item.toLowerCase().includes(value.toLowerCase())));
    }
    useEffect(() => {
        if (defaultData.length > 0) {
            setNumber(defaultData[0]);
        }
        const fetchData = async () => {
            const response = await getGBussinessInfo(
                JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
            );
            if (response) {
                const record = await response.json();
                setUserdata(record)
            }
        }
        fetchData();
    }, []);

    return (
        <>
            <div className="d-flex align-items-center py-4">
                <label>
                    Send payment link to:
                </label>
                <div className="ms-3" >
                    <MDBAutocomplete data={data} label="Enter Phone Number" onSearch={onSearch} value={number} />
                </div>
            </div>

            {EmptyNumberValidation && number === "" &&
                <label className="clr-red align-items-center"> Field can't be empty. Please select or add a number.</label>}
            <div class="modal-footer pb-0">
                <button class="ripple ripple-surface btn btn-secondary" role="button" onClick={() => props.close()}>
                    close
                </button>
                <button class="ripple ripple-surface btn btn-primary" role="button" onClick={(e) => sendPaymentLinkHandler(e)} disabled={number == ""}>
                    Send
                </button>
            </div>
            <MDBAlert
                color={Alerttxt.includes("ailed") ? "danger" : "success"}
                autohide
                position='top-right'
                delay={2000}
                appendToBody
                open={Alerttxt != ""}
                onClose={() => setAlerttxt("")}
            >
                {Alerttxt}
            </MDBAlert>
        </>
    )
};

export default PaymentLink;