

import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import ReactGA from 'react-ga';
import { useNavigate } from "react-router";
import Popup from "../components/Popup";
import LogATime from "../components/logatime";
import SubPopup from "../components/supPopup";
import Timer from "../components/timer";
import {
  deleteCheckin,
  getAttendanceList,
  logEvent
} from "../services/apiServices";
import calImg from "./../components/images/calendar.svg";
import logtimeImg from "./../components/images/log_timeGreen.svg";
import { MDBBtn, MDBIcon, MDBModalFooter } from "mdb-react-ui-kit";
import { browserName, browserVersion } from "react-device-detect";
import { googleAnalytic } from "../components/googleAnalytics";

const AttendanceList = (props) => {
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const [usersData, setData] = useState("");
  const [checkedStaff, setCheckedStaff] = useState([]);
  const navigate = useNavigate();
  const [addandSave, setaddandSave] = useState(1);
  const [sId, setSId] = useState(null);
  const [DeleteCheckin, setDeleteCheckin] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [logtimeShow, setLogtimeShow] = useState(false);
  const [timetoshow, setTimetoshow] = useState(props.data.chkin)
  const [log, setLog] = useState('')
  const [value, setvalue] = useState('');
  const [latestCheckInDelete, setLatestCheckInDelete] = useState(null);


  const togglePopup = () => {
    setIsOpen(!isOpen);
  };


  function openAddform(e) {
    setSId(0);
    setaddandSave(1);
    togglePopup();
  }

  async function deleteStaff(tobject) {
    if (window.confirm("Are you sure want to delete this Staff Member?")) {
    }
  }

  function openLogTime() {
    setLogtimeShow(true);
    setLog('Log Time')
  }

  function CheckInUpdate() {
    let todaydate = new Date();
    const firstDay = new Date(todaydate);
    firstDay.setDate(todaydate.getDate() - 30);
    firstDay.setHours(0, 0, 0, 0);
    const lastDay = new Date(todaydate);
    lastDay.setDate(todaydate.getDate() + 1);
    setDateRange([firstDay, lastDay]);
    fetchData(getRDateTime(firstDay).date, getRDateTime(lastDay).date);
  }

  function checkMember(updates, member) {
    console.log(updates)
    props.onsave(updates, member);
    // if (updates.checkFlag == "1") {
    //   let value = {
    //     bId: JSON.parse(localStorage.getItem("loggedUserInfo")).gymid,
    //     createdAt: new Date(),
    //     updatedAt: new Date(),
    //     autocheckout: updates.autocheckout,
    //     date: updates.date,
    //     id: updates.mid,
    //     intime: updates.date,
    //     member_type: "1",
    //     // _id: mId,
    //   }
    //   usersData.attendance.unshift(value)
    // }
    // else {
    // usersData.attendance.sort((a, b) =>a.updatedAt-b.updatedAt)
    // usersData.attendance[0].outtime = updates.date
    // }
    if (updates.checkFlag == "1") {
      props.data.checkedin = true;
    } else {
      props.data.checkedin = false;
    }
    // CheckInUpdate();
    closeLogTime()
  }
  function closeLogTime() {
    setLogtimeShow(false);
  }
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  function openEditStaff(tId) {
    setSId(tId.id);
    togglePopup();
  }

  async function checkinDelete(e, data) {
    e.preventDefault();
    console.log(data);
    let resp = await deleteCheckin(data._id);
    let ip = localStorage.getItem("ipaddress");
    let browserinfo = browserName + "-" + browserVersion + ".0.0";
    let flag = `Member Checkin Deleted Successfull (${props.data.fname} ${props.data.lname})`;
    let Eventtype = "Checkins";
    let gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
    let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : "";
    await googleAnalytic("AttendanceList", "Member", 'Delete Button', 'Member Check-In Deleted')
    // ReactGA.event({
    //   category: 'Member',
    //   action: 'Delete Button',
    //   label: 'Member Check-In Deleted',
    // });
    logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);
    setData(prevState => {
      const updatedValue = prevState.attendance.filter(obj => data._id !== obj._id);
      return { ...prevState, attendance: updatedValue };
    });
    setDeleteCheckin(null);
  }
  // let latestCheckInDelete = true;
  async function fetchData(fdate, tdate) {
    console.log(props)
    const response = await getAttendanceList(props.id, {
      fdate: fdate,
      tdate: tdate,
    });
    if (!response) {
      return;
    }
    if (response == "false") {
      return;
    }
    const usersData = await response.json();

    if (!usersData) {

      navigate("/");
      return;
    }
    setData(usersData);
    let latestCheckins = usersData.attendance.sort((a, b) => (new Date(b.intime) - new Date(a.intime)));
    if (latestCheckins.length > 0 && latestCheckins[0].intime && !latestCheckins[0].outtime) {
      setLatestCheckInDelete(latestCheckins[0]);

      // latestCheckInDelete = false;
    }
    console.log(usersData);
  }
  function getDateRange(value) {
    console.log(value);
    setDateRange(value);
    if (value && value.length === 2) {
      let fdate = getRDateTime(value[0]).date;
      let tdate = getRDateTime(value[1]).date;
      console.log(fdate + ", " + tdate);
      fetchData(fdate, tdate);
    }
  }
  function getRDateTime(tdbtime) {
    var now = new Date(tdbtime);
    var year = now.getFullYear();
    var month = now.getMonth() + 1;
    var day = now.getDate();
    var hour = now.getHours();
    var minute = now.getMinutes();
    var second = now.getSeconds();
    if (month.toString().length == 1) {
      month = "0" + month;
    }
    if (day.toString().length == 1) {
      day = "0" + day;
    }
    if (hour.toString().length == 1) {
      hour = "0" + hour;
    }
    if (minute.toString().length == 1) {
      minute = "0" + minute;
    }
    if (second.toString().length == 1) {
      second = "0" + second;
    }
    var dateTime = {
      date: year + "-" + month + "-" + day,
      time: hour + ":" + minute,
    };
    return dateTime;
  }
  function getDateTime(tdbtime) {
    var now = new Date(tdbtime);
    var year = now.getFullYear();
    var month = now.getMonth() + 1;
    var day = now.getDate();
    var hour = now.getHours();
    var minute = now.getMinutes();
    var second = now.getSeconds();
    if (month.toString().length == 1) {
      month = "0" + month;
    }
    if (day.toString().length == 1) {
      day = "0" + day;
    }
    if (hour.toString().length == 1) {
      hour = "0" + hour;
    }
    if (minute.toString().length == 1) {
      minute = "0" + minute;
    }
    if (second.toString().length == 1) {
      second = "0" + second;
    }
    var dateTime = {
      date: day + "-" + month + "-" + year,
      time: hour + ":" + minute,
    };
    return dateTime;
  }
  const RangeCustomInput = forwardRef(({ value, onClick }, ref) => (
    <span className="gym-date">
      {value === "Log Time" ? "Log Time" : "Log Time"}
      &nbsp;
      <img className="w-16" src={logtimeImg} onClick={onClick} ref={ref} />
    </span>
  ));
  const CalenderInput = forwardRef(({ value, onClick }, ref) => (
    <span className="text-dark">
      {value === "" ? "Custom Range" : value}
      &nbsp;
      <img
        src={calImg}
        onClick={onClick}
        ref={ref}
        title="Select Custom Range"
      />
    </span>
  ));
  function daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }
  // async function checkinUser(e, mId, chekinflag) {
  //   e.preventDefault();
  //   props.data.checkedin = chekinflag == 0 ? false : true;
  //   setTimetoshow(new Date().getTime())
  //   props.oncheckin(e, mId, chekinflag);
  //   /* const updates = {
  //     checkFlag: chekinflag,
  //     mid: mId,
  //     date: new Date(),
  //   }; 
  //   console.log(updates)
  //   let saverep = await checkMembers(updates, mId);*/

  //   //props.data.checkeddate
  // }
  function getDiff(strt1, strt2) {
    var diff = (strt2.getTime() - strt1.getTime()) / 1000;
    diff /= 60;

    return Math.abs(Math.round(diff));
  }
  useEffect(() => {
    let todaydate = new Date();
    const firstDay = new Date(todaydate);
    firstDay.setDate(todaydate.getDate() - 30);
    firstDay.setHours(0, 0, 0, 0);
    const lastDay = new Date(todaydate);
    lastDay.setDate(todaydate.getDate() + 1);
    setDateRange([firstDay, lastDay]);
    fetchData(getRDateTime(firstDay).date, getRDateTime(lastDay).date);
  }, []);
  return (
    <>
      <div className="table-scroll viewattandance" id="contact">
        <div className="CheckInBtn-align">
          <div  >
            <h4 className="text-dark">
              {props.data.fname
                ? props.data.fname + " " + props.data.lname
                : props.data.firstname + " " + props.data.lastname}
            </h4>
            <p className="m-0">
              {props.data.sex ? props.data.sex : ""}{(props.data.sex && (props.data.age || props.data.weight || props.data.number)) ? ', ' : ''}
              {props.data.age ? props.data.age + "y" : ""}{(props.data.age && (props.data.weight || props.data.number)) ? ', ' : ''}
              {props.data.weight ? props.data.weight + "kgs" : ""}{(props.data.weight && props.data.number) ? ', ' : ''}
              {props.data.number ? props.data.number + " " : ""}
            </p>
          </div>
          <div className="d-flex align-center mob-block">
            <div className="view-boxdate mob-block" >
              <DatePicker
                className="slotdrop w-100 m-0"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  getDateRange(update);
                }}
                isClearable={false}
                customInput={<CalenderInput />}
              />
            </div>
            {props.data && !props.data.issuspend && <><div>
              <div
                className={
                  props.data.checkinflag == 1
                    ? "checkindate checkinbuttonout mob-block mob-w-100" : "checkindate mob-block mob-w-100"
                }
              >
                <input
                  type="button"
                  value={
                    props.data.checkinflag == 1 ? "Check-Out" : "Check-In"
                  }
                  className={
                    props.data.checkinflag == 1
                      ? "checkinbuttonout mob-block mob-w-100" : "checkinbutton  mob-block mob-w-100"

                  }
                  onClick={() =>
                  // checkinUser(e, props.data._id, props.data.checkedin)
                  {
                    setLogtimeShow(true)
                    setLog('')
                    setvalue(props.data.checkinflag == 1 ? "Check-Out" : "Check-In")
                  }
                  }
                />
                {props.data.checkinflag == 1
                  && (
                    <p className="d-flex m-inherit f-9">

                      <Timer
                        member={props.data.fname}
                        time={
                          new Date(props.data.checkindate)

                        }
                      />
                    </p>
                  )}
              </div>
            </div>
              <div
                className=" font-12"
                onClick={(e) => openLogTime(e)}
              >
                <span className="logtime font-12 view-boxdate">

                  <img className="w-16" src={logtimeImg} />
                  &nbsp; Log time
                </span>
              </div></>}
          </div>
        </div>
        <div className="daterangemain P-10">
          <div className="datechange">
            <span>
              {props.firstname} {props.lastname}
            </span>
          </div>
        </div>
        <div>
          <div className="FixedtableHeader table-flow sno">
            <table>
              <thead>
                <tr>
                  <th>S.no</th>
                  <th colSpan={2}>Date</th>
                  <th>Check-In</th>
                  <th>Check-Out</th>
                  <th>Hours spent</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {usersData &&
                  usersData.attendance.map((item, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td colSpan={2}>{getDateTime(item.date).date}</td>
                      <td>
                        {getDateTime(item.intime).time === "NaN:NaN"
                          ? " -- "
                          : getDateTime(item.intime).time}
                      </td>
                      <td>
                        {getDateTime(item.outtime).time === "NaN:NaN"
                          ? "-- "
                          : getDateTime(item.outtime).time}
                      </td>
                      <td>
                        {(
                          "0" +
                          Math.floor(
                            getDiff(
                              new Date(item.outtime),
                              new Date(item.intime)
                            ) / 60
                          )
                        ).slice(-2) === "aN"
                          ? "--"
                          : (
                            "0" +
                            Math.floor(
                              getDiff(
                                new Date(item.outtime),
                                new Date(item.intime)
                              ) / 60
                            )
                          ).slice(-2)}
                        :
                        {(
                          "0" +
                          Math.floor(
                            getDiff(
                              new Date(item.outtime),
                              new Date(item.intime)
                            ) % 60
                          )
                        ).slice(-2) === "aN"
                          ? "--"
                          : (
                            "0" +
                            Math.floor(
                              getDiff(
                                new Date(item.outtime),
                                new Date(item.intime)
                              ) % 60
                            )
                          ).slice(-2)}{" "}
                      </td>
                      <td colSpan={2}>
                        {(latestCheckInDelete != null && latestCheckInDelete._id) != item._id &&
                          <MDBIcon fas icon="trash"
                            onClick={(e) => setDeleteCheckin(item)}
                          />}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div >
      {logtimeShow && props.title != "Staff Information" && (
        <SubPopup
          title={log != '' ? log : value}
          ptype="modal-lm"
          content={
            <>
              <div>
                <LogATime
                  id={props.data._id}
                  data={props.data}
                  value={log != '' ? log : value}
                  expires={props.expires}
                  oncancel={closeLogTime}
                  onsave={checkMember}
                />
              </div>
            </>
          }
          handleClose={closeLogTime}
        />
      )
      }
      {
        DeleteCheckin && (
          <Popup
            title="Confirm Delete"
            content={
              <>
                <div className="pb-3">
                  <span className="fs-6 pb-3">Are you sure you want to delete this entry?</span>
                </div>
                <MDBModalFooter>
                  <MDBBtn color='secondary' onClick={(e) => setDeleteCheckin(null)}>
                    Cancel
                  </MDBBtn>
                  <MDBBtn color="danger" onClick={(e) => checkinDelete(e, DeleteCheckin)}>Delete</MDBBtn>
                </MDBModalFooter>
              </>
            }
            handleClose={setDeleteCheckin}
          />
        )
      }
    </>
  );
};

export default AttendanceList;
