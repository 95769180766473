import React, { useEffect, useState } from "react";
import { browserName, browserVersion } from "react-device-detect";
import ReactGA from 'react-ga';
import { getAddonByPId, saveAddon } from "../services/apiServices";
import { logEvent } from "../services/apiServices.js";
import { isEmpty } from "../validators/helper.js";
import PageError from "./PageError";
import { googleAnalytic } from "../components/googleAnalytics";

const CreateAddon = (props) => {
  const [apiError, setApiError] = useState(false);
  const [form, setForm] = useState({
    name: "",
    gender: "1",
    size: "1",
    quantity: "",
    rentbasis: "2",
    price: "",
    enable: true,
    validtimetype: false,
    description: ""
  });
  const [error, setError] = useState({});
  const [isChecked, setIsChecked] = useState(true);

  const [sq, setSq] = useState([
    { propname: "", propvalue: "", quantity: "10" },
  ]);




  const handleReload = () => {
    window.location.reload();
  }

  function updatePrice(obj) {
    console.log(obj);
    setSq((prevState) => {
      const newState = prevState.map((eachObj, index) => {
        // 👇️ if id equals 2, update country property
        if (index === obj.index) {
          return {
            propname: eachObj.propname,
            propvalue: obj.propvalue,
          };
        }
        // 👇️ otherwise return object as is
        return eachObj;
      });
      return newState;
    });
  }

  function updateForm(value) {
    return setForm((prev) => {
      return { ...prev, ...value };
    });
  }
  let validateForm = (e) => {
    console.log("Validating form...");
    let errors = {};
    if (isEmpty(form.name)) {
      errors.name = "Name can't be blank";
    }
    sq.map((item, index) => {
      if (isEmpty(item.propvalue)) {
        if (errors.price === null) {
          errors.price = [];
        }
        errors.price[index] = "Price can't be blank";
      }
      return null;
    });

    console.log(errors);
    if (isEmpty(errors)) {
      return true;
    } else {
      return errors;
    }
  };
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  async function onSubmit(e) {

    e.preventDefault();

    const updates = {
      name: form.name,
      gender: form.gender,
      sq: sq,
      rentbasis: form.rentbasis,
      price: form.price,
      taxcheck: isChecked,
      enable: form.enable,
      validtimetype: form.validtimetype,
      _id: props.id && props.id !== -1 ? props.id : null,
      description: form.description
    };
    console.log(validateForm(e));
    let errors = validateForm(e);
    if (errors === true) {
      console.log(updates);
      let savedObj = await saveAddon(
        updates,
        localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : ""
      );
      if (savedObj === "false") {
        setApiError(true)
        return;
      }
      let addon = await savedObj.json();
      console.log(addon);
      localStorage.setItem("setting_save_addon", "Addon saved successfully");
      console.log(addon);
      let ip = localStorage.getItem("ipaddress");
      let browserinfo = browserName + "-" + browserVersion + ".0.0";
      let gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
      let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : "";
      let Eventtype = "Addon";
      if (props.id === addon._id) {
        props.onsubmit(addon);
        let flag = `Addon Updated Successfull (${updates.name})`;
        await googleAnalytic("Createaddon", 'Addons', 'Save Button', 'Addons Updated')
        // ReactGA.event({
        //   category: 'Addons',
        //   action: 'Save Button',
        //   label: 'Addons Updated',
        // });
        logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);
      }
      else {
        let flag = `Addon Created Successfull (${updates.name})`;
        await googleAnalytic("Createaddon", 'Addons', 'create Button', 'Addons Created')
        // ReactGA.event({
        //   category: 'Addons',
        //   action: 'create Button',
        //   label: 'Create Addons',
        // });
        logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);
        props.onsubmit(addon);

      }

    } else {
      setError(errors);
    }
  }

  useEffect(() => {
    async function fetchData() {
      const response = await getAddonByPId(props.id);
      console.log(response);
      if (!response) {
        return;
      }
      if (response === "false") {
        setApiError(true)
        return;
      }
      const record = await response.json();
      if (!record) {

        return;
      }
      setForm(record);
      setSq(record.props);
    }
    console.log(props.id);
    if (props.id && props.id !== "-1") {
      fetchData();
    }
    return;
  }, [props.id]);
  return (
    <>
      {apiError ? <PageError handleReload={handleReload} /> : <> <div
        className="gym-container"
        id="showcase"
      >
        {" "}
        {/* {smessage && (
          <h4>
            <Toast message={smessage} />
          </h4>
        )} */}
      </div>
        {!props.viewmode && (
          <>
            <div className="gym-container">
              <form
                action="#"
                target="_blank"
                onSubmit={onSubmit}
                className="business-form"
              >
                <div>
                  <div className="gym-section gym-half">
                    <label>Product Name<span className="text-danger">*</span></label>
                    <input
                      className="gym-input"
                      type="text"
                      name="product_name"
                      id="product_name"
                      placeholder="Product Name"
                      value={form.name}
                      onChange={(e) => updateForm({ name: e.target.value })}
                    />
                    {error.name && (
                      <>
                        <p className="clr-red">{error.name}</p>
                      </>
                    )}
                  </div>

                  <div className="gym-section gym-half">
                    <label>Validity:</label>{" "}
                    <select
                      name="rentbasis"
                      id="rentbasis"
                      className="gym-input"
                      onChange={(e) => updateForm({ rentbasis: e.target.value })}
                      value={form.rentbasis}
                    >
                      <option value="1">Daily</option>
                      <option value="2">Monthly</option>
                      <option value="3">Yearly</option>
                    </select>
                  </div>

                  {sq &&
                    sq.map((item, index) => (
                      <>
                        <div className="gym-section gym-half">
                          <label>Price:<span className="text-danger">*</span></label>
                          <input
                            className="gym-input"
                            type="number"
                            name="price"
                            id="price"
                            value={item.propvalue}
                            placeholder="Enter Price"
                            onChange={(e) =>
                              updatePrice({
                                index: index,
                                propvalue: e.target.value,
                              })
                            }
                          />
                          {error.price && error.price[index] && (
                            <>
                              <p className="clr-red mb-0">{error.price[index]}</p>
                            </>
                          )}
                          <p className="mb-0 mt-1">
                            <input type="checkbox" name="tax" checked={isChecked} onChange={() => { setIsChecked(!isChecked) }}></input><label className="ms-2">Include Tax</label>
                          </p>
                        </div>
                      </>
                    ))}
                  {/* <div className="col-3 gym-section gym-half">
                  <label>Validity Formate Type:</label>{" "}
                  <select
                    name="valid_days"
                    id="valid_days"
                    className="gym-input"
                    value={form.validtimetype}
                    onChange={(e) => updateForm({ validtimetype: e.target.value })}
                  >
                    <option value="1" selected> Day wise </option>
                    <option value="2" >hours wise </option>
               
                  </select>
                </div> */}
                  <div className="gym-section gym-half">
                    <div className="d-flex align-items-center">
                      <label>24 Hours: </label>

                      <label className="ms-1 switch">
                        <input className="form-control" type="checkbox" checked={form.validtimetype} onChange={(e) => updateForm({ validtimetype: !form.validtimetype })} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>

                  <div className="gym-section gym-half">
                    <div className="d-flex align-items-center">
                      <label>Enable/Disable Addon: </label>

                      <label className="ms-1 switch">
                        <input className="form-control" type="checkbox" checked={form.enable} onChange={(e) => updateForm({ enable: !form.enable })} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>

                  <div className="w-100 mt-1 gym-section gym-half">

                    <label className="switchlabel">Description: </label>
                    <textarea className='p-2 w-100 textarea'
                      id='form6Example7'
                      label=''
                      rows='5'
                      placeholder='Describe about this Addon here...'
                      name='description'
                      maxLength={1024}
                      value={form.description || ""}
                      onChange={(e) => updateForm({ description: e.target.value })}
                    // inputRef={textAreaRef}

                    />
                  </div>
                  <div className="modal-footer justify-content-start">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      data-mdb-dismiss="modal"
                    >
                      Save
                    </button>
                    &nbsp;&nbsp;
                    <button
                      to="#"
                      onClick={props.onclose}
                      className="btn btn-secondary"
                      data-mdb-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </>}</>
  );
};

export default CreateAddon;
