
import React, { useEffect, useRef, useState, forwardRef } from "react";
import PageError from "./PageError.js";
import { MDBBtn, MDBDatatable, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle, MDBTooltip } from "mdb-react-ui-kit";
import { getRevenueReports } from "../services/apiServices.js";
import DatePicker from 'react-datepicker';
import calImg from "./../components/images/calendar.svg";
import ExcelExportButton from "../components/exportToExcel.js";
import Loader from "../components/Loader.js";
import BillInvoice from "./BillInvoice.js";


function RevenueReport() {
    const [memberData, setmemberData] = useState([])
    const [apiError, setApiError] = useState(false);
    const [DayFilter, setDayFilter] = useState("4");
    const [rangeFilter, setRangeFilter] = useState("");
    const [loading, setLoading] = useState(false)
    const [basicData, setBasicData] = useState({
        columns: [
            { label: 'Date', field: 'date' },
            { label: 'Member Name', field: 'memberName' },
            { label: 'Plan', field: 'plan' },
            { label: 'Addon', field: 'addon' },
            { label: 'Total Amount', field: 'totalAmount' },
            { label: 'Transaction Type', field: 'transactionType' },
        ],
        rows: [],
    });
    const [card, setCard] = useState({
        new: 0,
        renew: 0,

    })
    const searchRef = useRef();
    const [customdate, setcustomdate] = useState(DayFilter === "6");
    const [customRange, setCustomRange] = useState(rangeFilter === "5")
    const today = new Date();
    const currentTime = new Date();
    const startOfToday = new Date(currentTime);
    startOfToday.setHours(0, 0, 0, 0);

    const endOfToday = new Date(currentTime);
    endOfToday.setHours(23, 59, 59, 999);

    const yesterday = new Date(currentTime);
    yesterday.setDate(currentTime.getDate() - 1);
    const startOfYesterday = new Date(yesterday);
    startOfYesterday.setHours(0, 0, 0, 0);

    const endOfYesterday = new Date(yesterday);
    endOfYesterday.setHours(23, 59, 59, 999);

    const startOfWeek = new Date(currentTime);
    startOfWeek.setDate(currentTime.getDate() - currentTime.getDay());
    startOfWeek.setHours(12, 0, 0, 0);
    const endOfWeek = new Date(currentTime);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(12, 0, 0, 0);

    const startOfMonth = new Date(currentTime);
    startOfMonth.setDate(1);
    startOfMonth.setHours(0, 0, 0, 0);

    const endOfMonth = new Date(currentTime);

    const startOfYear = new Date(currentTime);
    startOfYear.setMonth(0, 1);
    startOfYear.setHours(0, 0, 0, 0);

    const endOfYear = new Date(currentTime);
    endOfYear.setMonth(11, 31);
    endOfYear.setHours(23, 59, 59, 999);
    today.setHours(23, 59, 0, 0);
    const [dateRange, setDateRange] = useState([startOfMonth, endOfMonth]);
    const [startDate, endDate] = dateRange;
    const [applyFilterClicked, setApplyFilterClicked] = useState(false)
    const [resetFilterClicked, setResetFilterClicked] = useState(false)
    const [mfilter, setMfilter] = useState({
        tidName: "",
        Ttype: "",
    })
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [basicData2, setBasicData2] = useState({
        columns: ["S.no", 'Order Id', 'Member(s)', 'Date', 'Payment Mode', 'Paid', 'Due', "Total"],
        rows: [],
    })
    const [range, setRange] = useState({
        from: 0,
        to: 0
    })
    const [memberName, setMemberName] = useState("")
    const CalenderInput = forwardRef(({ value, onClick }, ref) => (
        <>
            <span className="gym-input forminput h-40">
                {value === "" ? "Custom Range" : value}
                &nbsp;
                <img
                    src={calImg}
                    onClick={onClick}
                    ref={ref}
                    alt="custom Range"
                    title="Select Custom Range"
                />
            </span>
        </>
    ));

    const getDates = (obj) => {
        setDateRange(obj);
    };



    /**
* handleApplyAndToggle function is used for applying filters .
* created by vamshi
* @function
* 
* @description
* This handleApplyAndToggle function has been create on [30-7-2024] used to set the filters.
*/
    const handleApplyAndToggle = () => {
        const sDate = DayFilter === "1" ? startOfToday : (DayFilter === "2" ? startOfYesterday : (DayFilter === "3" ? startOfWeek : (DayFilter === "4" ? startOfMonth : (DayFilter === "5" ? startOfYear : (DayFilter === "6" ? startDate : null)))))
        const eDate = DayFilter === "1" ? endOfToday : (DayFilter === "2" ? endOfYesterday : (DayFilter === "3" ? endOfWeek : (DayFilter === "4" ? endOfMonth : (DayFilter === "5" ? endOfYear : (DayFilter === "6" ? endDate : null)))))
        if (sDate !== null && eDate !== null) {
            setDateRange([sDate, eDate])
        } else {
            setDateRange([])
        }

        setApplyFilterClicked(true)
    }


    /**
    * handleResetAndToggle function is used to clear filters .
    * created by vamshi
    * @function
    * 
    * @description
    * This handleResetAndToggle function has been create on [30-7-2024] used for reset the filters .
    */
    const handleResetAndToggle = () => {

        setDayFilter("4")
        setDateRange([startOfMonth, endOfMonth])
        setMfilter({
            tidName: "",
            Ttype: "",
        })
        setCustomRange(false)
        setRange({
            from: 0,
            to: 0
        })
        setRangeFilter("")
        setResetFilterClicked(true)

    }
    let filter = {}
    // console.log(mfilter);
    async function fetchData() {
        setLoading(true)
        let startOfDate = startDate
        let endOfDate = endDate
        let Dateobj = {}
        filter.name = mfilter.tidName
        filter.Ttype = mfilter.Ttype

        if ((startOfDate && endOfDate)) {
            const startDatef = new Date(startOfDate)
            startDatef.setHours(0, 0, 0, 0)
            const utcstartDate = startDatef.toISOString();
            let lastoftheday = new Date(endOfDate);
            lastoftheday.setHours(23, 59, 59, 999);
            const utcEndDate = lastoftheday.toISOString();
            Dateobj = {
                startDate: utcstartDate,
                endDate: utcEndDate,
            }
        }
        filter.dateobj = Dateobj
        const response = await getRevenueReports(
            JSON.parse(localStorage.getItem("loggedUserInfo")).gymid, filter
        );

        // console.log(response);
        if (!response) {
            return;
        }
        if (response === "false") {
            setApiError(true)
            return;
        }

        const record = await response.json();
        if (!record) {
            return;
        }

        // Filter memberData based on generateInvoice threshold

        const filteredData = await rangeFilterHandler(record)
        console.log(filteredData);
        // console.log(record);
        setLoading(true)
        setmemberData(filteredData);



        setApplyFilterClicked(false)
        setResetFilterClicked(false)

    }


    // console.log(range);
    const rangeFilterHandler = (record) => {
        const filteredData = record.map(item => {
            const filteredTrans = item.trans.filter(transaction => {
                const totalAmount = generateInvoice(transaction.payment.itemprice, transaction.payment.discount ? transaction.payment.discount.amount : 0, transaction.payment.taxamounts);
                return rangeFilter == "5" ? Number(range.from) <= totalAmount && totalAmount <= Number(range.to) : rangeFilter == "1" ? totalAmount <= 1000 : rangeFilter == "2" ? totalAmount < 5000 : rangeFilter == "3" ? totalAmount < 10000 : rangeFilter == "4" ? totalAmount > 10000 : totalAmount;
            });

            return {
                ...item,
                trans: filteredTrans
            };
        });
        return filteredData
    }
    useEffect(() => {
        fetchData();
    }, [applyFilterClicked, resetFilterClicked])

    useEffect(() => {
        setcustomdate(DayFilter === "6" ? true : false);
    }, [DayFilter]);

    useEffect(() => {
        setCustomRange(rangeFilter === "5" ? true : false);
    }, [rangeFilter]);


    // console.log(basicData);
    // console.log(memberData);

    const generateInvoice = (price, discount, tax) => {
        const hasIncluded = tax && tax.length > 0 ? tax.some(taxItem => taxItem.TaxCheck === "Included") : true;

        const totalTaxAmount = hasIncluded ? 0 : tax.reduce((accumulator, taxItem) => accumulator + (Number(taxItem.amount) || 0), 0);
        const discountedPrice = price - discount;
        const finalPrice = discountedPrice + totalTaxAmount;
        if (isNaN(finalPrice)) {
            return 0;
        }
        return finalPrice;
    }

    useEffect(() => {
        const popupRow = [];

        if (memberData && memberData.length > 0) {

            memberData.forEach(item => {
                const date = item._id;
                item.trans.forEach(transaction => {
                    const memberinfo = transaction.members || {};
                    const memberName = `${memberinfo.fname} ${memberinfo.lname}`;
                    const planName = transaction.plan && transaction.plan.name ? transaction.plan.name : "--";
                    const addonName = transaction.addons && transaction.addons.name ? transaction.addons.name : "--";
                    const totalAmount = "₹" + generateInvoice(transaction.payment.itemprice, transaction.payment.discount ? transaction.payment.discount.amount : 0, transaction.payment.taxamounts).toLocaleString('en-IN');
                    const transactionType = transaction.Ttype === "Renew" ? `Renew` : `New`;

                    const rowData = {
                        date,
                        memberName,
                        // plan: <MDBTooltip tag='div' title={planName}>{planName}</MDBTooltip>,
                        // addon: <MDBTooltip tag='div' title={addonName}>{addonName}</MDBTooltip>,
                        plan: planName,
                        addon: addonName,
                        totalAmount,
                        transactionType,
                        originalItem: transaction
                    };
                    popupRow.push(rowData);
                });
            });

        }

        setBasicData(prevData => ({
            ...prevData,
            rows: popupRow
        }));

        calculateNewAndRenewAmount();
        setLoading(false);
    }, [memberData]);


    /**
   * calculateNewAndRenewAmount function is used for calculate data of new and renewRevenue .
   * created by vamshi
   * @function
   * 
   * @description
   * This calculateNewAndRenewAmount function has been create on [30-7-2024] for displaying new and renew amount.
   */

    const calculateNewAndRenewAmount = () => {
        const renewRevenue = transactionAmount(
            memberData.flatMap(entry => entry.trans).filter(transaction => transaction.Ttype === "Renew")
        );
        const newRevenue = transactionAmount(
            memberData.flatMap(entry => entry.trans).filter(transaction => transaction.Ttype === "New")
        );
        setCard({
            new: newRevenue,
            renew: renewRevenue
        });
        // console.log(`Total Renew Revenue: ${renewRevenue}`);
        // console.log(`Total New Revenue: ${newRevenue}`);
    };

    const transactionAmount = (transactions) => {
        return transactions.reduce((sum, transaction) => {
            return sum + generateInvoice(transaction.payment.itemprice, transaction.payment.discount ? transaction.payment.discount.amount : 0, transaction.payment.taxamounts);
        }, 0);
    };

    const handleReload = () => {
        window.location.reload();
    }

    /**
    * downloaddata function is used for export data .
    * created by vamshi
    * @function
    * 
    * @description
    * This downloaddata function has been create on [30-7-2024] for export.
    */
    const downloaddata = (data) => {
        const popupRow = [];
        if (memberData && memberData.length > 0) {
            memberData.forEach(item => {
                const date = item._id;
                item.trans.forEach(transaction => {
                    const rarray = [];
                    const memberinfo = transaction.members ? transaction.members : {}
                    const planName = transaction.plan && transaction.plan.name ? transaction.plan.name : "--";
                    const addonName = transaction.addons && transaction.addons.name ? transaction.addons.name : "--";
                    const totalAmount = "₹" + generateInvoice(transaction.payment.itemprice, transaction.payment.discount ? transaction.payment.discount.amount : 0, transaction.payment.taxamounts).toLocaleString('en-IN');
                    const transactionType = transaction.Ttype === "Renew" ? `Renew` : `New`;
                    rarray.push(date);
                    rarray.push(memberinfo.fname ? memberinfo.fname + " " + memberinfo.lname : "");
                    rarray.push(planName);
                    rarray.push(addonName);
                    rarray.push(totalAmount);
                    rarray.push(transactionType);
                    popupRow.push(rarray);
                })
            })
        }

        const obj = {
            columns: ['Date', 'Member Name', "Plan", "Addon", 'Total Amount', 'Transaction Type'],
            rows: popupRow
        }
        ExcelExportButton(obj, "Revenue Report");
    }
    const [openTogle, setOpenTogle] = useState(false)
    /**
* handleRowClick function is used know the member details .
* created by vamshi
* @function
* 
* @description
* This handleRowClick function has been create on [30-7-2024] for meber details in popup.
*/
    const handleRowClick = (rowData) => {
        setSelectedRowData(rowData.originalItem ? rowData.originalItem : rowData);
        console.log(rowData);
        setMemberName(rowData.memberName)
        setOpenTogle(true)
    };
    // console.log(selectedRowData);
    // console.log(selectedTrans);
    useEffect(() => {
        const popupRow = [];
        if (selectedRowData != undefined) {
            const rarray = [];
            const tax = selectedRowData.payment.taxamounts

            const hasIncluded = tax && tax.length > 0 ? tax.some(taxItem => taxItem.TaxCheck === "Included") : true;

            const totalTaxAmount = hasIncluded ? 0 : tax.reduce((accumulator, taxItem) => accumulator + (Number(taxItem.amount) || 0), 0);
            const discountValue = (selectedRowData.payment.discount ? selectedRowData.payment.discount.amount : 0)
            rarray.push(selectedRowData.token);
            rarray.push(selectedRowData.members.fname ? selectedRowData.members.fname + " " + selectedRowData.members.lname : "");
            rarray.push(selectedRowData.plan && selectedRowData.plan.name ? selectedRowData.plan.name : selectedRowData.addons.name);
            rarray.push(<span className='trans-date'>{new Date(selectedRowData.createdAt).toLocaleString("en-IN", { timeZone: "Asia/Kolkata", })}</span>);
            // rarray.push(<span className='trans-date'>{new Date(selectedRowData.startdate).toLocaleString("en-IN", { timeZone: "Asia/Kolkata", })}</span>);
            // rarray.push(<span className='trans-date'>{new Date(selectedRowData.expirydate).toLocaleString("en-IN", { timeZone: "Asia/Kolkata", })}</span>);
            rarray.push(
                selectedRowData.payment.mode
                    ? selectedRowData.payment.mode === 1
                        ? "Cash"
                        : selectedRowData.payment.mode === 2
                            ? "Card"
                            : selectedRowData.payment.mode === 3
                                ? "UPI"
                                : selectedRowData.payment.mode === 4
                                    ? "Wallet"
                                    : selectedRowData.payment.mode === 5
                                        ? "Coupon"
                                        : selectedRowData.payment.mode === 6
                                            ? [
                                                JSON.parse(selectedRowData.payment.payinfo).info.cash_amt ? "Cash" : "",
                                                JSON.parse(selectedRowData.payment.payinfo).info.cc_amt ? "Card" : "",
                                                JSON.parse(selectedRowData.payment.payinfo).info.upi_amt ? "UPI" : "",
                                                JSON.parse(selectedRowData.payment.payinfo).info.wallet_amt ? "Wallet" : "",
                                            ]
                                                .filter(Boolean)
                                                .join(", ")
                                            : selectedRowData.mode >= 7
                                                ? JSON.parse(selectedRowData.payment.payinfo).info.userpaymethod
                                                : "Account Payment Type"
                    : "--"
            );
            rarray.push("₹" + ((selectedRowData.payment.itemprice).toLocaleString('en-IN')))
            rarray.push("₹" + ((discountValue).toLocaleString('en-IN')))
            rarray.push("₹" + (totalTaxAmount).toLocaleString('en-IN'));
            rarray.push("₹" + ((selectedRowData.payment.itemprice - discountValue) + totalTaxAmount).toLocaleString('en-IN'));

            popupRow.push(rarray);

        }
        // "Start Date","End Date",
        setBasicData2({
            columns: ['Order Id', 'Member Name', "Membership", 'Date', 'Payment Mode', 'Membership Price', 'Discount', "Tax Amount", "Total"],
            rows: popupRow
        })
    }, [selectedRowData])

    return (
        <>


            {apiError ? <PageError handleReload={handleReload} /> :
                <div className="mt-4">
                    <div className="d-flex align-items-center TrainerSummeryCustomHead">
                        <div className="d-flex">

                            <div class="filters_Count bg-success bg-opacity-10"><p class="text-center mb-1 fw-600">New Revenue</p>₹{(card.new).toLocaleString('en-IN')} </div>
                            <div class="filters_Count bg-danger bg-opacity-10"><p class="text-center mb-1 fw-600">Renew Revenue</p>₹{(card.renew).toLocaleString('en-IN')}</div>

                        </div>
                    </div>

                    <div className=" d-flex flex-wrap w-90 align-items-end">
                        <div className="gym-section w-100-mobile">
                            <label className="fw-bold">Search by Member:</label>
                            <div>
                                <input
                                    type="text"
                                    ref={searchRef}
                                    value={mfilter.tidName}
                                    className="form-control select-input placeholder-active form-controlMobile"
                                    // className="form-control"
                                    placeholder="Search here..."
                                    onChange={(e) => setMfilter({
                                        ...mfilter,
                                        tidName: e.target.value.toLowerCase()
                                    })}
                                />
                            </div>
                        </div>
                        <div className="gym-section w-100-mobile ms-2">
                            <label className="fw-bold">Transaction type:</label>
                            <div>
                                <select
                                    name="emailReminder"
                                    id="emailReminder"
                                    className="form-control select-input placeholder-active form-select shadow-1 form-selectMobile"
                                    value={mfilter.Ttype}

                                    onChange={(e) => setMfilter({
                                        ...mfilter,
                                        Ttype: e.target.value
                                    })}
                                >
                                    <option value="">All</option>
                                    <option value="Renew">Renew</option>
                                    <option value="New">New</option>
                                </select>
                            </div>
                        </div>
                        <div className="gym-section w-100-mobile ms-2">
                            <label className="me-2 fw-bold"> Day: </label>
                            <div className="mob-w-100 ">
                                <select
                                    name="shrs"
                                    id="shr"
                                    className="form-control select-input form-select placeholder-active me-3 shadow-1 form-selectMobile"
                                    onChange={(e) => setDayFilter(e.target.value)}
                                    value={DayFilter}
                                >
                                    <option value="7">All</option>
                                    <option value="1">Today</option>
                                    <option value="2">Yesterday</option>
                                    <option value="3">This Week</option>
                                    <option value="4">This Month</option>
                                    <option value="5">This Year</option>
                                    <option value="6">Custom</option>

                                </select>
                            </div>
                        </div>
                        <div className="gym-section w-100-mobile ms-2">
                            <label className="me-2 fw-bold"> Revenue Range: </label>
                            <div className="mob-w-100 ">
                                <select
                                    name="shrs"
                                    id="shr"
                                    className="form-control select-input form-select placeholder-active me-3 shadow-1 form-selectMobile"
                                    onChange={(e) => setRangeFilter(e.target.value)}
                                    value={rangeFilter}
                                >
                                    <option value="">All</option>
                                    <option value="1">below 1,000</option>
                                    <option value="2">below 5,000</option>
                                    <option value="3">below 10,000</option>
                                    <option value="4">Greater then 10,000</option>
                                    <option value="5">Custom</option>
                                </select>
                            </div>
                        </div>
                        {customRange && <div className="gym-section w-100-mobile ms-2">
                            <label className="fw-bold">Custom Range</label>
                            <div className="d-flex align-items-center w-auto">
                                <input type="number" name="" id="" className="form-control w-80px" placeholder="From" value={range.from}

                                    onChange={(e) => setRange({
                                        ...range,
                                        from: e.target.value
                                    })} />
                                <div className="mx-2">-</div>
                                <input type="number" name="" id="" className="form-control w-80px " placeholder="To" value={range.to}

                                    onChange={(e) => setRange({
                                        ...range,
                                        to: e.target.value
                                    })} />
                            </div>
                        </div>}
                        {customdate && <div className="gym-section w-100-mobile ms-3">
                            <span className="customRange align-items-center">
                                <label className="fw-bold">Custom Range</label>
                                <div className="gym-xlarge gym-larg-pos">
                                    <DatePicker
                                        dateFormat={"d/M/yyyy"}
                                        selectsRange={true}
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={(update) => {
                                            getDates(update)
                                        }}
                                        isClearable={false}
                                        customInput={<CalenderInput />}
                                    />
                                </div>
                            </span>
                        </div>}
                        <div className="gym-section w-100-mobile ms-3">
                            <div className="filterApplyMobile">
                                <button
                                    type="submit"
                                    className="me-3 btn btn-primary"
                                    onClick={() => handleApplyAndToggle()}
                                >
                                    Apply
                                </button>
                                <button
                                    type="submit"
                                    className="mt-2 btn btn-secondary"
                                    onClick={() => { handleResetAndToggle() }}
                                >
                                    Clear
                                </button>
                                {(localStorage.getItem("loggedUserInfo")) && JSON.parse(localStorage.getItem("loggedUserInfo")).role === "admin" && <button type="submit" className="ms-3 btnheight btn btn-primary"
                                    onClick={(e) => downloaddata(basicData)}
                                >
                                    <MDBIcon fas icon="download" className="d-inline me-1" />Export
                                </button>}
                            </div>
                        </div>
                    </div>
                    <div className="">
                        {loading ? (<div className="loader-height"> <Loader /></div>) :
                            <MDBDatatable className="RevinueReportTable"
                                fixedHeader
                                maxHeight='calc(100vh - 223px)'
                                entries={25}
                                paging={true}
                                data={basicData}
                                onRowClick={handleRowClick}
                            />
                        }
                    </div>
                </div>

            }
            <MDBModal staticBackdrop tabIndex='-1' open={openTogle} onClose={() => setOpenTogle(false)}>
                <MDBModalDialog size="lg">
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>
                                <h6 className="m-0">{memberName} Transaction Details</h6>
                            </MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={() => setOpenTogle(false)}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody >
                            <div className="trainerMemberSummeryDataTable">
                                <MDBDatatable entries={25} data={basicData2} />

                            </div>
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

        </>
    )
}

export default RevenueReport