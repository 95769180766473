import Header from "../components/Header";
import LeftPane from "../components/LeftPane";

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import { saveSlot } from "../services/apiServices";
import { Link } from "react-router-dom";
import CreateSlot from "./CreateSlot";

const CreateSlotView = (props) => {
  const navigate = useNavigate();

  function oncancel() {
    
    navigate("/plans");
   
  }
  return (
    <>
      <LeftPane isactive="7" />
      <Header />
      <div
        className="gym-main rightContent"
      >
        <CreateSlot ispoppup="0" oncancel={oncancel} />
      </div>
    </>
  );
};
export default CreateSlotView;
