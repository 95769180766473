import {
    MDBBtn,
    MDBModalFooter,
    MDBTooltip
} from 'mdb-react-ui-kit';
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { browserName, browserVersion } from "react-device-detect";
import { logEvent, pauseMemberShip, resumeMemberShip } from "../services/apiServices";
import { isEmpty, } from '../validators/helper';
import Toast from './Toast';
import ReactGA from 'react-ga';
import { googleAnalytic } from './googleAnalytics';

function EditMemberShip(props) {
    console.log(props)
    const LastTransaction = (props.editTrans);
    let startDate = new Date(LastTransaction.startdate);
    let startDateTime = `${startDate.getDate().toString().padStart(2, '0')}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getFullYear()}`
    const memberDetails = (props.details);
    const [smessage, setSMessage] = useState(null);
    const [StartDate, setStartDate] = useState(new Date(props.editTrans.startdate));
    const [EndDate, setEndDate] = useState(new Date(props.editTrans.expirydate));
    const [PauseContent, setPauseContent] = useState(false)
    const [error, setError] = useState({});
    const [pauseForm, setPauseForm] = useState({
        inputValue: "",
        validType: "1",
        totime: new Date(),
        fromtime: new Date(),
    });
    const currentTime = new Date();
    const startOfToday = new Date(currentTime);
    startOfToday.setHours(0, 0, 0, 0);

    const endOfToday = new Date(currentTime);
    endOfToday.setHours(23, 59, 59, 999);
    useEffect(() => {
        if (props.details && props.details.attendance.length > 0) {
            const currentTime = new Date();
            const startOfToday = new Date(currentTime);
            startOfToday.setHours(0, 0, 0, 0);

            const endOfToday = new Date(currentTime);
            endOfToday.setHours(23, 59, 59, 999);
            let sortedAttendance = props.details.attendance.filter((obj) => (new Date(obj.intime) >= startOfToday)
                && (new Date(obj.intime) <= endOfToday));
            const nextDay = new Date();
            nextDay.setDate(nextDay.getDate() + 1);
            setPauseForm({
                inputValue: "",
                validType: "1",
                fromtime: sortedAttendance.length > 0 ? nextDay : new Date(),
                totime: sortedAttendance.length > 0 ? nextDay : new Date(),
            });
        }
    }, [props]);

    function startDateChanges(date, data) {
        let plan = memberDetails.plan.filter((item) => item._id === data.itemid)[0]
        if (!plan) {
            plan = memberDetails.addons.filter((item) => item._id === data.itemid)[0]
        }
        let expirydate = new Date(date);
        if (data.isplan === "1") {
            expirydate.setDate(expirydate.getDate() +
                (plan.validityflag === 1 ? plan.numberofdays * 1
                    : plan.validityflag === 2
                        ? plan.numberofdays * 30
                        : plan.numberofdays * 365));
        } else {
            console.log("in else");
            expirydate.setDate(
                expirydate.getDate() +
                (plan.rentbasis === "1"
                    ? 1
                    : (plan.rentbasis === "2"
                        ? 30
                        : 365))
            );
        }
        setStartDate(date);
        setEndDate(expirydate);
    }
    function PauseUpdateForm(value) {
        return setPauseForm((prev) => {
            return { ...prev, ...value };
        });
    }
    // useEffect(() => {
    //     let toDate = new Date(pauseForm.totime);
    //     let fromDate = new Date(pauseForm.fromtime);
    //     const dayDifference = Math.ceil((toDate - fromDate) / (1000 * 60 * 60 * 24));
    //     let endDate = new Date(LastTransaction.expirydate)
    //     endDate.setDate(endDate.getDate() + dayDifference);
    //     setEndDate(endDate);
    // }, [pauseForm])
    function togglePause(e) {
        e.preventDefault();
        setPauseContent((val) => !val);
        setStartDate(new Date(LastTransaction.startdate));
        setEndDate(new Date(LastTransaction.expirydate));
        if (props.details && props.details.attendance.length > 0) {
            let sortedAttendance = props.details.attendance.filter((obj) => (new Date(obj.intime) >= startOfToday)
                && (new Date(obj.intime) <= endOfToday));
            const nextDay = new Date();
            nextDay.setDate(nextDay.getDate() + 1);
            setPauseForm({
                inputValue: "",
                validType: "1",
                fromtime: sortedAttendance.length > 0 ? nextDay : new Date(),
                totime: sortedAttendance.length > 0 ? nextDay : new Date(),
            });

        }
    }
    function fromDateChanges(date) {
        let days = pauseForm.inputValue ? (pauseForm.validType === "2" ? Number(pauseForm.inputValue) * 30 : pauseForm.inputValue * 1) : 0;
        PauseUpdateForm({ fromtime: new Date(date), });
        if (days === 0) {
            let totime = new Date(pauseForm.totime);
            totime.setHours(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
            const dayDifference = Math.ceil((new Date(totime) - new Date(date)) / (1000 * 60 * 60 * 24));
            if (new Date(totime) >= new Date(date)) {
                PauseUpdateForm({
                    totime: new Date(totime),
                    inputValue: dayDifference,
                    validType: "1",
                });
            } else {
                PauseUpdateForm({ totime: new Date(date), });
            }
        } else {
            const toDate = new Date(date);
            toDate.setDate(date.getDate() + days);
            PauseUpdateForm({ totime: toDate });
        }
    }
    function toDateChanges(date) {
        // to date should be more than from date
        let toDate = new Date(date);
        let fromDate = new Date(pauseForm.fromtime);
        const dayDifference = Math.ceil((toDate - fromDate) / (1000 * 60 * 60 * 24));
        PauseUpdateForm({
            inputValue: dayDifference,
            validType: "1",
            totime: toDate,
        });
    }
    function updateDaysFunction(value) {
        let days = pauseForm.validType === "2" ? Number(pauseForm.inputValue) * 30 : pauseForm.inputValue * 1;
        if (value.validType) {
            days = value.validType === "2" ? Number(pauseForm.inputValue) * 30 : pauseForm.inputValue * 1;
            PauseUpdateForm({ validType: value.validType });
        } else {
            days = pauseForm.validType === "2" ? Number(value.inputValue) * 30 : value.inputValue * 1;
            PauseUpdateForm({ inputValue: value.inputValue });
        }
        let fromDate = new Date(pauseForm.fromtime);
        let toDate = new Date(fromDate);
        toDate.setDate(toDate.getDate() + days);
        PauseUpdateForm({ totime: toDate, });
    }
    let validateForm = (e) => {
        e.preventDefault();
        console.log("Validating form...");
        let errors = {};
        if (PauseContent && (Number(pauseForm.inputValue) <= 0 || pauseForm.inputValue === "")) {
            errors.inputValue = "Field can't be empty.";
        }
        // if (isEmpty(reason)) {
        //     setErrors("reason can't be empty.")
        // }
        if (isEmpty(errors)) {
            return true;
        } else {
            return errors;
        }
    };
    async function pauseSubmitFunction(e) {
        setError("")
        e.preventDefault();
        let errors = validateForm(e);
        if (errors === true) {
            if (localStorage.getItem("loggedUserInfo")) {
                let gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
                let transaction = LastTransaction;
                let startdate = new Date(StartDate);
                let enddate = new Date(EndDate);
                let resume = pauseForm.totime < new Date() ? false : true;
                let updates;
                let flag = `Paused Membership Successfull (${memberDetails.fname} ${memberDetails.lname})`;
                if (pauseForm.inputValue !== "" && pauseForm.inputValue !== 0) {
                    const dayDifference = (new Date(pauseForm.totime) - new Date(pauseForm.fromtime)) / (1000 * 60 * 60 * 24);
                    enddate.setDate(enddate.getDate() + Math.ceil(dayDifference));
                    updates = {
                        date: new Date(),
                        fromtime: pauseForm.fromtime,
                        totime: pauseForm.totime,
                        resume: resume,
                        transaction: transaction,
                        enddate: enddate,
                        startdate: startdate,
                        gymid: gymid,
                        pauseToggle: true,
                    };
                    await googleAnalytic("editMembership", 'Member', 'Pause Membership Button', 'Membership Paused')
                    // ReactGA.event({
                    //     category: 'Member',
                    //     action: 'Pause Membership Button',
                    //     label: 'Membership Paused',
                    //   });
                    setSMessage("Paused Membership Successfull");
                } else {
                    updates = {
                        date: new Date(),
                        transaction: transaction,
                        enddate: enddate,
                        startdate: startdate,
                        gymid: gymid,
                        pauseToggle: false,
                    };
                    await googleAnalytic("editMembership", 'Member', 'End Date field', 'Membership End Date Changed')
                    // ReactGA.event({
                    //     category: 'Member',
                    //     action: ' End Date field',
                    //     label: 'Membership End Date Changed',
                    //   });
                    flag = `End Date Changed Successfull (${memberDetails.fname} ${memberDetails.lname})`;
                    setSMessage("Membership Edited Successfull");
                }
                console.log(updates);
                await pauseMemberShip(gymid, updates);

                let ip = localStorage.getItem("ipaddress");
                let browserinfo = browserName + "-" + browserVersion + ".0.0";
                let Eventtype = "Membership";
                let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : "";

                await logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);
                setTimeout(() => {
                    window.location.reload();
                    // setSMessage(null);
                }, 300);
            }
        } else {
            setError(errors);
        }
    }
    let pauseData = [];
    if (LastTransaction.pause && LastTransaction.pause.length > 0) {
        pauseData = [LastTransaction.pause.sort((a, b) => b.sno - a.sno)[0]];
    }
    let PauseLastDate = pauseData.length > 0 ? new Date(pauseData[0].totime) : new Date();
    let PauseLastDateString = `${PauseLastDate.getDate().toString().padStart(2, '0')}-${(PauseLastDate.getMonth() + 1).toString().padStart(2, '0')}-${PauseLastDate.getFullYear()}`


    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);

    async function resumeSubmitFunction(e) {
        e.preventDefault();
        let transaction = LastTransaction;
        let pausedDate = new Date(pauseData && pauseData.length > 0 && pauseData[0].fromtime ? pauseData[0].fromtime : new Date());
        let currentTime = new Date();
        currentTime.setHours(pausedDate.getHours());
        currentTime.setMinutes(pausedDate.getMinutes());
        currentTime.setSeconds(pausedDate.getSeconds());
        currentTime.setMilliseconds(pausedDate.getMilliseconds());

        let OldPausedDate = new Date(pauseData && pauseData.length > 0 && pauseData[0].totime ? pauseData[0].totime : new Date());

        const dayDifference = (OldPausedDate - currentTime) / (1000 * 60 * 60 * 24);
        let enddate = new Date(transaction.expirydate);
        console.log(enddate.getDate());
        enddate.setDate(enddate.getDate() - Math.ceil(dayDifference));
        let gymid = JSON.parse(localStorage.getItem("loggedUserInfo")).gymid;
        let updates = {
            date: pauseData && pauseData.length > 0 ? pauseData[0].date : new Date(),
            fromtime: pauseData[0].fromtime ? pauseData[0].fromtime : new Date(),
            totime: currentTime,
            resume: false,
            sno: pauseData[0].sno,
            enddate: enddate,
            transaction: transaction,
            gymid: gymid,
        };
        await resumeMemberShip(gymid, updates);
        let flag = `Plan Resume Successfull (${memberDetails.fname} ${memberDetails.lname})`;
        let ip = localStorage.getItem("ipaddress");
        let browserinfo = browserName + "-" + browserVersion + ".0.0";
        let Eventtype = "Membership";
        let username1 = JSON.parse(localStorage.getItem("loggedUserInfo")) ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : "";
        await googleAnalytic("editMembership", 'Member', 'Resume Membership Button', 'Membership Resumed')
        // ReactGA.event({
        //     category: 'Member',
        //     action: 'Resume Membership Button',
        //     label: 'Membership Resumed',
        //   });
        await logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);
        setSMessage("Membership Resumed Successfull");
        setTimeout(() => {
            window.location.reload();
        }, 300);
    }
    
    const CalendarInput = ({ value, onClick }) => (
        <input
            type="text"
            value={value}
            readOnly={true}
            className="w-80 form-control form-control"
            onClick={onClick}
        />
    );
    const CalendarInputMain = ({ value, onClick }) => (
        <input
            type="text"
            value={value}
            readOnly={true}
            className="w-50 form-control form-control"
            onClick={onClick}
        />
    );
    console.log(JSON.parse(localStorage.getItem("loggedUserInfo")));
    return (
        <>
            <div>
                <div className="w-100">
                    {smessage && (
                        <div>
                            <Toast message={smessage} />
                        </div>
                    )}
                    <div className="d-flex align-items-end   ">
                        <div className="view-name m-0 checkIn-Head">
                            <MDBTooltip tag='div' wrapperProps={{ className: "view-name m-0 checkIn-Head" }}
                                title={memberDetails.fname ? memberDetails.fname + " " + memberDetails.lname : ""} >
                                {memberDetails.fname ? memberDetails.fname + " " + memberDetails.lname : ""}
                            </MDBTooltip>
                        </div>
                    </div>
                    <span className="text-dark mt-1">
                        {memberDetails.sex ? `${memberDetails.sex}` : ""}
                        {memberDetails.number ? ("," + memberDetails.number) : ""}
                    </span>
                    <div className="pb-2">
                        <span className="text-dark mt-3">
                            {
                                (() => {
                                    const matchingPlan = memberDetails.plan.find(item => item._id === LastTransaction.itemid);
                                    const matchingAddon = memberDetails.addons.find(item => item._id === LastTransaction.itemid);
                                    if (matchingPlan) {
                                        return "Plan Name: " + matchingPlan.name;
                                    } else if (matchingAddon) {
                                        return "Addon Name: " + matchingAddon.name;
                                    } else { return "" }
                                })()
                            }
                        </span>
                    </div>
                    {pauseData.length > 0 && pauseData[0].resume ?
                        <div className="pb-2">
                            <span className="text-warning mt-3">
                                {LastTransaction.isplan !== "0" ? "Plan" : "Addon"} Paused Until {PauseLastDateString}
                            </span>
                        </div>
                        : null}
                    <div className="p-2 ps-0">
                        <div className='d-flex align-items-center w-100  justify-content-between'>
                            <div className='d-inline-block float-left'><strong> Start Date:</strong> </div>
                            <div className='d-inline-block float-end custom_date_picker'>
                                {(PauseContent || (LastTransaction.pause && LastTransaction.pause.length > 0)) ?
                                    startDateTime :
                                    <DatePicker
                                        style={{ width: "70px" }}
                                        selected={StartDate}
                                        dateFormat="dd-MM-yyyy"
                                        className="inp-clrdate-Small"
                                        isClearable={false}
                                        customInput={<CalendarInputMain />}
                                        onChange={(date) => startDateChanges(date, LastTransaction)}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="p-2 ps-0">
                        <div className='d-flex align-items-center w-100 justify-content-between'>
                            <div className=' '><strong> End Date:</strong> </div>
                            <div className=' custom_date_picker '>
                                {(PauseContent || (pauseData.length > 0 && pauseData[0].resume)) ?
                                    `${EndDate.getDate().toString().padStart(2, '0')}-${(EndDate.getMonth() + 1).toString().padStart(2, '0')}-${EndDate.getFullYear()}`
                                    :
                                    <DatePicker
                                        style={{ width: "70px" }}
                                        selected={EndDate}
                                        dateFormat="dd-MM-yyyy"
                                        className="inp-clrdate-Small"
                                        isClearable={false}
                                        customInput={<CalendarInputMain />}
                                        onChange={(date) => setEndDate(date)}
                                    />}
                            </div>
                        </div>
                    </div>
                    {!PauseContent
                        && (new Date(LastTransaction.expirydate) >= new Date())
                        && ((pauseData.length === 0) || (pauseData.length > 0 && !pauseData[0].resume))
                        &&
                        <div className='my-2' onClick={(e) => togglePause(e)}>
                            <strong className="link-color my-1" >Pause</strong></div>}

                    {PauseContent && <>
                        <div className='p-2 ps-0'>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div><strong className="link-color my-1" onClick={(e) => togglePause(e)}> Pause:</strong><br />
                                    This will pause your {LastTransaction.isplan !== "0" ? "Plan" : "Addon"} until you resume or your next check-in. Enter the desired value in the below text box in days/months.</div>
                            </div>
                        </div>

                        <div className='d-flex align-items-center justify-content-between my-2 pauseplan' >
                            <div className='w-30'>
                                <label>Days/Months: </label>
                                <div className="d-flex align-items-center justify-content-start">
                                    <input type="number" className="form-control form-control form-control-sm w-50"
                                        value={pauseForm.inputValue}
                                        onChange={(e) => updateDaysFunction({ inputValue: e.target.value })}
                                    />
                                    <select
                                        name="emailReminder"
                                        id="emailReminder"
                                        className="form-control form-control-sm select-input form-select placeholder-active shadow-1 form-selectMobile ms-2 w-auto"
                                        onChange={(e) => updateDaysFunction({ validType: e.target.value })}
                                    >
                                        <option value="1">Days</option>
                                        <option value="2">Months</option>
                                    </select>
                                </div>
                                {error.inputValue && (<><p className="clr-red waiver-error-txt">{error.inputValue}</p></>)}

                            </div>
                            <div className='w-30'>
                                <label className='w-100'>From</label>
                                <DatePicker
                                    selected={pauseForm.fromtime}
                                    dateFormat="dd-MM-yyyy"
                                    className="inp-clrdate-Small ms-0 w-100"
                                    isClearable={false}
                                    customInput={<CalendarInput />}
                                    onChange={(date) => fromDateChanges(date)}
                                    minDate={new Date(LastTransaction.startdate)}
                                    maxDate={new Date(LastTransaction.expirydate)}
                                />
                            </div>
                            <div className='w-30'>
                                <label>To</label>
                                <DatePicker
                                    selected={pauseForm.totime}
                                    dateFormat="dd-MM-yyyy"
                                    className="inp-clrdate-Small ms-0 w-100"
                                    isClearable={false}
                                    customInput={<CalendarInput />}
                                    onChange={(date) => toDateChanges(date)}
                                    minDate={new Date(pauseForm.fromtime)}
                                // maxDate={new Date(LastTransaction.expirydate)}
                                />
                            </div>
                        </div>
                    </>}
                    {/* 
                    <span className="w-30">Pause:</span>
                    <MDBDatatable data={basicData} className="Transaction__table" />
                    <span className="w-30">Extend:</span>
                    <MDBDatatable data={basicDataE} className="Transaction__table" /> */}
                </div>
            </div>
            <MDBModalFooter>
                <MDBBtn color='secondary' className='me-2'
                    onClick={(e) => props.cancel()}
                >
                    Close
                </MDBBtn>
                {(pauseData.length > 0 && pauseData[0].resume) ?
                    <button
                        type="button"
                        class="btn btn-warning btn-small me-2"
                        onClick={resumeSubmitFunction}>
                        <i class="fas fa-play me-2"></i>
                        Resume
                    </button>
                    :
                    <MDBBtn onClick={(e) => pauseSubmitFunction(e)}>Save</MDBBtn>}
            </MDBModalFooter>
        </>);
}

export default EditMemberShip;