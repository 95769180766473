import React, { useEffect, useState } from "react";
import { browserName, browserVersion } from "react-device-detect";
import ReactGA from 'react-ga';
import deleteImg from "./../components/images/delete.svg";
import {
    MDBCard,
    MDBCardBody,
    MDBCardText,
    MDBCardTitle,
    MDBIcon
} from 'mdb-react-ui-kit';
import Popup from "../components/Popup";
import {
    getGBussinessInfo,
    logEvent,
    saveGBussinessInfo
} from "../services/apiServices.js";
import {
    isEmail,
    isEmpty,
    isValidPhoneNumber
} from "../validators/helper.js";
import PageError from "./PageError";
import { googleAnalytic } from "../components/googleAnalytics";

function TaxSettings(props) {

    const [apiError, setApiError] = useState(false);
    const [ddgst, setDdgst] = useState(true);
    const [dpaymethods, setDpaymethods] = useState([]);
    const [userpaymethods, setUserpaymethods] = useState([]);
    const [selectedUOpts, setselecteUOpts] = useState([]);
    const [dgsttax, setDgsttax] = useState([]);
    const [ugsttax, setUgsttax] = useState([]);
    const [gstopt, setGstopt] = useState([]);
    const [emailrem, setEmailrem] = useState([]);
    const [smsrem, setSmsrem] = useState([]);
    const [can, setCan] = useState(false)
    const [businessForm, setbusinessForm] = useState({
        businessName: "",
        businessEmail: "",
        firstName: "",
        lastName: "",
        contactNumber: "",
        memberCapacity: "",
        state: "",
        city: "",
        address: "",
        prefix: "",
        dpaymethods: dpaymethods,
        userpaymethods: userpaymethods,
        dgsttax: dgsttax,
        ugsttax: ugsttax,
        shortcode: "",
        emailrem: emailrem,
        smsrem: smsrem,
        emailinter: "h",
        smsinter: "h",
        timezone: null,
    });
    const [gstpopup, setGstpopup] = useState(false);
    function savepopup() {
        setDdgst(false);
        setDgsttax([]);
        setGstpopup(false);
    }
    useEffect(() => {
        async function fetchData() {
            if (localStorage.getItem("loggedUserInfo")) {
                const response = await getGBussinessInfo(
                    JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
                );

                if (!response) {
                
                    return;
                }
                if (response === "false") {
                    setApiError(true);
                } else {
                    const record = await response.json();
                    if (!record) {
                        return;
                    }
                    setbusinessForm(record);
                    setEmailrem(record.emailrem);
                    setSmsrem(record.smsrem);
                    setDpaymethods(record.dpaymethods);
                    setDgsttax(record.dgsttax);
                    if (record.ddgst === false) {
                        setDdgst(false);
                    }

                    let temOpts = [];
                    console.log(record);
                    if (Array.isArray(record.userpaymethods)) {
                        record.userpaymethods.forEach((opt) => {
                            if (opt.check) {
                                temOpts.push(opt._id);
                            }
                        });
                    }
                    setselecteUOpts(temOpts);
                    setUserpaymethods(record.userpaymethods);

                    let gsttaxoption = [];
                    if (Array.isArray(record.ugsttax)) {
                        record.ugsttax.forEach((opt) => {
                            if (opt.check) {
                                gsttaxoption.push(opt._id);
                            }
                        });
                    }
                    setGstopt(gsttaxoption);
                    setUgsttax(record.ugsttax);
                }
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);

    let validateForm = (e) => {
        console.log("Validating form...");
        let errors = {};
        if (isEmpty(businessForm.businessName)) {
            errors.businessname = "Business Name can't be blank";
        }
        if (isEmpty(businessForm.businessEmail)) {
            errors.businessEmail = "Email can't be blank";
        } else if (!isEmail(businessForm.businessEmail)) {
            errors.businessEmail = "Please enter valid email";
        }
        if (isEmpty(businessForm.firstName)) {
            errors.firstname = "First Name can't be blank";
        }
        if (isEmpty(businessForm.lastName)) {
            errors.lastName = "Last Name can't be blank";
        }
        if (isEmpty(businessForm.contactNumber)) {
            errors.contactNumber = "Contact Number can't be blank";
        } else if (!isValidPhoneNumber(businessForm.contactNumber)) {
            errors.contactNumber = "Please enter valid Contact Number";
        }
        // if (isEmpty(businessForm.memberCapacity)) {
        //   errors.memberCapacity = "Member Capacity can't be blank";
        // }

        if (isEmpty(businessForm.city)) {
            errors.city = "City can't be blank";
        }
        if (isEmpty(businessForm.address)) {
            errors.address = "Address can't be blank";
        }
        // if (isEmpty(businessForm.prefix)) {
        //   errors.prefix = "Transaction id prefix can't be blank";
        // }

        if (isEmpty(errors)) {
            return true;
        } else {
            return errors;
        }
    };
    function addNewRowgst(e) {
        e.preventDefault();
        if (ugsttax.length >= 10) {
            window.alert("Maximum limit reached");
            return;
        }
        setUgsttax([...ugsttax, { check: "", title: "", label: "" }]);
    }
    function deleteRowgst(e, index) {
        e.preventDefault();
        setUgsttax((current) =>
            current.filter((obj, tindex) => tindex !== index)
        );
    }
    function processCancel() {
        setCan(!can)
        window.location.href = "/bInfo";
    }
    function addgstMethod(e, tid, isdded) {
        try {
            console.log(isdded);
            let ischecked = isdded ? false : true;
            console.log("ischecked :: " + ischecked);
            if (ischecked) {
                setDgsttax([...dgsttax, tid]);
            } else {
                const nmethods = dgsttax.filter((item) => item !== tid);
                setDgsttax(nmethods);
            }
        } catch (e) {
            console.log("Error ::::::::::;; " + e);
        }
    }
    function addUgstMethod(e, tid, isdded) {
        try {
            console.log(isdded);
            let ischecked = isdded ? false : true;
            console.log("ischecked :: " + ischecked);
            if (ischecked) {
                setGstopt([...gstopt, tid]);
            } else {
                const nmethods = gstopt.filter((item) => item !== tid);
                setGstopt(nmethods);
            }
        } catch (e) {
            console.log("Error ::::::::::;; " + e);
        }
    }
    function updateTitlegst(obj) {
        setUgsttax((prevState) => {
            const newState = prevState.map((eachObj, index) => {
                // 👇️ if id equals 2, update country property
                if (index === obj.index) {
                    return {
                        _id: eachObj._id,
                        check: eachObj.check,
                        title: obj.title,
                        label: eachObj.label,
                    };
                }
                // 👇️ otherwise return object as is
                return eachObj;
            });
            return newState;
        });
    }
    function updateLabelgst(obj) {
        setUgsttax((prevState) => {
            const newState = prevState.map((eachObj, index) => {
                if (index === obj.index) {
                    return {
                        _id: eachObj._id,
                        check: eachObj.check,
                        title: eachObj.title,
                        label: obj.label,
                    };
                }
                // 👇️ otherwise return object as is
                return eachObj;
            });
            return newState;
        });
    }
    async function onSubmit(e) {
        e.preventDefault();
        if (Array.isArray(userpaymethods)) {
            userpaymethods.forEach((opt) => {
                opt.check = selectedUOpts.filter((item) => item === opt._id).length > 0;
            });
        }

        // Check if ugsttax is an array before using forEach
        if (Array.isArray(ugsttax)) {
            ugsttax.forEach((opt) => {
                opt.check = gstopt.filter((item) => item === opt._id).length > 0;
            });
        }

        await googleAnalytic("TaxSettings", 'BussinessInfo', 'Save Button', 'Tax Settings Updated');

        // Filter out empty title or label values
        const gstdata = ugsttax.filter((obj) => obj.title !== "" || obj.label !== "");
        const upaydata = userpaymethods.filter((obj) => obj.title !== "" || obj.label !== "");

        const updates = {
            businessName: businessForm.businessName,
            businessEmail: businessForm.businessEmail,
            firstName: businessForm.firstName,
            lastName: businessForm.lastName,
            contactNumber: businessForm.contactNumber,
            memberCapacity: businessForm.memberCapacity,
            ddgst: ddgst,

            city: businessForm.city,
            address: businessForm.address,
            userpaymethods: upaydata,
            dpaymethods: dpaymethods,
            dgsttax: dgsttax,
            ugsttax: gstdata,
            gstnumber: businessForm.gstnumber,
            prefix: businessForm.prefix,
            shortcode: businessForm.shortcode,
            emailrem: emailrem,
            smsrem: smsrem,
            emailinter: businessForm.emailinter,
            smsinter: businessForm.smsinter,
            timezone: businessForm.timezone,
        };
        console.log(validateForm(e));
        let errors = validateForm(e);
        if (errors === true) {
            let saverep = await saveGBussinessInfo(updates, businessForm._id);
            if (saverep === "false") {
                setApiError(true)
            } else {
                await saverep.json();
            }

            let ip = localStorage.getItem("ipaddress");
            let browserinfo = browserName + "-" + browserVersion + ".0.0";
            let flag = "Bussiness Info Updated Successfull";
            let Eventtype = "bussinessinfo";
            let gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
            let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : "";
            logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "");
            if (!can)
                localStorage.setItem("business_save", "Profile saved successfully");
            // navigate("/bInfo");
            props.toggleShow()
            window.location.href = "/bInfo";

        }
    }

    const handleReload = () => {
        window.location.reload();
    }
    return (
        <React.Fragment>
            <>
                {/* <form
                    action="#"
                    target="_blank"
                    onSubmit={onSubmit}
                > */}
                {apiError ? <PageError handleReload={handleReload} /> : <div className="gym-main rightContent pb-4 pt-0">
                    <div className="BusinessPage__content">

                        <MDBCard className="shadow-0">
                            <MDBCardBody className='p-0'>
                                <MDBCardTitle>
                                    <label>Add Tax Settings</label>
                                    <MDBIcon fas icon="add"
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => addNewRowgst(e)}
                                        className="ripple ripple-surface ripple-surface-light btn btn-primary fs-5" />
                                </MDBCardTitle>
                                <MDBCardText>
                                    <div className="payments">
                                        <ul className="mb0">
                                            {ddgst &&
                                                <li className="Business__Details__data">
                                                    <div className="d-flex align-items-center">
                                                        <button
                                                            type="button"
                                                            className="button-link"
                                                            onClick={(e) =>
                                                                addgstMethod(
                                                                    e,
                                                                    1,
                                                                    dgsttax.filter((item) => item === 1)[0]
                                                                )
                                                            }
                                                        >

                                                            <input
                                                                type="checkbox"
                                                                checked={dgsttax.filter((item) => item === 1)[0]}
                                                            />
                                                        </button>
                                                        <span className="payment-span">GST{" "}: {" "}18%</span>
                                                        <span>
                                                            <button
                                                                type="button"
                                                                className="ml20 button-link" >
                                                                <img
                                                                    src={deleteImg}
                                                                    width="15px"
                                                                    alt="Delete"
                                                                    onClick={(e) => setGstpopup(true)}
                                                                />
                                                            </button>
                                                        </span>
                                                    </div>
                                                </li>
                                            }

                                            {ugsttax &&
                                                ugsttax.map((item, tindex) => (
                                                    <>
                                                        {" "}
                                                        <li className="Business__Details__data">
                                                            <div className="d-flex align-items-center">
                                                                <button
                                                                    type="button"
                                                                    className="button-link"
                                                                    onClick={(e) =>
                                                                        addUgstMethod(
                                                                            e,
                                                                            item._id,
                                                                            gstopt.filter(
                                                                                (titem) => titem === item._id
                                                                            )[0]
                                                                        )
                                                                    }
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={
                                                                            gstopt.filter(
                                                                                (opt) => opt === item._id
                                                                            )[0]
                                                                        }
                                                                    />
                                                                </button>
                                                                <span>
                                                                    <input
                                                                        className="payment_methods_input me-2"
                                                                        type="text"
                                                                        value={item.title}
                                                                        onChange={(e) =>
                                                                            updateTitlegst({
                                                                                title: e.target.value,
                                                                                index: tindex,
                                                                            })
                                                                        }
                                                                    />
                                                                </span>
                                                                <span className='d-flex'>
                                                                    <input
                                                                        className="payment_methods_input "
                                                                        type="number"
                                                                        pattern="[0-9]*\.?[0-9]*"
                                                                        min="0"
                                                                        max="100"
                                                                        value={item.label}
                                                                        onChange={(e) =>
                                                                            updateLabelgst({
                                                                                label: e.target.value,
                                                                                index: tindex,
                                                                            })
                                                                        }
                                                                    />
                                                                    <span>%</span>
                                                                </span>
                                                                <span>
                                                                    <button
                                                                        type="button"
                                                                        className="ml20 button-link" >
                                                                        <img
                                                                            src={deleteImg}
                                                                            width="15px"
                                                                            alt="Delete"
                                                                            onClick={(e) =>
                                                                                deleteRowgst(e, tindex)
                                                                            }
                                                                        />
                                                                    </button>
                                                                </span>
                                                            </div>
                                                        </li>
                                                    </>
                                                ))}


                                        </ul>
                                    </div>
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </div>
                </div>
                }
                <div class="modal-footer pb-0">
                    <button class="ripple ripple-surface btn btn-secondary" onClick={() => processCancel()} >
                        Cancel
                    </button>
                    <button class="ripple ripple-surface btn btn-primary" type="submit" onClick={onSubmit} >
                        Save
                    </button>
                </div>
                {/* </form> */}

            </>
            {gstpopup && (
                <>
                    <Popup
                        title="Confirmation Alert"
                        ptype="modal-sm"
                        content={
                            <>
                                <div className=" text-center">
                                    <h5>GST : 18%</h5>
                                </div>
                                <div className="thanksmain paddplan">
                                    Are you sure you want to delete this TAX setting?{" "}<br></br>
                                    <button
                                        type="button"
                                        className="btn btn-secondary button-link" onClick={() => setGstpopup(false)}>
                                        Cancel
                                    </button>
                                    {" "}
                                    &nbsp;{" "}
                                    <button
                                        type="button"
                                        className="btn btn-primary mt10 button-link" onClick={(e) => savepopup()}>
                                        Delete
                                    </button>
                                </div>
                            </>
                        }
                        handleClose={() => setGstpopup(false)}
                    />
                </>
            )}
        </React.Fragment>

    )
}
export default TaxSettings