import React, { useEffect, useRef, useState, forwardRef } from "react";
import { sessionReport } from "../services/apiServices";
import { MDBDatatable, MDBIcon } from 'mdb-react-ui-kit';
import DatePicker from 'react-datepicker';
import calImg from "./../components/images/calendar.svg";
import ExcelExportButton from "../components/exportToExcel";
import PageError from "./PageError";
import ReactGA from 'react-ga';
import Loader from "../components/Loader";

function SessionSummary() {

    const [sessionData, setSessionData] = useState([])
    const [applyFilterClicked, setApplyFilterClicked] = useState(false);
    const [resetFilterClicked, setResetFilterClicked] = useState(false)
    const [apiError, setApiError] = useState(false);
    const searchRef = useRef();
    const [mfilter, setMfilter] = useState({
        tidName: "",
        status: "",
        transtype: "",
    })
    const [basicData, setBasicData] = useState({
        columns: ['Order Id', 'Member', "Plan Name", "Start Date", "Expiry Date", 'Used Session(s)', "Balance Sessions", "Total Sessions Used", "Status"],
        rows: [],
    });
    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);

    const [loading, setLoading] = useState(false)

    const CalenderInput = forwardRef(({ value, onClick }, ref) => (
        <>
            <span className="gym-input forminput h-40">
                {value === "" ? "Custom Range" : value}
                &nbsp;
                <img
                    src={calImg}
                    onClick={onClick}
                    ref={ref}
                    alt="Custom Range"
                    title="Select Custom Range"
                />
            </span>
        </>
    ));

    const getDates = (obj) => {
        setDateRange(obj);
    };


    useEffect(() => {
        fetchData();
    }, [applyFilterClicked, resetFilterClicked]);

    const applyHandler = () => {

        setApplyFilterClicked(true)
        // fetchData()
    }



    const handleclear = () => {
        setResetFilterClicked(true)
        setMfilter({
            tidName: "",
            status: "",
            transtype: "",
        })


        setDateRange([])

        // fetchData()
    }




    let filter = {}
    async function fetchData() {
        setLoading(true)
        let startOfDate = startDate
        let endOfDate = endDate
        let Dateobj = {}
        filter.name = mfilter.tidName
        filter.status = mfilter.status
        filter.transtype = mfilter.transtype
        if (mfilter.transtype !== "" && (startOfDate && endOfDate)) {
            const startDatef = new Date(startOfDate)
            startDatef.setHours(0, 0, 0, 0)
            const utcstartDate = startDatef.toISOString();
            // location.filter.startDate = utcstartDate;
            let lastoftheday = new Date(endOfDate);
            lastoftheday.setHours(23, 59, 59, 999);
            const utcEndDate = lastoftheday.toISOString();
            Dateobj = {
                startDate: utcstartDate,
                endDate: utcEndDate,
            }
        }
        filter.dateobj = Dateobj
        const response = await sessionReport(
            JSON.parse(localStorage.getItem("loggedUserInfo")).gymid, filter
        );
        if (!response) {
            return;
        }
        if (response === "false") {
            setApiError(true)
            return;
        }

        const sessions = await response.json();
        console.log(sessions);
        setSessionData(sessions)
        setResetFilterClicked(false)
        setApplyFilterClicked(false)
    }


    const handleReload = () => {
        window.location.reload();
    };



    // console.log(sessionData);


    useEffect(() => {
        const popupRow = [];
        sessionData && sessionData.length > 0 && sessionData.filter(titem => titem.trans !== undefined && titem.member !== undefined).map((item, index) => {
            let PauseObj = (item.trans.pause && item.trans.pause.length > 0) ? item.trans.pause.sort((a, b) => b.sno - a.sno)[0] : {};
            let filterExtend = item.trans.extend && item.trans.extend.length > 0 ? item.trans.extend.filter((obj) => {
                let oldEndDate = new Date(obj.oldEndDate);
                let newEndDate = new Date(obj.newEndDate);
                let currentDate = new Date();
                return oldEndDate <= currentDate && currentDate <= newEndDate;
            }) : [];
            const rarray = [];
            rarray.push(item.trans.token);
            rarray.push(item.member.fname + " " + item.member.lname);
            rarray.push(item.plan ? item.plan.name : "");
            rarray.push(<span className='trans-date'>{new Date(item.trans.startdate).toLocaleString("en-IN", { timeZone: "Asia/Kolkata", })}</span>);
            rarray.push(<span className='trans-date'>{new Date(item.trans.expirydate).toLocaleString("en-IN", { timeZone: "Asia/Kolkata", })}</span>);
            rarray.push(item.totalsessions - item.balsessions);
            rarray.push(item.balsessions);


            rarray.push((item.totalsessions - item.balsessions) + (item.usedcomp ? item.usedcomp : 0));


            rarray.push(
                // ((item.balsessions == 0) || (new Date(item.trans.expirydate) < new Date())) ? "Inactive" : "Active"
                <div className="title">{
                    (PauseObj && PauseObj.resume) ? "paused" :
                        (filterExtend.length > 0 ? "extend" :
                            ((item.balsessions <= 0) || (new Date(item.trans.expirydate) < new Date()) ? "Inactive" : "Active"))}
                </div>
            )

            popupRow.push(rarray);
        })
        setBasicData({
            columns: ['Order Id', 'Member', "Plan Name", "Start Date", "Expiry Date", 'Used Session(s)', "Balance Sessions", "Total Sessions Used", "Status"],
            rows: popupRow
        })
        setLoading(false)
    }, [sessionData])


    const downloaddata = async (e) => {
        fetchDataAndExport();
    }

    const fetchDataAndExport = async () => {
        ExcelExportButton(basicData, "sessionReportsData");
    }
    return (
        <>
            {apiError ? (
                <PageError handleReload={handleReload} />
            ) : <div className=" mt-4">
                <div className=" d-flex flex-wrap">
                    <div className="gym-section w-100-mobile">
                        <label className="fw-bold">Search by Member/Order Id:</label>
                        <div>
                            <input
                                type="text"
                                ref={searchRef}
                                value={mfilter.tidName}
                                className="form-control select-input placeholder-active form-controlMobile"
                                // className="form-control"
                                placeholder="search here..."
                                onChange={(e) => setMfilter({
                                    ...mfilter,
                                    tidName: e.target.value
                                })}
                            />
                        </div>
                    </div>
                    <div className="gym-section w-100-mobile ms-2">
                        <label className="fw-bold">Status:</label>
                        <div>
                            <select
                                name="emailReminder"
                                id="emailReminder"
                                className="form-control select-input placeholder-active form-select shadow-1 me-3 form-selectMobile"
                                value={mfilter.status}

                                onChange={(e) => setMfilter({
                                    ...mfilter,
                                    status: e.target.value
                                })}
                            >
                                <option value="">All</option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </select>
                        </div>
                    </div>
                    <div className="gym-section w-100-mobile ms-2">

                        <label className="fw-bold">Date Filters:</label>
                        <div>
                            <select
                                name="emailReminder"
                                id="emailReminder"
                                className="form-control select-input form-select placeholder-active me-3 shadow-1 form-selectMobile"
                                value={mfilter.transtype}
                                onChange={(e) => setMfilter({
                                    ...mfilter,
                                    transtype: e.target.value
                                })}
                            >
                                <option value="">All</option>
                                <option value="sdate">Start Date filter</option>
                                <option value="edate">Expiry Date filter</option>

                            </select>
                        </div>
                    </div>
                    {mfilter.transtype !== "" && <div className="gym-section w-100-mobile ms-3">
                        <span className="customRange align-items-center">
                            <label className="fw-bold">Custom Range:</label>
                            <div className="gym-xlarge gym-larg-pos">
                                <DatePicker
                                    dateFormat={"d/M/yyyy"}
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        getDates(update)
                                    }}
                                    isClearable={false}
                                    customInput={<CalenderInput />}
                                />
                            </div>
                        </span>
                    </div>}
                    <div className="gym-section w-100-mobile ms-3">
                        <div className="mt-3 pt-1 filterApplyMobile">
                            <button
                                type="submit"
                                className="me-3 btn btn-primary"
                                onClick={() => applyHandler()}
                            >
                                Apply
                            </button>
                            <button
                                type="submit"
                                className="mt-2 btn btn-secondary"
                                onClick={() => { handleclear() }}
                            >
                                Clear
                            </button>
                            {(localStorage.getItem("loggedUserInfo")) && JSON.parse(localStorage.getItem("loggedUserInfo")).role === "admin" && <button type="submit" className="ms-3 btnheight btn btn-primary"
                                onClick={(e) => downloaddata()}
                            >
                                <MDBIcon fas icon="download" className="d-inline me-1" />Export
                            </button>}
                        </div>
                    </div>
                </div>


                <div>
                    {loading ? (
                        <div className="loader-height">
                            <Loader />
                        </div>) :
                        <div className="summeryReportsDatatable">
                            <MDBDatatable entries={25} paging={true} data={basicData} />
                        </div>
                    }

                </div>
            </div>}</>
    )
}

export default SessionSummary