import React, { useEffect, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { isEmail, isEmpty } from "../validators/helper.js";
import ReactGA from 'react-ga';
import {
  CountryDropdown,
  RegionDropdown
} from "react-country-region-selector";

import {
  getGBussinessInfo,
  saveGBussinessInfo,
} from "../services/apiServices.js";
import { MDBModalFooter, MDBRow, MDBCol } from 'mdb-react-ui-kit';




export default function BusinessForm(props) {
  const [country, setCountry] = useState("India");
  const [region, setRegion] = useState("Telangana");
  const [error, setError] = useState({});
  const [dpaymethods, setDpaymethods] = useState([]);
  const [userpaymethods, setUserpaymethods] = useState([]);
  const [selectedUOpts, setselecteUOpts] = useState([]);

  const [businessForm, setbusinessForm] = useState({
    businessName: "",
    businessEmail: "",
    firstName: "",
    lastName: "",
    contactNumber: "",
    memberCapacity: "",
    state: "",
    city: "",
    address: "",
    records: [],
    dpaymethods: dpaymethods,
    userpaymethods: userpaymethods,
  });
  async function handleClick() {
    try {
      localStorage.removeItem("loggedUserInfo");
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("password");
      //this.context.router.transitionTo('/login');
      window.location.href = "/login";
      //return;
    } catch (e) {

    }
  }
  useEffect(() => {
    async function fetchData() {
      if (localStorage.getItem("loggedUserInfo")) {
        const response = await getGBussinessInfo(
          JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
        );

        if (!response) {
          return;
        }
        const record = await response.json();
        if (!record) {
          return;
        }
        setbusinessForm(record);
        setDpaymethods(record.dpaymethods);
        let temOpts = [];
        record.userpaymethods.forEach((opt) => {
          if (opt.check) {
            temOpts.push(opt._id);
          }
        });
        setselecteUOpts(temOpts);
        setUserpaymethods(record.userpaymethods);
      }
    }
    fetchData();
  }, []);

  let validateForm = (e) => {
    console.log("Validating form...");
    let errors = {};
    if (isEmpty(businessForm.businessName)) {
      errors.businessname = "Business Name can't be blank";
    }
    if (isEmpty(businessForm.businessEmail)) {
      errors.businessEmail = "Email can't be blank";
    } else if (!isEmail(businessForm.businessEmail)) {
      errors.businessEmail = "Please enter valid email";
    }
    if (isEmpty(businessForm.firstName)) {
      errors.firstName = "First Name can't be blank";
    }
    if (isEmpty(businessForm.lastName)) {
      errors.lastName = "Last Name can't be blank";
    }
    if (isEmpty(businessForm.contactNumber)) {
      errors.contactNumber = "Contact Number can't be blank";
    } else if (!isValidPhoneNumber(businessForm.contactNumber)) {
      errors.contactNumber = "Please enter valid Contact Number";
    }

    if (isEmpty(businessForm.city)) {
      errors.city = "City can't be blank";
    }
    if (isEmpty(businessForm.address)) {
      errors.address = "Address can't be blank";
    }
    if (isEmpty(errors)) {
      return true;
    } else {
      return errors;
    }
  };

  function updateForm(value) {
    return setbusinessForm((prev) => {
      return { ...prev, ...value };
    });
  }
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  async function onSubmit(e) {
    e.preventDefault();
    ReactGA.event({
      category: 'BussinessForm',
      action: 'Save Button',
      label: 'BussinessForm Submission',
    });
    userpaymethods.forEach((opt) => {
      opt.check = selectedUOpts.filter((item) => item === opt._id).length > 0;
    });

    const updates = {
      businessName: businessForm.businessName,
      businessEmail: businessForm.businessEmail,
      firstName: businessForm.firstName,
      lastName: businessForm.lastName,
      contactNumber: businessForm.contactNumber,
      memberCapacity: businessForm.memberCapacity,
      state: region,
      city: businessForm.city,
      address: businessForm.address,
      userpaymethods: userpaymethods,
      dpaymethods: dpaymethods,
    };
    let errors = validateForm(e);
    if (errors === true) {
      await saveGBussinessInfo(updates, businessForm._id);
      localStorage.setItem("business_save", "Profile saved successfully");
      window.location.href = "/bInfo";
    } else {
      setError(errors);
    }
  }

  return (
    <>
      <div className="gym-container">
        <form
          action="#"
          target="_blank"
          className="business-form"
          onSubmit={onSubmit}
        >
          <div className="popup-bussiness">

            <MDBRow>
              <MDBCol sm={12} md={6} lg={4}>
                <div className="mt-2">
                  <label>Business Name<span className="text-danger">*</span></label>
                  <input
                    className="gym-input"
                    type="text"
                    name="bussiness_name"
                    id="bussiness_name"
                    placeholder="Busniess Name"
                    value={businessForm.businessName}
                    maxLength="30"
                    onChange={(e) => updateForm({ businessName: e.target.value })}
                  />
                  {error.businessname && (
                    <>
                      <p className="clr-red">Please Enter Name</p>
                    </>
                  )}
                </div>
              </MDBCol>
              <MDBCol sm={12} md={6} lg={4}>
                <div className="mt-2">
                  <label>Email<span className="text-danger">*</span></label>
                  <input
                    className="gym-input"
                    type="text"
                    name="business_email"
                    id="business_email"
                    required=""
                    placeholder="Email"
                    value={businessForm.businessEmail}
                    onChange={(e) => updateForm({ businessEmail: e.target.value })}
                  />
                  {error.businessEmail && (
                    <>
                      <p className="clr-red">{error.businessEmail}</p>
                    </>
                  )}
                </div>
              </MDBCol>
              <MDBCol sm={12} md={6} lg={4}>
                <div className="mt-2">
                  <label>Contact No.<span className="text-danger">*</span></label>
                  <PhoneInput
                    className="gym-input d-flex"
                    defaultCountry="IN"
                    placeholder="Enter phone number"
                    value={businessForm.contactNumber}
                    // onInput={(e) => e.target.value = e.target.value.slice(0, 15)}
                    onChange={(value) => updateForm({ contactNumber: value })}
                  />
                  {error.contactNumber && (
                    <>
                      <p className="clr-red">{error.contactNumber}</p>
                    </>
                  )}
                </div>
              </MDBCol>
              <MDBCol sm={12} md={6} lg={4}>
                <div className="mt-2">
                  <label>Country</label>
                  <CountryDropdown
                    className="gym-input"
                    value={country}
                    onChange={(val) => setCountry(val)}
                  />
                </div>
              </MDBCol>
              <MDBCol sm={12} md={6} lg={4}>
                <div className="mt-2">
                  <label>State</label>

                  <RegionDropdown
                    className="gym-input"
                    country={country}
                    value={region}
                    onChange={(val) => setRegion(val)}
                  />
                </div>
              </MDBCol>
              <MDBCol sm={12} md={6} lg={4}>
                <div className="mt-2">
                  <label>City<span className="text-danger">*</span></label>
                  <input
                    className="gym-input"
                    type="text"
                    name="city"
                    id="city"
                    required=""
                    placeholder="City"
                    value={businessForm.city}
                    onChange={(e) => updateForm({ city: e.target.value })}
                  />
                  {error.city && (
                    <>
                      <p className="clr-red">{error.city}</p>
                    </>
                  )}
                </div>
              </MDBCol>
              <MDBCol sm={12} md={6} lg={4}>
                <div className="mt-2">
                  <label>Owner First Name<span className="text-danger">*</span></label>
                  <input
                    className="gym-input"
                    type="text"
                    name="first_name"
                    id="first_name"
                    required=""
                    placeholder="First Name"
                    value={businessForm.firstName}
                    onChange={(e) => updateForm({ firstName: e.target.value })}
                  />
                  {error.firstName && (
                    <>
                      <p className="clr-red">{error.firstName}</p>
                    </>
                  )}
                </div>
              </MDBCol>
              <MDBCol sm={12} md={6} lg={4}>
                <div className="mt-2">
                  <label>Owner Last Name<span className="text-danger">*</span></label>
                  <input
                    className="gym-input"
                    type="text"
                    name="last_name"
                    id="last_name"
                    required=""
                    placeholder="Last Name"
                    value={businessForm.lastName}
                    onChange={(e) => updateForm({ lastName: e.target.value })}
                  />
                  {error.lastName && (
                    <>
                      <p className="clr-red">{error.lastName}</p>
                    </>
                  )}
                </div>
              </MDBCol>
              <MDBCol sm={12} md={6} lg={4}>
                <div className="mt-2">
                  <label>Business Address<span className="text-danger">*</span></label>
                  <input
                    className="gym-input "
                    type="text"
                    name="address"
                    id="address"
                    required=""
                    placeholder="Address"
                    value={businessForm.address}
                    onChange={(e) => updateForm({ address: e.target.value })}
                  />
                  {error.address && (
                    <>
                      <p className="clr-red">{error.address}</p>
                    </>
                  )}
                </div>
                {!props.mId && <></>}
              </MDBCol>
            </MDBRow>


          </div>
          <MDBModalFooter>
            <button
              className="btn btn-primary me-2"
              data-mdb-dismiss="modal"
              type="submit"
            >
              Save
            </button>
            <button
              className="btn btn-secondary closegym"
              data-mdb-dismiss="modal"
              type="button"
              onClick={handleClick}
            >
              Close
            </button>
          </MDBModalFooter>

        </form>
      </div>
    </>
  );
}