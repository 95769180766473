import Header from "../components/Header";
import LeftPane from "../components/LeftPane";
import React, { useEffect, useRef, useState } from "react";
import ReactGA from 'react-ga';
import Loader from "../components/Loader";
import PageError from "./PageError";
import { MDBBtn, MDBDatatable, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBSelect } from "mdb-react-ui-kit";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router";

const Forms = () => {
    const [loading, setLoading] = useState(true)
    const [apiError, setApiError] = useState(false);

    const handleReload = () => {
        window.location.reload();
    }
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
        setLoading(false);
    }, []);

    const navigate = useNavigate();

    const actionData = {
        columns: [
            { label: 'S No', field: 'name' },
            { label: 'Title', field: 'position' },
            { label: 'Description', field: 'Description' },
            { label: 'Creation Date', field: 'created_date' },
            { label: 'Total Response(s)', field: 'responses' },
            { label: 'Last Response', field: 'last_responses' },
            { label: 'Disable Form', field: 'Disable_form' },
            { label: 'Actions', field: 'contact', sort: false },
        ],
        rows: [
            {
                name: '1',
                position: 'New User',
                Description: "jzxzvzfhdjvb jksd gkfksDJGJvkhb igK",
                created_date: "11/02/2024",
                responses: "15",
                last_responses: "11/02/2024"
            },
            {
                name: '1',
                position: 'New User',
                Description: "jzxzvzfhdjvb jksd gkfksDJGJvkhb igK",
                created_date: "11/02/2024",
                responses: "15",
                last_responses: "11/02/2024"

            },
            {
                name: '1',
                position: 'New User',
                Description: "jzxzvzfhdjvb jksd gkfksDJGJvkhb igK",
                created_date: "11/02/2024",
                responses: "15",
                last_responses: "11/02/2024"

            },
            {
                name: '1',
                position: 'New User',
                Description: "jzxzvzfhdjvb jksd gkfksDJGJvkhb igK",
                created_date: "11/02/2024",
                responses: "15",
                last_responses: "11/02/2024"

            },
            {
                name: '1',
                position: 'New User',
                Description: "jzxzvzfhdjvb jksd gkfksDJGJvkhb igK",
                created_date: "11/02/2024",
                responses: "15",
                last_responses: "11/02/2024"

            },
            {
                name: '1',
                position: 'New User',
                Description: "jzxzvzfhdjvb jksd gkfksDJGJvkhb igK",
                created_date: "11/02/2024",
                responses: "15",
                last_responses: "11/02/2024"

            },

        ].map((row) => {
            return {
                ...row,
                Disable_form: (
                    <>
                        <label className="ms-1 switch">
                            <input className="form-control" type="checkbox" />
                            <span className="slider round"></span>
                        </label>
                    </>
                ),
                contact: (
                    <>
                        <div className="d-flex ">
                            <MDBIcon far icon="copy" className="me-3 fs-5" />
                            <MDBIcon fas icon="edit" className="fs-5" />
                        </div>
                    </>
                ),
            };
        }),
    }

    function createFormHandler(e, id) {
        e.preventDefault();
        navigate("/createforms", {
            state: { id: id },
        });
    }

    return (
        <>
            <LeftPane isactive="14" />
            <Header />
            {!apiError && loading && (<h4><Loader /></h4>)}
            {apiError ? <PageError handleReload={handleReload} /> :
                <div className="gym-container " id="showcase">
                    <div className="gym-main gym-main-div">
                        <div className="createplanmain w-100">
                            <h3 className="gym-large">Forms Manager</h3>

                            <MDBBtn onClick={(e) => createFormHandler(e, -1)}>Create Form</MDBBtn>
                        </div>
                        <div className="d-flex flex-wrap" >

                            <div className="gym-section w-100-mobile">
                                <label className="fw-bold">Search: </label>
                                <div>
                                    <input
                                        type="text"
                                        className="form-control select-input placeholder-active me-3 shadow-1  "
                                        placeholder="Search by Name"
                                    // value={inputText}
                                    // onChange={(e) => setInputText(e.target.value)}
                                    // onChange={inputHandler}
                                    />
                                </div>
                            </div>

                            <div className="gym-section w-100-mobile ms-3">
                                <label className="fw-bold"> Days: </label>
                                <div>
                                    <select
                                        name="shrs"
                                        id="shr"
                                        className="form-control select-input form-select placeholder-active me-3 shadow-1 "
                                    // onChange={(e) => setDayFilter(e.target.value)}
                                    // value={DayFilter}
                                    >
                                        <option value="">All</option>
                                        <option value="1">Today</option>
                                        <option value="2">Yesterday</option>
                                        <option value="3">This Week</option>
                                        <option value="4">This Month</option>
                                        <option value="5">This Year</option>
                                        <option value="6">Custom</option>
                                    </select>
                                </div>
                            </div>

                            <div className="gym-section w-100-mobile ms-3">
                                <div className="mt-3 pt-1 filterApplyMobile">
                                    <button
                                        type="submit"
                                        className="me-3 btn btn-primary"
                                    // onClick={() => applyHandler()}
                                    >
                                        Apply
                                    </button>
                                    <button
                                        type="submit"
                                        className="mt-2 btn btn-secondary"
                                    // onClick={() => { handleclear() }}
                                    >
                                        Clear
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div>
                            <MDBDatatable hover data={actionData} />
                        </div>


                    </div>
                </div>
            }

            {/* <MDBModal open={basicModal} onClose={() => setBasicModal(false)} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Create Registration Form</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div className="d-flex align-items-center">
                                <label className="fw-bold me-2" >Title: </label>
                                <input type="text" name="" id="" className="BusinessPage__contentNew w-100 "
                                // value={businessForm.title}
                                // onChange={(e) => updateForm({ title: e.target.value })}
                                />
                            </div>
                            <div className="d-flex flex-column Consentcheckbox mt-2">
                                <label className="fw-bold mt-3">Description</label>
                                <ReactQuill
                                // value={businessForm.agreement}
                                // onChange={(value) => updateForm({ agreement: value })}
                                />
                            </div>
                            <div className="gym-section w-300 w-100-mobile ms-2">
                                <label className="fw-bold">Select Plans</label>

                                <div>
                                    <MDBSelect
                                        clearButton
                                        placeholder="Select plans"
                                        multiple search
                                        data={selectData}
                                    // value={mfilter.planNme}
                                    // onChange={(e) => {
                                    //     const selectedValues = e.map(item => item.value);
                                    //     // setSelectValue(selectedValues);
                                    //     setMfilter({
                                    //         ...mfilter,
                                    //         planNme: selectedValues
                                    //     })
                                    // }}
                                    />
                                </div>
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleOpen}>
                                Close
                            </MDBBtn>
                            <MDBBtn>Save</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal> */}



        </>
    );
};

export default Forms;
