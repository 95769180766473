import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom';
import { CardBody, Col, Row } from "reactstrap";
import { numberSignUp } from "../services/apiServices";
// import { isValidPhoneNumber } from "../validators/helper.js";
import logImg from "./../components/images/GymLogo.png";
import logBg from "./../components/images/login-page-compressed.png";
import 'react-phone-number-input/style.css'
import PhoneInput, {isValidPhoneNumber } from 'react-phone-number-input'
import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";

const OTPValidation = () => {
    const navigate = useNavigate();
    const [phnumber, setPhnumber] = useState("");

    const [otp, setOtp] = useState("");
    const [notp, setNotp] = useState("");
    const [error, setError] = useState("");

    const [userExists, setUserExists] = useState(false);
    const validation = async (e) => {
        e.preventDefault();
        console.log(phnumber);
        if (otp !== notp) {
            setError("Invalid OTP.Please try again.");
        }
        else {
            navigate("/signup");
            localStorage.setItem("number", phnumber);

        }
        // Other validation logic or API calls can go here
    }
    const otpfield = async (e) => {
        e.preventDefault();
        if (!isValidPhoneNumber(phnumber)) {
            setError("Please Enter valid Contact Number");
        }
        else {
            const response = await numberSignUp({ phone: phnumber });
            let resData = await response.json();
            // console.log(resData);
            if (resData.status !== 200) {
                setError(resData.status === 400 ? "Please try again." : "Number already exits in the system!")
            }
            else {
                setError("OTP Sent Sucessfully");
                setUserExists(true);
                setNotp(resData.otp);
            }
        }
    }
    function updateLoginUser(value) {
        // e.preventDefault();
        setPhnumber(value);
        setOtp("");
        setUserExists(false);
        setError("");
    }
    console.log(phnumber);
    return (
        <React.Fragment>
            <div className="back-to-home">
                <Link to="/">
                    <MDBBtn>
                        <MDBIcon fas icon="arrow-left" />
                    </MDBBtn>
                </Link>
                {/* <Link to="/" className="back-button btn btn-icon btn-primary"><Icon.ArrowLeft className="icons" /></Link> */}
            </div>
            {/* Hero Start */}
            <section className="cover-user bg-white">
                <div className="container-fluid px-0">
                    <Row className="g-0 position-relative">
                        <Col lg={4} className="cover-my-30 ">
                            <div className="cover-user-img d-flex align-items-center justify-content-center">
                                <Row>
                                    <div className="col-12">
                                        <div className="d-flex flex-column auth-hero">
                                            <div className="mt-md-5 text-center">
                                                <Link to="#"><img src={logImg} className="gymlogo1" alt="" /></Link>
                                            </div>
                                            <div className="title-heading my-lg-auto">
                                                <div className="border-0">
                                                    <CardBody className="p-0 margin__signuo">
                                                        <h4 className="card-title">OTP Validation</h4>
                                                        <form className="login-form mt-4"
                                                            onSubmit={(e) => userExists === true ? validation(e) : otpfield(e)}
                                                        >
                                                            <div style={(error === "OTP Sent Sucessfully") ? { color: "green" } : { color: "red" }}>{error}</div>
                                                            <Row>
                                                                <Col md={12} >
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Your Mobile No.<span className="text-danger">*</span></label>
                                                                        {/* <input type="text"
                                                                            className="form-control" placeholder="Mobile Number"
                                                                            value={phnumber}
                                                                            disabled={userExists}
                                                                            onChange={(e) => updateLoginUser(e, e.target.value)}
                                                                            required /> */}
                                                                        <PhoneInput
                                                                            className="form-control"
                                                                            defaultCountry="IN"
                                                                            placeholder="Mobile Number"
                                                                            value={phnumber}
                                                                            disabled={userExists}
                                                                            // onInput={(e) => e.target.value = e.target.value.slice(0, 15)}
                                                                            onChange={(value) => updateLoginUser(value)}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                {userExists && (

                                                                    <Col md={12}>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Enter OTP<span className="text-danger">*</span></label>
                                                                            <input type="text"
                                                                                className="form-control" placeholder="Enter OTP"
                                                                                value={otp}
                                                                                onChange={(e) => setOtp(e.target.value)}
                                                                                required />
                                                                        </div>
                                                                    </Col>
                                                                )}
                                                                <div className="col-lg-12 mb-0 mt-4">
                                                                    <div className="d-grid">
                                                                        <button type="submit" className="btn btn-primary">{!userExists ? "Get OTP" : "Submit"}</button>
                                                                    </div>
                                                                </div>
                                                                <div className="mx-auto">
                                                                    <p className="mb-0 mt-3"><span className="text-dark me-2 font-14px">Already have an account ?</span> <Link to="/login" className="text-dark fw-bold">Login</Link></p>
                                                                </div>
                                                            </Row>
                                                        </form>
                                                    </CardBody>
                                                </div>
                                            </div>
                                            <div className="footer mb-md-5 text-center">
                                                <p className="mb-0 text-muted">© {(new Date().getFullYear())}{" "} Gym Admin Online.</p>
                                            </div>
                                        </div>
                                    </div>
                                </Row>
                            </div>
                        </Col>
                        <div className="col-lg-8  padding-less img" style={{ backgroundImage: `url(${logBg})` }} data-jarallax='{"speed": 0.5}'>
                            <div className="greenoverlay"></div>
                        </div>
                    </Row>
                </div>
            </section>
            {/* end section */}
        </React.Fragment>
    );
};

export default OTPValidation;
