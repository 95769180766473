// import { MDBBtn } from 'mdb-react-ui-kit';
import React from 'react';
// import CheckImg from "../components/images/circle-check.svg";

function SuccessScreen() {
    // const handleClose = () => {
    //     window.location.reload();
    // };
    return (
        <div className="success-screen">
            <div>

                <div class="success-animation">
                    <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                </div>
                <div className="success-text text-center">
                    <h2 className='mt-2'>Thank you</h2>
                    <h5>Form is submitted successfully!!</h5>

                </div>
            </div>
            {/* <div className='pt-5 pb-3'>
                <MDBBtn onClick={handleClose}>Back</MDBBtn>
            </div> */}
        </div>
    );
}

export default SuccessScreen;
