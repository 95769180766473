
import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCol,
  MDBInputGroup,
  MDBRow
} from 'mdb-react-ui-kit';
import React, { useEffect, useState } from "react";
import {
  CountryDropdown,
  RegionDropdown
} from "react-country-region-selector";
import { browserName, browserVersion } from "react-device-detect";
import ReactGA from 'react-ga';

import logo from "../components/images/GymLogo.png";

import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import TimezoneSelect from 'react-timezone-select';
import {
  getGBussinessInfo,
  logEvent,
  saveGBussinessInfo
} from "../services/apiServices.js";
import {
  isEmail,
  isEmpty,
} from "../validators/helper.js";
import UploadLogo from "./uploadLogo.js";
import PageError from './PageError';
import { googleAnalytic } from '../components/googleAnalytics';


const BusinessPage = (props) => {

  const [error, setError] = useState({});
  const [businessForm, setbusinessForm] = useState({
    businessName: "",
    businessEmail: "",
    firstName: "",
    lastName: "",
    contactNumber: "",
    memberCapacity: "",
    state: "Telangana",
    city: "",
    country: "India",
    address: "",
    prefix: "",
    gstnumber: "",
    shortcode: "",
    emailinter: "h",
    smsinter: "h",
    logourl: "",
    checkoutduration: 1.5,
  });
  const [logourl, setLogourl] = useState(localStorage.getItem("loggedUserInfo") ? (JSON.parse(localStorage.getItem("loggedUserInfo")).gymlogoUrl ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymlogoUrl : logo) : logo)
  const [imageLoaded, setImageLoaded] = useState(true);
  const [selectedTimezone, setSelectedTimezone] = useState({
    value: 'Asia/Kolkata',
    label: '(GMT+5:30) Chennai, Kolkata, Mumbai, New Delhi',
    offset: 5.5,
    abbrev: 'IST',
    altName: 'India Standard Time'
  });
  const [apiError, setApiError] = useState(false);
  useEffect(() => {

    const handleImageLoad = () => {
      setImageLoaded(true);
    };

    const handleImageError = () => {
      setImageLoaded(false);
    };
    const imgElement = document.createElement("img");
    imgElement.onload = handleImageLoad;
    imgElement.onerror = handleImageError;
    imgElement.src = logourl;

    return () => {
      imgElement.onload = null;
      imgElement.onerror = null;
    };
  }, [logourl]);
  function updateAutocheckout(e) {
    if (e.target.value >= 12) {
      updateForm({ checkoutduration: 12 })
    } else {
      updateForm({ checkoutduration: e.target.value })
    }
  }
  useEffect(() => {
    async function fetchData() {
      if (localStorage.getItem("loggedUserInfo")) {
        const response = await getGBussinessInfo(
          JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
        );

        if (!response) {
          return;
        }
        if (response === "false") {
          setApiError(true)
        } else {

          const record = await response.json();
          if (!record) {
            return;
          }
          console.log(record);
          let updatedInfo = { ...record };

          if (!updatedInfo.checkoutduration) {
            updatedInfo.checkoutduration = 1.5;
          }
          if (!updatedInfo.country) {
            updatedInfo.country = "India";
            updatedInfo.state = "Telangana";
          }

          setbusinessForm(updatedInfo);
          if (record && record.timezone) {
            setSelectedTimezone({
              abbrev: record.timezone.abbrev,
              altName: record.timezone.altName,
              label: record.timezone.label,
              offset: record.timezone.offset,
              value: record.timezone.value,
            });
          }
        }
      } else {
        window.location.href = "/login"
      }

    }

    fetchData();
  }, []);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  let validateForm = (e) => {
    console.log("Validating form...");
    let errors = {};
    if (isEmpty(businessForm.businessName)) {
      errors.businessname = "Business Name can't be blank";
    }
    if (isEmpty(businessForm.businessEmail)) {
      errors.businessEmail = "Email can't be blank";
    } else if (!isEmail(businessForm.businessEmail)) {
      errors.businessEmail = "Please enter valid email";
    }
    if (isEmpty(businessForm.firstName)) {
      errors.firstname = "First Name can't be blank";
    }
    if (isEmpty(businessForm.lastName)) {
      errors.lastName = "Last Name can't be blank";
    }
    if (isEmpty(businessForm.contactNumber)) {
      errors.contactNumber = "Contact Number can't be blank";
    } else if (!isValidPhoneNumber(businessForm.contactNumber)) {
      errors.contactNumber = "Please enter valid Contact Number";
    }
    // if (isEmpty(businessForm.memberCapacity)) {
    //   errors.memberCapacity = "Member Capacity can't be blank";
    // }

    if (isEmpty(businessForm.city)) {
      errors.city = "City can't be blank";
    }
    if (isEmpty(businessForm.address)) {
      errors.address = "Address can't be blank";
    }
    // if (isEmpty(businessForm.prefix)) {
    //   errors.prefix = "Transaction id prefix can't be blank";
    // }

    if (isEmpty(errors)) {
      return true;
    } else {
      return errors;
    }
  };






  function updateForm(value) {
    return setbusinessForm((prev) => {
      return { ...prev, ...value };
    });
  }
  function processCancel() {
    window.location.href = "/bInfo";
  }
  function setUserSession(userInfo) {
    localStorage.setItem("loggedUserInfo", JSON.stringify(userInfo));
  }


  async function onSubmit(e) {
    e.preventDefault();


    const updates = {
      businessName: businessForm.businessName,
      businessEmail: businessForm.businessEmail,
      firstName: businessForm.firstName,
      lastName: businessForm.lastName,
      contactNumber: businessForm.contactNumber,
      memberCapacity: businessForm.memberCapacity,
      city: businessForm.city,
      address: businessForm.address,
      timezone: selectedTimezone,
      state: businessForm.state,
      country: businessForm.country,
      gstnumber: businessForm.gstnumber,
      prefix: businessForm.prefix,
      shortcode: businessForm.shortcode,
      logourl: logourl || businessForm.logourl,
      checkoutduration: businessForm.checkoutduration,
    };
    console.log(updates);
    console.log(validateForm(e));
    let errors = validateForm(e);
    console.log(error);
    if (errors === true) {
      let saverep = await saveGBussinessInfo(updates, businessForm._id);
      await saverep.json();
      let ip = localStorage.getItem("ipaddress");
      let browserinfo = browserName + "-" + browserVersion + ".0.0";
      let flag = "Bussiness Info Updated Successfull";
      let Eventtype = "bussinessinfo";
      let gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
      let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : "";
      await googleAnalytic("BusinessPage", 'BussinessInfo', 'Save Button', 'BussinessInfo Updated')
      // ReactGA.event({
      //   category: 'BussinessInfo',
      //   action: 'Save Button',
      //   label: 'BussinessInfo Updated',
      // });
      logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "");

      const userInfo = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")) : "";
      userInfo.gymlogoUrl = logourl || businessForm.logourl;
      setUserSession(userInfo);
      props.toggleShow()
      window.location.href = "/bInfo";
    } else {
      setError(errors);
    }
  }

  const handleUrl = (data) => {
    console.log(data);
    console.log(typeof data);
    // updateForm({ logourl: data.route })
    setLogourl(data)
  }

  // const [convertedTime, setConvertedTime] = useState(null);

  const handleChange = (timezone) => {
    console.log(timezone);
    setSelectedTimezone(timezone);
    const localTime = new Date();
    const offset = timezone.offset * 60;
    const convertedTime = new Date(localTime.getTime() + (localTime.getTimezoneOffset() * 60000) + offset * 60000);  // Add offset in milliseconds
    console.log(convertedTime);
    // setConvertedTime(convertedTime);
  };
  const handleReload = () => {
    window.location.reload();
  }
  return (
    <>
      {/* <form
      action="#"
      target="_blank"
      onSubmit={onSubmit}
    > */}
      {apiError ? <PageError handleReload={handleReload} /> : <div className="gym-main rightContent pb-2  pt-0">
        <div className="BusinessPage__content">


          <MDBCard className="shadow-0">
            <MDBCardBody className=" p-0">
              <MDBCardText>
                <MDBRow>

                  <MDBCol>
                    <div className=" Business__Details__data">
                      <label>Business Name:<span className="text-danger">*</span></label> <label className="fw-600">
                        <input
                          className="Business__input__adjust"
                          type="text"
                          name="bussiness_name"
                          id="bussiness_name"
                          placeholder="Busniess Name"
                          value={businessForm.businessName}
                          maxLength="30"
                          onChange={(e) => updateForm({ businessName: e.target.value })}
                        />
                        {error.businessname && (
                          <>
                            <p className="clr-red">Please Enter Name</p>
                          </>
                        )}
                      </label>
                    </div>

                    <div className=" Business__Details__data ">
                      <label>Owner First Name:<span className="text-danger">*</span></label> <label className="fw-600">
                        <input
                          className="Business__input__adjust"
                          type="text"
                          name="first_name"
                          id="first_name"
                          required=""
                          placeholder="First Name"
                          value={businessForm.firstName}
                          onChange={(e) => updateForm({ firstName: e.target.value })}
                        />
                        {error.firstName && (
                          <>
                            <p className="clr-red">{error.firstName}</p>
                          </>
                        )}</label>
                    </div>
                    <div className=" Business__Details__data ">
                      <label>Owner Last Name:<span className="text-danger">*</span></label> <label className="fw-600">
                        <input
                          className="Business__input__adjust"
                          type="text"
                          name="last_name"
                          id="last_name"
                          required=""
                          placeholder="Last Name"
                          value={businessForm.lastName}
                          onChange={(e) => updateForm({ lastName: e.target.value })}
                        />
                        {error.lastName && (
                          <>
                            <p className="clr-red">{error.lastName}</p>
                          </>
                        )}
                      </label>
                    </div>

                    <div className=" Business__Details__data ">
                      <label>State:</label> <label className="fw-600">
                        <RegionDropdown
                          className="Business__input__adjust"
                          country={businessForm.country}
                          value={businessForm.state}
                          onChange={(val) => updateForm({ state: val })}
                        />
                      </label>
                    </div>

                    <div className=" Business__Details__data ">
                      <label>Country:</label> <label className="fw-600">
                        <CountryDropdown
                          className="Business__input__adjust"
                          value={businessForm.country}
                          onChange={(val) => updateForm({ country: val })}
                        />
                      </label>
                    </div>

                    <div className=" Business__Details__data ">
                      <label htmlFor="timezone">Select Timezone:</label>
                      <TimezoneSelect
                        className="label-offset w-55 ps-2 mob-w-100 mob-block"
                        value={selectedTimezone}
                        onChange={handleChange}
                      />
                      {/* {selectedTimezone && convertedTime && (
                      <div>
                        <p>Selected Timezone: {selectedTimezone.label}</p>
                        <p>Current Local Time: {new Date().toLocaleString()}</p>
                        <p>Converted Time in Selected Timezone: {convertedTime.toLocaleString()}</p>
                      </div>
                    )} */}
                    </div>
                    <div className=" Business__Details__data ">
                      <label>Transaction Id Prefix:</label> <label className="fw-600">
                        <input
                          className="Business__input__adjust"
                          type="text"
                          name="prefix"
                          id="prefix"
                          required=""
                          placeholder="Transaction Id Prefix"
                          value={businessForm.prefix}
                          onChange={(e) => updateForm({ prefix: e.target.value })}
                        />
                      </label>
                    </div>


                    <div className=" Business__Details__data ">
                      <label>Email:<span className="text-danger">*</span></label> <label className="fw-600">
                        <input
                          className="Business__input__adjust"
                          type="text"
                          name="business_email"
                          id="business_email"
                          required=""
                          placeholder="Email"
                          value={businessForm.businessEmail}
                          onChange={(e) =>
                            updateForm({ businessEmail: e.target.value })
                          }
                        />
                        {error.businessEmail && (
                          <>
                            <p className="clr-red">{error.businessEmail}</p>
                          </>
                        )}
                      </label>
                    </div>

                    <div className=" Business__Details__data ">
                      <label>Contact No:<span className="text-danger">*</span></label> <label className="fw-600">


                        <PhoneInput
                          className="Business__input__adjust custom_Select"
                          defaultCountry="IN"
                          placeholder="Enter phone number"
                          value={businessForm.contactNumber}
                          // onInput={(e) => e.target.value = e.target.value.slice(0, 15)}
                          onChange={(value) => updateForm({ contactNumber: value })}
                        />
                        {error.contactNumber && (
                          <>
                            <p className="clr-red">{error.contactNumber}</p>
                          </>
                        )}

                      </label>
                    </div>

                    <div className=" Business__Details__data ">
                      <label>City:<span className="text-danger">*</span></label> <label className="fw-600">
                        <input
                          className="Business__input__adjust"
                          type="text"
                          name="city"
                          id="city"
                          required=""
                          placeholder="City"
                          value={businessForm.city}
                          onChange={(e) => updateForm({ city: e.target.value })}
                        />
                        {error.city && (
                          <>
                            <p className="clr-red">{error.city}</p>
                          </>
                        )}
                      </label>
                    </div>

                    <div className=" Business__Details__data ">
                      <label>SMS ShortCode:</label> <label className="fw-600">
                        <input
                          className="Business__input__adjust"
                          type="text"
                          name="shortcode"
                          id="shortcode"
                          required=""
                          placeholder="ShortCode"
                          value={businessForm.shortcode}
                          onChange={(e) => updateForm({ shortcode: e.target.value })}
                        />
                      </label>
                    </div>


                    <div className=" Business__Details__data ">
                      <label>Member Capacity:</label> <label className="fw-600">
                        <input
                          className="Business__input__adjust"
                          type="text"
                          name="members_capacity"
                          id="members_capacity"
                          required=""
                          placeholder="Capacity"
                          value={businessForm.memberCapacity}
                          onChange={(e) =>
                            updateForm({ memberCapacity: e.target.value })
                          }
                        />
                      </label>
                    </div>


                    <div className=" Business__Details__data ">
                      <label>Business Address:<span className="text-danger">*</span></label> <label className="fw-600">
                        <input
                          className="Business__input__adjust"
                          type="text"
                          name="address"
                          id="address"
                          required=""
                          placeholder="Address"
                          value={businessForm.address}
                          onChange={(e) => updateForm({ address: e.target.value })}
                        />
                        {error.address && (
                          <>
                            <p className="clr-red">{error.address}</p>
                          </>
                        )}
                      </label>
                    </div>
                    <div className=" Business__Details__data ">
                      <label>GST Number:</label> <label className="fw-600">
                        <input
                          className="Business__input__adjust"
                          type="text"
                          name="gstnumber"
                          id="gstnumber"
                          required=""
                          placeholder="Gst Number"
                          value={businessForm.gstnumber}
                          onChange={(e) => updateForm({ gstnumber: e.target.value })}
                        />
                      </label>
                    </div>
                    <div className=" Business__Details__data ">
                      <label className='w-90'>Member Check-In Duration:</label>
                      <label className="fw-600 w-100">
                        <MDBInputGroup className='mb-0 w-100' textAfter='Hours'>
                          <input type='number' min="0" max="12" className='form-control w-100'
                            onChange={(e) => { updateAutocheckout(e) }}
                            value={businessForm.checkoutduration}
                          />
                        </MDBInputGroup>
                      </label>
                    </div>
                    <div className="">
                      <UploadLogo handleUrl={handleUrl} Gid={props.Gid} imageurl={imageLoaded ? logourl : logo} from={"logo"} />
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </div>
      </div>}

      <div class="modal-footer pb-0">
        <button class="ripple ripple-surface btn btn-secondary" onClick={() => processCancel()}>
          Cancel
        </button>
        <button class="ripple ripple-surface btn btn-primary" type="submit" onClick={(e) => onSubmit(e)}>
          Save
        </button>
      </div>
      {/* </form> */}
    </>
  );
};

export default BusinessPage;