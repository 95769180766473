import Header from "../components/Header";
import LeftPane from "../components/LeftPane";
import React, { useState, useEffect, forwardRef } from "react";
import AttendanceList from "./../views/AttendanceList";


const Attendance = () => {
    let selectedId = -1;
    let selData = {};

  return (
    <>
      <LeftPane isactive="5" />
      <Header />
      <AttendanceList id={selectedId} data={selData} />
    </>
  );
};

export default Attendance;
