import { React, useEffect, useState } from "react";
import { browserName, browserVersion } from "react-device-detect";
import { useNavigate } from "react-router";
import { checkPaymentStatus, getGBussinessInfo, getPaymentLinkSetting, logEvent, reqPaymentStatus } from "../services/apiServices.js";
import tranImg from "./../components/images/Transactions.svg";
import ccImg from "./../components/images/paymentsicons/card-credit.svg";
import cash from "./../components/images/paymentsicons/cash.svg";
import coupon from "./../components/images/paymentsicons/coupon.svg";
import multiple from "./../components/images/paymentsicons/multiple-payments.svg";
import phonepeIcon from "./../components/images/phonepe-icon.svg"
import otherpayments from "./../components/images/paymentsicons/other-payment.svg";
import uiImg from "./../components/images/paymentsicons/upi.svg";
import wallet from "./../components/images/paymentsicons/wallet.svg";
import PageError from "./PageError.js";
import Popup from "../components/Popup.js";
import PaymentLink from "../components/PaymentLink.js";
import DatePicker from "react-datepicker";
import {
  MDBAlert,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalHeader,
  MDBModalTitle,
} from 'mdb-react-ui-kit';
import ReverseTimer from "../components/ReverseTimer.js";

const Payments = (props) => {

  const [paymenttype, setPaymenttype] = useState(0);
  const [dpaymethods, setDpaymethods] = useState([]);
  const [userpaymethods, setUserpaymethods] = useState([]);

  const [showMulitple, setShowMulitple] = useState(false);
  const [userpayselect, setUserpayselect] = useState(null);
  const [showCoupon, setShowCoupon] = useState(false);
  const [balance, setBalance] = useState(0);
  const [balmode, setBalmode] = useState(1);
  const [cashChecked, setCashChecked] = useState(true);
  const [ccChecked, setCcChecked] = useState(true);
  const [upiChecked, setUpiChecked] = useState(true);
  const [walletChecked, setWalletChecked] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [allData, setAllData] = useState([]);
  const [Paymetlinkobj, setPaymetlinkobj] = useState({})
  const [selItems, setSelItems] = useState([]);
  const [checkedData, setcheckedData] = useState([]);
  const [totalamount, setTotalamount] = useState(0);
  const [curSelMode, setCurSelMode] = useState(1);
  const [selectedChecks, setSelecedChecks] = useState([]);
  const [paymentLink, setPaymentLink] = useState(false);
  const [sentScreenPopup, setSentScreenPopup] = useState(false)
  const [minutes, setMinutes] = useState(20)
  const [createdDate, setCreatedDate] = useState(new Date());
  const [payInfo, setPayInfo] = useState({
    cash_amt: "",
    cc_amt: "",
    upi_amt: "",
    upi_input: "",
    wallet_amt: "",
    wallet_input: "",
    userpaymethod: "",
  });



  function updateBalance(value, updatefield) {
    let othertotal = 0;
    if (curSelMode === 6) {
      othertotal += payInfo.cash_amt * 1;
      othertotal += payInfo.cc_amt * 1;
      othertotal += payInfo.upi_amt * 1;
      othertotal += payInfo.wallet_amt * 1;
    } else {
      othertotal = value;
    }
    console.log("11111111111112222222222222");
    let balamount = totalamount * 1 - othertotal * 1;
    console.log(value);
    console.log(balamount);
    setBalance(balamount < 0 ? 0 : balamount);
  }
  function updatepayInfo(value, balmode) {
    //setBalmode(balmode);
    return setPayInfo((prev) => {
      return { ...prev, ...value };
    });
  }
  const [Alerttxt, setAlerttxt] = useState("");
  const [PaymentLinkId, setPaymentLinkId] = useState("")
  const [dateselector, setdateselector] = useState(false)
  function paymentLinkSent(id) {
    let uniqueID = id;
    setPaymentLink(false);
    setSentScreenPopup(true);
    setPaymentLinkId(uniqueID);
  }
  function CreatedDate(date, id) {
    // let tselObj = selItems.filter((tObj) => tObj._id === id)[0];
    // tselObj.cdate = date;
    setCreatedDate(date);
    handleTransDate(date)
  }

  function handleTransDate(date) {
    console.log("in searchbar handleTransDate ", date);
    props.handleTransdate(date)
  }
  async function checkingPaymentStatus(val) {
    if (val === 0) {
      setSentScreenPopup(false);
      setPaymentLinkId("");
      setAlerttxt("Payment link was expired or failed. Try again.");
      return true;
    }
    if (sentScreenPopup) {
      let response = await checkPaymentStatus(PaymentLinkId);
      if (!response || response === "false") {
        setSentScreenPopup(false);
        setAlerttxt("Payment link was expired or failed. Try again.");
        setPaymentLinkId("");
        setApiError(true);
        return true;
      } else {
        const resObject = await response.json();
        console.log(resObject);
        if (resObject.message === true) {
          donePayment(-2);
          setAlerttxt("Payment Done Sucessfull.");
          setSentScreenPopup(false);
          return true;
        }
        if (resObject.message === "failed") {
          setSentScreenPopup(false);
          setPaymentLinkId("");
          setAlerttxt("Payment link was expired or failed. Try again.");
          return true;
        }
      }
    } else {
      return true;
    }
    return false;
  }
  async function requestPaymentStatus() {
    if (sentScreenPopup) {
      let response = await reqPaymentStatus(PaymentLinkId);
      console.log("after request");
      if (!response) {
        return;
      }
      if (response === "false") {
        setApiError(true)
      } else {
        const resObject = await response.json();
        console.log(resObject);
      }
    }
  }
  function handleChange(e, value, checkString) {
    // e.preventDefault();
    setBalmode(value);

    console.log(value);
    switch (checkString) {
      case "cash":
        setCashChecked(!cashChecked);
        if (!cashChecked) {
          let remain = selectedChecks.filter((item) => item !== "cash");
          setSelecedChecks(remain);
        } else {
          setSelecedChecks([...selectedChecks, "cash"]);
        }
        break;
      case "cc":
        setCcChecked(ccChecked ? false : true);
        if (!ccChecked) {
          let remain = selectedChecks.filter((item) => item !== "cc");
          setSelecedChecks(remain);
        } else {
          setSelecedChecks([...selectedChecks, "cc"]);
        }
        break;
      case "upi":
        setUpiChecked(upiChecked ? false : true);
        if (!upiChecked) {
          let remain = selectedChecks.filter((item) => item !== "upi");
          setSelecedChecks(remain);
        } else {
          setSelecedChecks([...selectedChecks, "upi"]);
        }
        break;
      case "wallet":
        setWalletChecked(walletChecked ? false : true);
        if (!walletChecked) {
          let remain = selectedChecks.filter((item) => item !== "wallet");
          setSelecedChecks(remain);
        } else {
          setSelecedChecks([...selectedChecks, "wallet"]);
        }
        break;
      default:
        // Handle unexpected values of checkString
        break;
    }

    console.log("cash value === > " + cashChecked);
    console.log("cc value === > " + ccChecked);
    console.log("upi value === > " + upiChecked);
    console.log("wallet value === > " + walletChecked);

  }
  let selecteditems = localStorage.getItem("selItems") ? JSON.parse(localStorage.getItem("selItems")) : [];

  function setToken(tTransactionId) {
    try {
      localStorage.setItem("transactionId", tTransactionId);
    } catch (e) {
      console.log("Error while creating session.. " + e);
    }
  }
  function getToken() {
    try {
      const tokenString = localStorage.getItem("transactionId");
      return tokenString;
    } catch (e) {
      console.log("error :::::: " + e);
      return;
    }
  }
  function generateToken() {
    console.log("Generating Token");
    if (!getToken()) {
      const transactionId = Math.random().toString().substring(2, 8);
      console.log(transactionId);
      setToken(transactionId);
    }
  }
  console.log(JSON.parse(localStorage.getItem("allData")));
  console.log(JSON.parse(localStorage.getItem("selItems")));
  useEffect(() => {
    processAllData();
    function processAllData() {
      let tmdata = JSON.parse(localStorage.getItem("allData"));
      let tprice = localStorage.getItem("Tprice") ? localStorage.getItem("Tprice") : 0;
      if (tmdata.isplan && tmdata.isplan === 3) {
        // for due amount 
        setPaymenttype(2);
        setTotalamount(tmdata.price);
        setdateselector(true)
        generateToken();
      } else {
        setPaymenttype(1);

        setTotalamount(tprice);

      }
    }
    function generateToken() {
      console.log("Generating Token");
      if (!getToken()) {
        const transactionId = Math.random().toString().substring(2, 8);
        console.log(transactionId);
        setToken(transactionId);
      }
    }

  }, []);

  const handleReload = () => {
    window.location.reload();
  }
  useEffect(() => {
    async function fetchData() {
      if (localStorage.getItem("loggedUserInfo")) {
        const response = await getGBussinessInfo(
          JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
        );
        if (!response) {
          return;
        }
        if (response === "false") {
          setApiError(true)
        } else {
          const record = await response.json();
          if (!record) {
            return;
          }

          setDpaymethods(record.dpaymethods);
          let temOpts = [];
          record.userpaymethods.forEach((opt) => {
            if (opt.check) {
              temOpts.push(opt._id);
            }
          });

          setUserpaymethods(record.userpaymethods);
        }
        const paymentLinkSetting = await getPaymentLinkSetting(JSON.parse(localStorage.getItem("loggedUserInfo")).gymid);
        const paymentLinkSettings = await paymentLinkSetting.json();
        if (paymentLinkSettings.gymid) {
          setPaymetlinkobj(paymentLinkSettings);
        }
      }
    }
    fetchData();
  }, []);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (sentScreenPopup) {
        event.preventDefault();
        event.returnValue = ''; // Required for Chrome to show the prompt
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [sentScreenPopup]);

  async function donePayment(value) {
    const valuee = (value === -1) ? curSelMode : value
    console.log("Payment Mode:: " + valuee);
    console.log("Payment Info:: ");
    console.log(payInfo);
    console.log(balance);
    let ip = localStorage.getItem("ipaddress");
    let gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
    let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : '';
    let browserinfo = browserName + "-" + browserVersion + ".0.0";
    let Eventtype = "payment";
    let flag = `Payment Done Successfull (${getToken()})`;
    logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);
    let tsdata = JSON.parse(localStorage.getItem("selItems"));
    let tmdata = JSON.parse(localStorage.getItem("allData"));
    let bal = balance;
    let totalAmount = totalamount;
    if (
      value == 1 ||
      value == 2 ||
      value == 3 ||
      value == 4 ||
      value == -2
    ) {
      bal = 0;
      totalAmount = totalamount;
    }

    setSelItems({
      ...tsdata,
      payinfo: {
        payInfo,
      },
      balance: bal,
      tId: getToken(),
      price: totalAmount,
      type: paymenttype,
    });
    localStorage.setItem(
      "selItems",
      JSON.stringify({
        ...tsdata,
        payinfo: {
          mode: valuee,
          balmode: balmode,
          info: payInfo,
          balance: bal,
        },
        balance: bal,
        tId: getToken(),
        totalPrice: tmdata.totalPrice,
        ref_tId: tmdata.ref_tId,
        price: totalAmount,
        type: paymenttype,
        token: tsdata[0].token ? tsdata[0].token : -1,
        PaymentLinkId: PaymentLinkId != "" ? PaymentLinkId : -1,
        transDate: tmdata.transDate,
      })
    );
    props.onsubmit();
  }

  function payAmount(obj, index) {
    console.log(obj.option);
    console.log(index);
    updateBalance(0);
    setCurSelMode(obj.option);
    if (obj && obj.option) {
      setCurSelMode(obj.option);
      if (
        obj.option === 1 ||
        obj.option === 2 ||
        obj.option === 3 ||
        obj.option === 4
      ) {
        // setShowCoupon(false);
        // setShowMulitple(false);
        donePayment(obj.option);
      }
      if (obj.option === 5) {
        setShowCoupon(true);
        setShowMulitple(false);
      }
      if (obj.option === 6) {
        setShowCoupon(false);
        setShowMulitple(true);
      }
    }
    if (index && index >= 7) {
      updatepayInfo({ userpaymethod: obj.title });
      setCurSelMode(index);
      setShowCoupon(false);
      setShowMulitple(false);
      setUserpayselect(
        <>
          <span>
            {obj.label}
            {/* <input
              className="coupen-code"
              type="text"
              value=""
              alt="coupon"
              onChange={(e) => { updatepayInfo({ usr_input: e.target.value }) }}
            /> */}
          </span>
          <span>
            <span>
              {" "}
              Amount <img className="w-11" src={tranImg} alt="Amount" />
            </span>{" "}
            <input
              className="splitinput w-50"
              type="text"
              alt="pay"
              onChange={(e) => updatepayInfo({ usr_input: e.target.value })}
              onKeyUp={(e) => updateBalance(e.target.value)}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </span>{" "}
        </>
      );
    } else {
      setUserpayselect(null);
      updatepayInfo({ userpaymethods: "" })
    }
  }
  console.log(dpaymethods);
  return (
    <>
      {apiError ? <PageError handleReload={handleReload} /> :
        <div className="">
        {/* <div
          className="gym-container"
          style={{ marginTop: "20px" }}
          id="showcase"
        ></div> */}
        <div className="">
          <div className="d-flex align-items-center ">
            {dateselector &&
              <>
                <p className="mb-0">
                  <b>
                    Transaction Date
                  </b>
                </p>
                <div className="ms-2">
                  <DatePicker
                    selected={createdDate}
                    onChange={(date) => CreatedDate(date)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="MM-dd-yyyy h:mm aa"
                    className="inp-clrdate-Small1"
                  />
                </div>
              </>
            }
          </div>
          <div className="d-flex space-between mb-2 mt-2 mob-block">
            <p>All transactions are secure and encrypted.</p>
            <p>
              <b>
                Total To Pay:
                <img className="w-11" src={tranImg} alt="Pay" /> {totalamount}
              </b>
            </p>
          </div>

          <div className="d-flex flex-wrap">
            {dpaymethods &&
              dpaymethods.filter((item) => item === 1).length > 0 && (
                <div
                  style={{ cursor: "pointer" }}
                  className="paymt-clr-1"
                  onClick={(e) => payAmount({ option: 1 })}
                > <img className="w-25" src={cash} alt="Cash" />
                  Cash
                </div>
              )}
            {dpaymethods &&
              dpaymethods.filter((item) => item === 2).length > 0 && (
                <div
                  className="paymt-clr-2"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => payAmount({ option: 2 })}
                ><img className="w-25" src={ccImg} alt="Card" />
                  Card
                </div>
              )}
            {dpaymethods &&
              dpaymethods.filter((item) => item === 3).length > 0 && (
                <div
                  className="paymt-clr-3"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => payAmount({ option: 3 })}
                ><img className="w-25" src={uiImg} alt="UPI" />
                  UPI
                </div>
              )}
            {dpaymethods &&
              dpaymethods.filter((item) => item === 4).length > 0 && (
                <div
                  className="paymt-clr-4"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => payAmount({ option: 4 })}
                ><img className="w-25" src={wallet} alt="Wallet" />
                  Wallet
                </div>
              )}
            {dpaymethods &&
              dpaymethods.filter((item) => item === 5).length > 0 && (
                <div
                  className="paymt-clr-5"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => payAmount({ option: 5 })}
                ><img className="w-25" src={coupon} alt="Coupon" />
                  Coupon
                </div>
              )}
            {userpaymethods &&
              userpaymethods.map(
                (item, index) =>
                  item.check && (
                    <>
                      <div
                        className={"paymt-clr-"
                          + (index + 7)}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => payAmount(item, index + 7)}
                      ><img className="w-25" src={otherpayments} alt={item.title} />
                        {item.title}
                      </div>
                    </>
                  )
              )}
          </div>
          <div className="d-flex flex-wrap">
            <div
              className="paymt-clr-Multiple"
              style={{ cursor: "pointer" }}
              onClick={(e) => payAmount({ option: 6 })}
            ><img className="w-25" src={multiple} alt="Multiple" />
              Multiple
            </div>

            {Paymetlinkobj.toggle
                && Paymetlinkobj.status === 1
              && <div
                className="paymt-clr-2 phonepecolor"
                style={{ cursor: "pointer" }}
                onClick={(e) => { setPaymentLink(true) }}
              ><img className="w-25" src={phonepeIcon} />
                Send Payment Link
              </div>}

          </div>


          {userpayselect && <div className="splitenter">{userpayselect}</div>}
          {showCoupon && (
            <>
              <div className="splitenter">
                <span>
                  Enter Coupon Code{" "}
                  <input
                    className="coupen-code"
                    type="text"
                    onChange={(e) =>
                      updatepayInfo({ coupon_input: e.target.value })
                    }
                    placeholder="Enter Code Here"
                  />{" "}
                </span>
                <span>
                  <span>
                    {" "}
                    Coupon Amount <img className="w-11" src={tranImg} alt="Coupon" />
                  </span>{" "}
                  <input
                    className="splitinput w-50"
                    type="text"
                    onChange={(e) =>
                      updatepayInfo({ coupon_amt: e.target.value })
                    }
                    onKeyUp={(e) => updateBalance(e.target.value)}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </span>{" "}
              </div>
            </>
          )}
          {showMulitple && (
            <>
              <div className="pt-1 pb-1">Pay balance amount using
                <div className="duepaymentradio">
                  <div
                    onClick={(e) => handleChange(e, 1, "cash")}
                    style={{ zIndex: 2 }}
                  >
                    <span>
                      {/* <input
                      type="checkbox"
                      onClick={(e) => handleChange(e, 1, "cash")}

                      checked={
                        selectedChecks.filter((item) => item == "cash").length >
                        0
                      }
                      id={"checkbox_payment_cash"}
                    />{" "} */}
                      Enter cash to be paid
                    </span>
                  </div>
                  <span>
                    <span>
                      <img className="w-11" src={tranImg} alt="Cash" />
                    </span>

                    <input
                      className="splitinput w-50"
                      type="text"
                      // disabled={cashChecked ? true : false}
                      onChange={(e) =>
                        updatepayInfo({ cash_amt: e.target.value })
                      }
                      onKeyUp={(e) => updateBalance(e.target.value)}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className="duepaymentradio">
                <div
                  onClick={(e) => handleChange(e, 2, "cc")}
                  style={{ zIndex: 2 }}
                >
                  <span>
                    {/* <input
                      type="checkbox"
                      onClick={(e) => handleChange(e, 2, "cc")}
                      checked={
                        selectedChecks.filter((item) => item == "cc").length > 0
                      }
                    />{" "} */}
                    Credit / Debit Card
                  </span>
                </div>
                <span>
                  <span>
                    <img className="w-11" src={tranImg} alt="Card" />
                  </span>
                  <input
                    className="splitinput w-50"
                    type="text"
                    // disabled={ccChecked}
                    onChange={(e) => updatepayInfo({ cc_amt: e.target.value })}
                    onKeyUp={(e) => updateBalance(e.target.value)}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </span>
              </div>

              <div className="duepaymentradio">
                <div
                  onClick={(e) => handleChange(e, 3, "upi")}
                  style={{ zIndex: 2 }}
                >
                  <span>
                    {/* <input
                      type="checkbox"
                      onClick={(e) => handleChange(e, 3, "upi")}
                      checked={
                        selectedChecks.filter((item) => item == "upi").length >
                        0
                      }
                      id={"checkbox_payment_upi"}
                    />{" "} */}
                    UPI
                  </span>
                </div>
                <div>
                  <span>
                    <input
                      className="splitinputupi"
                      type="text"
                      placeholder="number@bank.com"
                      onChange={(e) =>
                        updatepayInfo({ upi_input: e.target.value })
                      }
                    />
                  </span>
                  <span>
                    <span>
                      <img className="w-11" src={tranImg} alt="UPI" />
                    </span>{" "}
                    <input
                      className="splitinput w-50"
                      type="text"
                      // disabled={upiChecked}
                      onChange={(e) =>
                        updatepayInfo({ upi_amt: e.target.value })
                      }
                      onKeyUp={(e) => updateBalance(e.target.value)}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </span>
                </div>
              </div>

              <div className="duepaymentradio">
                <div
                  onClick={(e) => handleChange(e, 4, "wallet")}
                  style={{ zIndex: 2 }}
                >
                  <span>
                    {/* <input
                      type="checkbox"
                      onClick={(e) => handleChange(e, 4, "wallet")}
                      checked={
                        selectedChecks.filter((item) => item == "wallet")
                          .length > 0
                      }
                      id={"checkbox_payment_wallet"}
                    />{" "} */}
                    Wallets
                  </span>
                </div>
                <div>
                  <span>
                    <input
                      className="splitinputwallet splitinputupi"
                      type="text"

                      placeholder="123456789"
                      onChange={(e) =>
                        updatepayInfo({ wallet_input: e.target.value })
                      }
                    />
                  </span>
                  <span>
                    <span>
                      <img className="w-11" src={tranImg} alt="Wallets" />
                    </span>{" "}
                    <input
                      className="splitinput w-50"
                      type="text"
                      // disabled={walletChecked}
                      onChange={(e) =>
                        updatepayInfo({ wallet_amt: e.target.value })
                      }
                      onKeyUp={(e) => updateBalance(e.target.value)}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </span>
                </div>
              </div>
            </>
          )}
          {(showMulitple || showCoupon || userpayselect) && (
            <>
              {" "}
              <div className="d-flex space-between mb-2 mt-3 clr-red">
                <span> Balance due amount</span>
                <span>
                  <img className="w-11" src={tranImg} alt="Due" />
                  {balance}
                </span>
              </div>
              <div className="modal-footer text-center">
                <button
                  type="submit"
                  onClick={() => donePayment(-1)}
                  className="btn btn-primary"
                  data-mdb-dismiss="modal">
                  Done
                </button>
                &nbsp;
                <button
                  type="button"
                  onClick={props.oncancel}
                  className="btn btn-secondary"
                  data-mdb-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </>
          )}
        </div>
      </div>}
      {paymentLink && (
        <Popup
          title="Send Payment Link"
          ptype="modal-md"
          from="transactions"
          content={
            <>
              <div>
                <PaymentLink selecteditems={selecteditems} close={() => setPaymentLink(false)} price={Number(totalamount)} paymentLinkSent={(id) => paymentLinkSent(id)} createdate={createdDate} />
              </div>
            </>
          }
          handleClose={() => setPaymentLink(false)}
        />
      )}
      {sentScreenPopup && (
        <Popup
          title="Payment link"
          ptype="modal-md"
          from="transactions"
          hideClose="true"
          content={
            <>
              <div className="d-flex align-items-center justify-content-center flex-column pb-5 pt-2">
                <h5>Payment link sent successfully.</h5>
                <div className="fs-6">Open link and complete the payment of <strong> &#8377;{totalamount} </strong>within</div>
                <div className="py-3">
                  <ReverseTimer minute={minutes} checkingPaymentStatus={(val) => checkingPaymentStatus(val)} requestPaymentStatus={() => requestPaymentStatus()} />
                </div>

                <p class="mt-4 text-secondary">
                  Please do not refresh or press back.
                </p>
              </div>
              {/* <button className="btn btn-primary">Regenerate Link</button> */}
            </>
          }
          handleClose={() => { setSentScreenPopup(false); setPaymentLinkId(""); }}
        />
      )}
      <MDBAlert
        color={Alerttxt.includes("ailed") ? "danger" : "success"}
        autohide
        position='top-right'
        delay={5000}
        appendToBody
        open={Alerttxt != ""}
        onClose={() => setAlerttxt("")}
      >
        {Alerttxt}
      </MDBAlert>
    </>
  );
};

export default Payments;