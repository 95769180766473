import ReactGA from 'react-ga';
import { getGAEventTracker } from '../services/apiServices';

export async function googleAnalytic(flName, category, action, label, value) {
  //  console.log(process.env.REACT_APP_GAVALIDATION,'555555555555555555555'); 
  const response = await getGAEventTracker();
  console.log(response);
  if (!response) {
    return;
  }

  if (response === "false") {
    return;
  }
  const record = await response.json();
  if (!record) {
    return;
  }
  // console.log(record, '888888888888888888');
  // const filevalidation = JSON.parse(process.env.REACT_APP_GAVALIDATION)

  const filteredfile = record && record.length > 0 ? record.find(item => item.filename === flName) : {}
  // console.log(filteredfile, '^^^^^^^^^^^^^^^');
  // console.log(filevalidation.find(item => item.filename === flName));
  if (filteredfile.value === "1") {

    return ReactGA.event({
      category: category,
      action: action,
      label: label,
      value: value
    });
  } else {
    return;
  }

}
