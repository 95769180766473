import Header from "../components/Header";
import LeftPane from "../components/LeftPane";

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import srcImg from "./../components/images/add.svg";

const Addons = () => {
  const [plans, setPlans] = useState("");
  const navigate = useNavigate();
  async function fetchData() {
    const response = await fetch(`http://localhost:5000/api/plans/get`);
    if (!response) {

      return;
    }
    const plans = await response.json();

    console.log(plans);


    if (!plans) {
      return;
    }
    setPlans(plans.plans);
  }

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <LeftPane isactive="7" />
      <Header />
      <div
        className="gym-main rightContent"
      >
        <div
          className="gym-container"
          id="showcase"
        >
          <div className="createplanmain w-100">
            <h3 className="gym-large">Membership Plans & Addons</h3>
            <div className="mobile-d-flex">
              <a href="/addplans" className="p-1">
                <div className="createplan">
                  <span className="addic">
                    <img src={srcImg} className="w-16" alt="Create Plan" />
                  </span>
                  <span className="pl-5">
                    Create Plan
                  </span>
                </div>
              </a>
              <a href="/addslots" className="p-1">
                <div className="createplan">
                  <span className="addic">
                    <img src={srcImg} className="w-16" alt="Create Slot" />
                  </span>
                  <span className="pl-5">
                    Create Slot
                  </span>
                </div>
              </a>
              <a href="/addaddons" className="p-1">
                <div className="createplan">
                  <span className="addic">
                    <img src={srcImg} className="w-16" alt="Create Addons" />
                  </span>
                  <span className="pl-5">
                    Create Addons
                  </span>
                </div>
              </a>

            </div>
          </div>
        </div>
        <div className="remaindersmains">
          <ul className="nav nav-tabs mb-3" id="ex1" role="tablist">
            <li className="nav-item remainders-middle" role="presentation">
              <a
                className="nav-link active"
                id="ex1-tab-1"
                data-mdb-toggle="tab"
                href="/plans"
                aria-controls="ex1-tabs-1"
                aria-selected="false"
              >
                Plans
              </a>
            </li>
            <li className="nav-item remainders-middle" role="presentation">
              <a
                className="nav-link"
                id="ex1-tab-2"
                data-mdb-toggle="tab"
                href="/slots"
                aria-controls="ex1-tabs-2"
                aria-selected="false"
              >
                Slots
              </a>
            </li>
            <li className="nav-item remainders-middle" role="presentation">
              <a
                className="nav-link"
                id="ex1-tab-3"
                data-mdb-toggle="tab"
                href="/addons"
                aria-controls="ex1-tabs-3"
                aria-selected="true"
              >
                {" "}
                Add On's{" "}
              </a>
            </li>
          </ul>
        </div>

        {plans &&
          plans.map((plan) => (
            <div className="timegym">

              <div className="gym-bar-itemstext text-left">
                <span> Puma Running</span><br />
                <span className="days">Shoes</span>
              </div>
              <div className="gym-bar-itemstext">
                <span className="days"> Avaiable Sizes</span><br />
                <span> <span className="size">UK 7</span> <span className="size">UK 8</span> <span className="size">UK 9</span> <span className="size">UK 10</span> </span>
              </div>
              <div className="gym-bar-itemstext">
                <span className="days"> Rental Price </span><br />
                <span><img src="./images/Icons/Transactions.svg" width="12" /></span> 249/Hr
              </div>
              <div><img src="images/Icons/delete.svg" /></div>
            </div>
          ))}
      </div>
    </>
  );
};

export default Addons;
