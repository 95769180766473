import {
  MDBIcon, MDBTooltip,
} from 'mdb-react-ui-kit';
import { React, useEffect, useState } from "react";
import { browserName, browserVersion } from "react-device-detect";
import ReactGA from 'react-ga';
import { Link, useNavigate } from "react-router-dom";
import report from '../components/images/reportlogo.svg';
import {
  getGBussinessInfo,
  getStaffProfile,
  logEvent,
  logOutUser,
  sendVEmail
} from "../services/apiServices";
import BusinessForm from "../views/BusinessForm";
import logo from "./../components/images/GymLogo.png";
import Popup from "./Popup";
import businessImg from "./images/Business.svg";
import checkImg from "./images/Checkin.svg";
import logOutImg from "./images/Logout.svg";
import membersImg from "./images/Members.svg";
import memShipImg from "./images/Membership.svg";
import remaindersImg from "./images/Reminders.svg";
import settingsImg from "./images/Settings.svg";
import staffImg from "./images/Staff.svg";
import transcImg from "./images/Transactions.svg";
import event from "./images/activity-svgrepo-com.svg";
import dashImg from "./images/dashboard.svg";
import posImg from "./images/point-of-sale.svg";
import sucessImg from "./images/succes_icon.svg";
import { googleAnalytic } from './googleAnalytics';

export default function LeftPane(props) {
  // const [userInfo, setUserInfo] = useState("");
  const [showBusinessPop, setShowBusinessPop] = useState(false);
  const logourl = (localStorage.getItem("loggedUserInfo") ? (JSON.parse(localStorage.getItem("loggedUserInfo")).gymlogoUrl ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymlogoUrl : logo) : logo)
  const [imageLoaded, setImageLoaded] = useState(true);
  const bussName = (localStorage.getItem("loggedUserInfo") ? (JSON.parse(localStorage.getItem("loggedUserInfo")).gymname) : "");
  const [menuClass, setMenuClass] = useState(
    "gym-sidebar gym-red gym-top gym-medium gym-padding gym-collapse "
  );
  const navigate = useNavigate();


  useEffect(() => {

    const handleImageLoad = () => {
      setImageLoaded(true);
    };

    const handleImageError = () => {
      setImageLoaded(false);
    };
    const imgElement = document.createElement("img");
    imgElement.onload = handleImageLoad;
    imgElement.onerror = handleImageError;
    imgElement.src = logourl;

    return () => {
      imgElement.onload = null;
      imgElement.onerror = null;
    };
  }, [logourl]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  function closeMenu() {
    setMenuClass(
      "gym-sidebar gym-red gym-top gym-medium gym-padding gym-collapse "
    );
  }
  function showmenu() {
    setMenuClass("gym-sidebar gym-red gym-top gym-medium gym-padding");
  }
  async function handleClick() {
    try {
      // logOutUser(JSON.parse(localStorage.getItem("loggedUserInfo"))._id);
      let ip = localStorage.getItem("ipaddress");
      let browserinfo = browserName + "-" + browserVersion + ".0.0";
      let flag = "Logout Successfull";
      let Eventtype = "login";
      let gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
      let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : "";
      await googleAnalytic("LeftPane", 'Staff', 'Logout Button', 'Staff Logout')
      await logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);
      localStorage.removeItem("transaction_filter");
      localStorage.removeItem("loggedUserInfo");
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("password");
      localStorage.removeItem("ipaddress");
      window.location.href = "/login";
      //this.context.router.transitionTo('/login');
      //return;
    } catch (e) {

    }
  }

  let userInfoInSession = localStorage.getItem("loggedUserInfo") ? true : false;

  function closeWindow() {
    handleClick();
  }
  function resendEamil(_id) {
    sendVEmail(_id);
  }
  useEffect(() => {
    let getLoggedInGymInfo = async () => {
      try {
        if (localStorage.getItem("loggedUserInfo") === null) {
          navigate("/login");
          return;
        } else {

          let uInfo = await getStaffProfile(
            JSON.parse(localStorage.getItem("loggedUserInfo"))._id
          );
          if (!uInfo) {
            return;
          }
          if (uInfo === "false") {
            return;
          }
          const recinfo1 = await uInfo.json();
          console.log(recinfo1);
          if (
            JSON.parse(localStorage.getItem("loggedUserInfo")).isemailverifed ===
            "1"
          ) {
            const response = await getGBussinessInfo(
              JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
            );
            if (!response) {
              return
            }
            const recinfo = await response.json();
            // console.log(recinfo);
            // setBussName(recinfo.businessName)
            // setLogourl(recinfo ? recinfo.logourl:logo)
            if (
              !recinfo.businessName ||
              !recinfo.firstName ||
              !recinfo.lastName ||
              !recinfo.contactNumber
            ) {
              setShowBusinessPop(true);
            }
          }

        }
      } catch (e) { }
      //return { name: userInfo.gym, loc: userInfo.gymloc };
    };

    if (localStorage.getItem("loggedUserInfo")) {
      getLoggedInGymInfo();
      // fetchData();
    }
    else {
      navigate("/login");
    }
  });
  function fetchData() {

  }
  const handleLinkClick = (event, path) => {
    if (event.ctrlKey) {
      event.preventDefault();
      window.open(path, "_blank");
    } else {
      navigate(path);
    }
  };

  return (
    <>
      <header className="gym-container gym-top gym-hide-large gym-red gym-xlarge gym-padding">
        <button
          onClick={showmenu}
          className="gym-button gym-red gym-margin-right button-link"
        >
          <MDBIcon fas icon="bars" />
        </button>
        <h5 className='ms-3 mb-0 d-inline'>Gym Management</h5>
      </header>
      <nav
        className={menuClass}
        style={{ zIndex: 11, width: "320px" }}
        id="mySidebar"
      >
        {/* <a
          href="#"
          className="gym-button gym-hide-large gym-display-topleft"
          style={{ width: "100%", fontSize: 22 }}
          onClick={closeMenu}
        >

          <MDBIcon fas icon="close" className='close-icon-m' onClick={closeMenu} />
        </a> */}
        <button
          onClick={closeMenu}
          className="gym-button gym-hide-large gym-display-topleft button-link"
        >
          <MDBIcon fas icon="close" className='close-icon-m' onClick={closeMenu} />
        </button>
        <img
          src={imageLoaded ? logourl : logo}
          className="logoImg"
          alt="logo"
        />
        <div className="user-info">
          <h5 className="mx-2 pt-3 title">

            <MDBTooltip tag='div' placement="bottom"
              title={
                // (localStorage.getItem("loggedUserInfo")) ? username : " "}
                bussName !== "" ? bussName : ""}
            >
              {/* {(localStorage.getItem("loggedUserInfo")) ? username : " "} */}
              {bussName !== "" ? bussName : ""}
            </MDBTooltip>
          </h5>
          {userInfoInSession &&
            <div className='d-flex flex-wrap mb-3'>
              {JSON.parse(localStorage.getItem("loggedUserInfo")).name ? <p className="expiry mx-1 mb-0 chip bg-secondary bg-opacity-50">
                {JSON.parse(localStorage.getItem("loggedUserInfo")).name}
              </p> : null}
              {JSON.parse(localStorage.getItem("loggedUserInfo")).role ?
                <p className="expiry mx-1 mb-0 title chip bg-info bg-opacity-50">
                  {JSON.parse(localStorage.getItem("loggedUserInfo")).role}
                </p> : null}
              {JSON.parse(localStorage.getItem("loggedUserInfo")).planname ? <p className="expiry mx-1 mb-0 chip bg-success bg-opacity-50">{JSON.parse(localStorage.getItem("loggedUserInfo")).planname}</p> : null}
            </div>}

          {userInfoInSession ? (
            JSON.parse(localStorage.getItem("loggedUserInfo")).balDays <= 5 &&
            <a href="/pricing" className="btn btn-danger my-1 mx-2">Expires in {userInfoInSession && Math.floor(JSON.parse(localStorage.getItem("loggedUserInfo")).balDays)} days</a>
          ) : ""}
        </div>
        <div className='d-flex flex-column justify-content-between leftpanelHeigt '>
          <div className="gym-bar-block">
            <Link
              id="hs-bp"
              to="/dashboard"
              onClick={(event) => handleLinkClick(event, '/dashboard')}
              className={`gym-bar-item gym-button gym-hover-white ${props.isactive === "11" ? "active" : ""
                }`}
            >
              <img src={dashImg} alt="Logo" /> Dashboard
            </Link>
            <Link
              id="hs-home"
              to="/home"
              onClick={(event) => handleLinkClick(event, '/home')}
              className={`gym-bar-item gym-button gym-hover-white ${props.isactive === "1" ? "active" : ""
                }`}
            >
              <img src={posImg} alt="Logo" /> Point of Sale
            </Link>
            {userInfoInSession && JSON.parse(localStorage.getItem("loggedUserInfo")).role !== "staff" &&
              <Link
                id="hs-bp"
                to="/bInfo"
                onClick={(event) => handleLinkClick(event, '/bInfo')}
                className={`gym-bar-item gym-button gym-hover-white ${props.isactive === "2" ? "active" : ""
                  }`}
              >
                <img src={businessImg} alt="Logo" /> Business Details
              </Link>
            }
            <Link
              id="hs-mmanger"
              to="/mmanager"
              onClick={(event) => handleLinkClick(event, '/mmanager')}
              className={`gym-bar-item gym-button gym-hover-white ${props.isactive === "3" ? "active" : ""
                }`}
            >
              <img src={membersImg} alt="Logo" /> Members Management
            </Link>
            <Link
              to="/checkin"
              id="checkin"
              onClick={(event) => handleLinkClick(event, '/checkin')}
              className={`gym-bar-item gym-button gym-hover-white ${props.isactive === "5" ? "active" : ""
                }`}
            >
              <img src={checkImg} alt="Logo" /> Check-In/Check-Out
            </Link>
            <Link
              to="/report"
              id="/report"
              onClick={(event) => handleLinkClick(event, '/report')}
              className={`gym-bar-item gym-button gym-hover-white ${props.isactive === "13" ? "active" : ""}`}>
              <img src={report} alt="Logo" /> Reports
            </Link>
            <Link
              to="/staff#"
              id="staff"
              onClick={(event) => handleLinkClick(event, '/staff')}
              className={`gym-bar-item gym-button gym-hover-white ${props.isactive === "6" ? "active" : ""
                }`}
            >
              <img src={staffImg} alt="Logo" /> Staff Roles & Attendance
            </Link>
            <Link
              to="/plansandaddons"
              id="about-me"
              onClick={(event) => handleLinkClick(event, '/plansandaddons')}
              className={`gym-bar-item gym-button gym-hover-white ${props.isactive === "7" ? "active" : ""
                }`}
            >
              <img src={memShipImg} alt="Logo" /> Plans, Slots, Addons & Discounts
            </Link>

            <Link
              to="/transactions"
              id="transaction"
              onClick={(event) => handleLinkClick(event, '/transactions')}
              className={`gym-bar-item gym-button gym-hover-white ${props.isactive === "8" ? "active" : ""
                }`}
            >
              <img src={transcImg} alt="Logo" /> Transactions
            </Link>

            <Link
              to="/logEvent"
              id="logEvent"
              onClick={(event) => handleLinkClick(event, '/logEvent')}
              className={`gym-bar-item gym-button gym-hover-white ${props.isactive === "12" ? "active" : ""
                }`}
            >
              <img src={event} alt="Logo" /> Activities
            </Link>
            {/* <Link
              to="/forms"
              id="forms"
              onClick={(event) => handleLinkClick(event, '/forms')}
              className={`gym-bar-item gym-button gym-hover-white ${props.isactive === "14" ? "active" : ""
                }`}
            >
              <img src={event} alt="Logo" /> Forms
            </Link> */}
            <Link
              id="hs-receipts"
              to="/messageSettings"
              onClick={(event) => handleLinkClick(event, '/messageSettings')}
              className={`gym-bar-item gym-button gym-hover-white ${props.isactive === "4" ? "active" : ""
                }`}
            >
              <img src={remaindersImg} alt="Logo" /> Templates & Reminders
            </Link>
            <Link
              to="/settingview"
              id="settings"
              onClick={(event) => handleLinkClick(event, '/settingview')}
              className={`gym-bar-item gym-button gym-hover-white ${props.isactive === "9" ? "active" : ""
                }`}
            >
              <img src={settingsImg} alt="Logo" /> Profile
            </Link>
            <Link
              onClick={handleClick}
              id="settings"

              className={`gym-bar-item gym-button gym-hover-white ${props.isactive === "10" ? "active" : ""
                }`}
            >
              <img src={logOutImg} alt="Logo" /> Logout
            </Link>
          </div>


          <div className="PoweredTxt">
            <span className='d-block text-secondary'>Powered by</span>
            <b className='d-block fs-6'> Gym Admin Online</b>
          </div>
        </div>
      </nav >
      {
        userInfoInSession ? (
          JSON.parse(localStorage.getItem("loggedUserInfo")).isemailverifed
          && JSON.parse(localStorage.getItem("loggedUserInfo")).role &&
          (JSON.parse(localStorage.getItem("loggedUserInfo")).isemailverifed ===
            "0" && JSON.parse(localStorage.getItem("loggedUserInfo")).role !== "staff") && (
            <>
              <Popup
                title="Please verify your email to continue"
                ptype="modal-md"
                content={
                  <>
                    <div style={{ textAlign: "center" }}>
                      <img src={sucessImg} alt='logo' />
                    </div>
                    <div className="thanksmain mt40">
                      Instructions for registering for Business have been sent to{" "}
                      <b>
                        {
                          JSON.parse(localStorage.getItem("loggedUserInfo"))
                            .email
                        }
                      </b>
                      . Kindly check your spam folder if you have not received the
                      email
                    </div>
                    <br />
                    <div className="thanksmain">
                      Email not received? <br />
                      <button className="btn btn-primary mt10 button-link" onClick={resendEamil}>
                        Resend
                      </button>
                      &nbsp;
                      {/* <button className="btn btn-secondary button-link" onClick={closeWindow}>
    Close
  </button> */}
                    </div>
                  </>
                }
                handleClose={closeWindow}
              />
            </>
          )) : ""
      }
      {
        showBusinessPop && (
          <>
            <Popup
              title="Please update your Business Informaion to continue"
              ptype="modal-lg"
              content={
                <>
                  <BusinessForm />
                </>
              }
              handleClose={() => setShowBusinessPop(false)}

            />
          </>
        )
      }
    </>
  );
}