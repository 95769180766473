import React, { useEffect, useState } from 'react';
import Header from "../components/Header";
import LeftPane from "../components/LeftPane";
import { getSMSTEMP, updateSMSTemp, resetTemplete } from "../services/apiServices.js";
import ReactGA from 'react-ga';
import {
    MDBAlert,
    MDBBtn,
    MDBIcon,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle,
    MDBTabs,
    MDBTabsContent,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsPane,
    MDBTextArea,
} from 'mdb-react-ui-kit';
import DefaultTempletes from './DefaultTempletes';
import EmailTempletes from './EmailTempletes';
import SmsGateway from './SmsGateway';
import SMSTempleteSettings from './SmsTempleteSettings';
import PageError from './PageError';
import { googleAnalytic } from '../components/googleAnalytics';

export default function SMSRemainder(props) {
    let gymid = JSON.parse(localStorage.getItem("loggedUserInfo")) ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : ""
    const [form, setForm] = useState({});
    const [AllSMS, setAllSMS] = useState([]);
    const [apiError, setApiError] = useState(false);
    const [errr, seterrr] = useState('')
    const [basicModal, setBasicModal] = useState(false);
    const toggleShow = () => setBasicModal(!basicModal);
    const [resetToggle, setResetToggle] = useState(false)
    const [iconsActive, setIconsActive] = useState('tab1');

    const handleReload = () => {
        window.location.reload();
    }

    const handleIconsClick = (value) => {
        if (value === iconsActive) {
            return;
        }
        setIconsActive(value);
    };

    function updateForm(value) {
        return setForm((prev) => {
            return { ...prev, ...value };
        });
    }
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);

    async function resetTempleteHandler(e) {
        setResetToggle(true);
        e.preventDefault();
        const updates = {
            label: form.label,
            type: form.type,
        }
        console.log(updates);
        let saverep = await resetTemplete(updates, gymid);
        let response = await saverep.json();
        console.log(response);
        updateForm({ templete: response.templete });
    }

    async function saveFunction(e) {
        e.preventDefault();
        seterrr("");
        const updates = {
            label: form.label,
            templete: form.templete,
            type: form.type,
            toggle: form.toggle,
            duration: form.duration,
            smscategory: form.smscategory,
            gymid: gymid,
        }
        console.log(updates);
        let saverep = await updateSMSTemp(updates, gymid);
        if (saverep === "false") {
            setApiError(true)
        } else {
            const recinfo = await saverep.json();
            console.log(recinfo);
            // if (AllSMS.find(obj => (obj.label === recinfo.label))) {
            setAllSMS(prevState => {
                const updatedValue = prevState.map(obj => (obj.label === recinfo.label && obj.type === recinfo.type ? recinfo : obj));
                return updatedValue;
            });
            await googleAnalytic("SMSRemainder", 'Sms Templete', 'Save Button', 'Sms Templete Updated')
            // ReactGA.event({
            //     category: 'Sms Templete',
            //     action: 'Sms Templete Save Button',
            //     label: 'Sms Templete Updated',
            // });
            // } else {
            //     setAllSMS([...AllSMS, recinfo]);
            // }
        }
        toggleShow();
    };
    useEffect(() => {
        async function fetchPData() {
            if (localStorage.getItem("loggedUserInfo")) {
                const response = await getSMSTEMP(gymid);
                const templetes = await response.json();
                setAllSMS(templetes);
            }
        }
        fetchPData();
    }, [gymid]);


    const handleUpdateSmsTemplete = (value) => {
        toggleShow()
        setForm(value);
        console.log(value);
    }
    return (
        <>
            <LeftPane isactive="4" />
            <Header />
            {apiError ? <PageError handleReload={handleReload} /> :
                <div className="gym-main gym-main-div rightContent">
                    <div className="gym-container gym-cnr" id="showcase">
                        <h3 class="gym-large">SMS/Email Settings</h3>
                        <MDBTabs className='mb-3'>
                            <MDBTabsItem>
                                <MDBTabsLink onClick={() => handleIconsClick('tab1')} active={iconsActive === 'tab1'}>
                                    <MDBIcon fas icon="comment-alt" className='me-2' /> Default Templates
                                </MDBTabsLink>
                            </MDBTabsItem>
                            <MDBTabsItem>
                                <MDBTabsLink onClick={() => handleIconsClick('tab3')} active={iconsActive === 'tab3'}>
                                    <MDBIcon fas icon="cog" className='me-2' />SMS Settings
                                </MDBTabsLink>
                            </MDBTabsItem>
                            <MDBTabsItem>
                                <MDBTabsLink onClick={() => handleIconsClick('tab4')} active={iconsActive === 'tab4'}>
                                    <MDBIcon fas icon="network-wired" className='me-2' />SMS Gateways
                                </MDBTabsLink>
                            </MDBTabsItem>
                            <MDBTabsItem>
                                <MDBTabsLink onClick={() => handleIconsClick('tab5')} active={iconsActive === 'tab5'}>
                                    <MDBIcon far icon="envelope-open" className='me-2' />Email Settings
                                </MDBTabsLink>
                            </MDBTabsItem>
                        </MDBTabs>
                        <MDBTabsContent>
                            <MDBTabsPane show={iconsActive === 'tab1'} open={iconsActive === 'tab1'}>

                                <DefaultTempletes handleUpdateSmsTemplete={handleUpdateSmsTemplete} AllSMS={AllSMS} />
                            </MDBTabsPane>
                            <MDBModal show={basicModal} setShow={setBasicModal} open={basicModal} setOpen={setBasicModal} tabIndex='-1'>
                                <MDBModalDialog centered>
                                    <MDBModalContent>
                                        <MDBModalHeader>
                                            <MDBModalTitle><MDBIcon fas icon="pen" className='me-2 fs-6' />Edit SMS Template</MDBModalTitle>
                                            <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                                        </MDBModalHeader>
                                        <MDBModalBody>
                                            <MDBTextArea
                                                label={"CUSTOM MESSAGE *"}
                                                id='textAreaExample'
                                                className={form.templete === "" ? 'is-invalid' : ''}
                                                rows={4}
                                                value={form.templete}
                                                onChange={(e) => { updateForm({ templete: e.target.value }) }}
                                            />
                                            {errr !== "" && <div className="ms-1" style={{ color: "red" }}>{errr}</div>}
                                        </MDBModalBody>
                                        <MDBModalFooter>
                                            <MDBBtn color='warning' onClick={resetTempleteHandler}>Reset</MDBBtn>
                                            <MDBBtn color='secondary' onClick={toggleShow}>Close</MDBBtn>
                                            <MDBBtn onClick={(e) => (form.templete !== '' && saveFunction(e)) || (form.templete === '' && seterrr("Fields cannot be empty"))}>Save</MDBBtn>
                                        </MDBModalFooter>
                                    </MDBModalContent>
                                </MDBModalDialog>
                            </MDBModal>
                            <MDBTabsPane show={iconsActive === 'tab3'} open={iconsActive === 'tab3'}>
                                {iconsActive === 'tab3' && <SMSTempleteSettings />}
                            </MDBTabsPane>
                            <MDBTabsPane show={iconsActive === 'tab4'} open={iconsActive === 'tab4'}>
                                <SmsGateway />
                            </MDBTabsPane>

                            <MDBTabsPane show={iconsActive === 'tab5'} open={iconsActive === 'tab5'}>

                                <EmailTempletes />
                            </MDBTabsPane>

                        </MDBTabsContent>
                    </div >
                    <MDBAlert
                        color='success'
                        autohide
                        position='top-right'
                        delay={2000}
                        appendToBody
                        open={resetToggle}
                        onClose={() => setResetToggle(false)}
                    >Reset Done successfully
                    </MDBAlert>
                </div >}
        </>
    );
}