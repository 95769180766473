import React, { forwardRef, useEffect, useState } from "react";
import { getCheckInReportCount, getSlots } from '../services/apiServices';
import { MDBBtn, MDBDatatable, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle } from 'mdb-react-ui-kit';
import PageError from "./PageError";
import DatePicker from 'react-datepicker';
import { format, parseISO } from "date-fns";
import calImg from "./../components/images/calendar.svg";
import ExcelExportButton from "../components/exportToExcel.js";
import ReportsGraph from "../components/ReportsGraph";
import ReactGA from 'react-ga';
import Loader from "../components/Loader";

function CheckInCount() {

    const [basicData, setBasicData] = useState({
        columns: [],
        rows: [],
    });
    const [loading, setLoading] = useState(false)
    const [graphinfo, setGraphinfo] = useState({})
    const [card, setCard] = useState({})
    const [graphCheck, setGraphCheck] = useState(false)
    const [graphData, setGraphData] = useState({})
    const [selectedSlotMembers, setSelectedSlotMembers] = useState([]);
    const [checkIndata, setData] = useState([]);
    const [slots, setSlots] = useState([]);
    const [apiError, setApiError] = useState(false);
    const [basicModal, setBasicModal] = useState(false);
    const [DayFilter, setDayFilter] = useState("Date");
    const [DayFilter1, setDayFilter1] = useState("4");
    const [customdate, setcustomdate] = useState(DayFilter1 === "6");
    const toggleOpen = () => setBasicModal(!basicModal);
    const [dateArray, setDateArray] = useState({});
    const today = new Date();
    const currentTime = new Date();
    const startOfToday = new Date(currentTime);
    startOfToday.setHours(0, 0, 0, 0);

    const endOfToday = new Date(currentTime);
    endOfToday.setHours(23, 59, 59, 999);

    const yesterday = new Date(currentTime);
    yesterday.setDate(currentTime.getDate() - 1);
    const startOfYesterday = new Date(yesterday);
    startOfYesterday.setHours(0, 0, 0, 0);

    const endOfYesterday = new Date(yesterday);
    endOfYesterday.setHours(23, 59, 59, 999);

    const startOfWeek = new Date(currentTime);
    startOfWeek.setDate(currentTime.getDate() - currentTime.getDay());
    startOfWeek.setHours(12, 0, 0, 0);
    const endOfWeek = new Date(currentTime);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(12, 0, 0, 0);

    const startOfMonth = new Date(currentTime);
    startOfMonth.setDate(1);
    startOfMonth.setHours(0, 0, 0, 0);

    const endOfMonth = new Date(currentTime);

    const startOfYear = new Date(currentTime);
    startOfYear.setMonth(0, 1);
    startOfYear.setHours(0, 0, 0, 0);

    const endOfYear = new Date(currentTime);
    endOfYear.setMonth(11, 31);
    endOfYear.setHours(23, 59, 59, 999);

    today.setHours(23, 59, 0, 0);
    const [dateRange, setDateRange] = useState([startOfMonth, endOfMonth]);
    const [startDate, endDate] = dateRange;
    const [applyFilterClicked, setApplyFilterClicked] = useState(false)
    const [resetFilterClicked, setResetFilterClicked] = useState(false)

    const [basicData2, setBasicData2] = useState({
        columns: ["S.no", 'Member', "Trainer", 'Date', 'Check-In', 'Check-Out', 'Hours Spent'],
        rows: [],
    })


    const handleApplyAndToggle = () => {
        const sDate = DayFilter1 === "1" ? startOfToday : (DayFilter1 === "2" ? startOfYesterday : (DayFilter1 === "3" ? startOfWeek : (DayFilter1 === "4" ? startOfMonth : (DayFilter1 === "5" ? startOfYear : (DayFilter1 === "6" ? startDate : null)))))
        const eDate = DayFilter1 === "1" ? endOfToday : (DayFilter1 === "2" ? endOfYesterday : (DayFilter1 === "3" ? endOfWeek : (DayFilter1 === "4" ? endOfMonth : (DayFilter1 === "5" ? endOfYear : (DayFilter1 === "6" ? endDate : null)))))
        if (sDate !== null && eDate !== null) {
            setDateRange([sDate, eDate])
        } else {
            setDateRange([])
        }

        setApplyFilterClicked(true)
    }
    const handleResetAndToggle = () => {

        setDayFilter1("4")
        setDateRange([startOfMonth, endOfMonth])
        setDayFilter("Date")
        setResetFilterClicked(true)

    }

    const handleReload = () => {
        window.location.reload();
    };

    useEffect(() => {
        setcustomdate(DayFilter1 === "6" ? true : false);
    }, [DayFilter1]);

    useEffect(() => {
        fetchData();
        fetchSData();
    }, [applyFilterClicked, resetFilterClicked]);

    const getDates = (obj) => {
        setDateRange(obj);
    };



    const CalenderInput = forwardRef(({ value, onClick }, ref) => (
        <>
            <span className="gym-input forminput h-40">
                {value === "" ? "Custom Range" : value}
                &nbsp;
                <img
                    src={calImg}
                    onClick={onClick}
                    ref={ref}
                    alt="Custom Range"
                    title="Select Custom Range"
                />
            </span>
        </>
    ));

    const fetchData = async () => {
        try {
            setLoading(true)
            let startOfDate = startDate
            let endOfDate = endDate
            let Dateobj = {}
            // console.log(startDate, endDate);
            if ((startOfDate && endOfDate)) {
                const startDatef = new Date(startOfDate)
                startDatef.setHours(0, 0, 0, 0)
                const utcstartDate = startDatef.toISOString();
                // location.filter.startDate = utcstartDate;
                let lastoftheday = new Date(endOfDate);
                lastoftheday.setHours(23, 59, 59, 999);
                const utcEndDate = lastoftheday.toISOString();

                //             location.filter.endDate = utcEndDate;
                Dateobj = {
                    startDate: utcstartDate,
                    endDate: utcEndDate,
                }
            }

            const response = await getCheckInReportCount(JSON.parse(localStorage.getItem("loggedUserInfo")).gymid, Dateobj);
            if (response === "false") {
                setApiError(true);
                return;
            }
            const checkIns = await response.json();

            processData(checkIns, DayFilter)
            setApplyFilterClicked(false)
            setResetFilterClicked(false)
        } catch (error) {
            console.error("Error fetching check-in data:", error);
            setApiError(true);
        }
    };

    const processData = (checkIns, dateformate) => {


        if (dateformate === "Month") {
            // const formattedData = checkIns.reduce((acc, cur) => {
            //     const date = new Date(cur.date);
            //     const month = new Date(cur.date).toLocaleString('default', { month: 'short' });
            //     const year = date.getFullYear();
            //     if (!acc[month]) {
            //         acc[month] = { date: `${month}-${year}`, data: [] };
            //     }
            //     acc[month].data.push(cur);
            //     return acc;
            // }, {});

            // const finalResponse = Object.values(formattedData);
            const formattedData = checkIns.reduce((acc, cur) => {
                const date = new Date(cur.date);
                const month = date.toLocaleString('default', { month: 'short' });
                const year = date.getFullYear();
                const monthYearKey = `${month}-${year}`;

                if (!acc[monthYearKey]) {
                    acc[monthYearKey] = { date: `${month}-${year}`, data: [] };
                }
                acc[monthYearKey].data.push(cur);
                return acc;
            }, {});

            const finalResponse = Object.values(formattedData);

            setData(finalResponse);
        } else if (dateformate === "Week") {
            // const formattedData = checkIns.reduce((acc, cur) => {
            //     const date = new Date(cur.date);
            //     const month = date.toLocaleString('default', { month: 'short' });
            //     const weekOfMonth = getWeekOfMonth(date);
            //     const weekLabel = `${month} ${weekOfMonth === 1 ? weekOfMonth + "st" : weekOfMonth === 2 ? weekOfMonth + "nd" : weekOfMonth === 3 ? weekOfMonth + "rd" : weekOfMonth + "th"}  week`;
            //     if (!acc[weekLabel]) {
            //         acc[weekLabel] = { date: weekLabel, data: [] };
            //     }
            //     acc[weekLabel].data.push(cur);
            //     return acc;
            // }, {});

            // const finalResponse = Object.values(formattedData);
            const formattedData = checkIns.reduce((acc, cur) => {
                const date = new Date(cur.date);
                const month = date.toLocaleString('default', { month: 'short' });
                const year = date.getFullYear();
                const weekOfMonth = getWeekOfMonth(date);
                const weekLabel = `${month} ${weekOfMonth === 1 ? weekOfMonth + "st" : weekOfMonth === 2 ? weekOfMonth + "nd" : weekOfMonth === 3 ? weekOfMonth + "rd" : weekOfMonth + "th"} week ${year}`;

                if (!acc[weekLabel]) {
                    acc[weekLabel] = { date: weekLabel, data: [] };
                }
                acc[weekLabel].data.push(cur);
                return acc;
            }, {});

            const finalResponse = Object.values(formattedData);

            setData(finalResponse);
            // console.log(finalResponse);

        } else if (dateformate === "Year") {
            const formattedData = checkIns.reduce((acc, cur) => {
                const date = new Date(cur.date);
                const year = date.getFullYear().toString();
                if (!acc[year]) {
                    acc[year] = { date: year, data: [] };
                }
                acc[year].data.push(cur);
                return acc;
            }, {});

            const finalResponse = Object.values(formattedData);
            setData(finalResponse);
        } else {
            const formattedData = checkIns.reduce((acc, cur) => {
                const dateKey = format(new Date(parseISO(cur.date)), "dd-MM-yyyy");

                if (!acc[dateKey]) {
                    acc[dateKey] = { date: dateKey, data: [] };
                }
                acc[dateKey].data.push(cur);
                return acc;
            }, {});

            const finalResponse = Object.values(formattedData);

            // console.log(finalResponse);
            setData(finalResponse);
        }
    }
    const getWeekOfMonth = (date) => {
        const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        const dayOfWeek = firstDayOfMonth.getDay();
        const firstWeekStart = firstDayOfMonth - (dayOfWeek * 86400000);
        const weekNumber = Math.floor((date - firstWeekStart) / (7 * 86400000)) + 1;
        return weekNumber;
    };

    const fetchSData = async () => {
        try {
            const response = await getSlots(localStorage.getItem("loggedUserInfo") && JSON.parse(localStorage.getItem("loggedUserInfo")).gymid);
            if (!response) return;
            if (response === "false") {
                setApiError(true);
                return;
            }
            const slots = await response.json();
            // console.log(slots);
            const adjustedSlots = slots.map(slot => ({
                ...slot,
                shrs: slot.shflag === "1" ? slot.shrs : (slot.shrs === "12" ? 12 : parseInt(slot.shrs) + 12),
                smins: slot.smins === "1" ? "00" : slot.smins === "2" ? "15" : slot.smins === "3" ? "30" : "45",
                ehrs: slot.ehflag === "1" ? slot.ehrs : (slot.ehrs === "12" ? 12 : parseInt(slot.ehrs) + 12),
                emins: slot.emins === "1" ? "00" : slot.emins === "2" ? "15" : slot.emins === "3" ? "30" : "45"
            }));
            // console.log(adjustedSlots);


            adjustedSlots.sort((a, b) => {

                const shrsA = parseInt(a.shrs);
                const shrsB = parseInt(b.shrs);

                return shrsA - shrsB;
            });

            setSlots(adjustedSlots);
        } catch (error) {
            console.error("Error fetching slots data:", error);
            setApiError(true);
        }
    };

    useEffect(() => {

        const calculateSlotCounts = () => {
            const slotCounts = {};

            checkIndata.forEach(dateData => {
                const dateKey = dateData.date;
                dateData.data.forEach(checkIn => {

                    const checkInTime = new Date(checkIn.intime);
                    const time = checkInTime.getHours() * 60 + checkInTime.getMinutes();

                    if (!slotCounts[dateKey]) {
                        slotCounts[dateKey] = { Others: 0 };
                    }

                    let withinSlotRange = false;
                    slots.forEach(slot => {
                        const slotStart = slot.shrs * 60 + parseInt(slot.smins);
                        const slotEnd = slot.ehrs * 60 + parseInt(slot.emins);
                        if (time >= slotStart && time <= slotEnd) {
                            slotCounts[dateKey][slot.name] = (slotCounts[dateKey][slot.name] || 0) + 1;
                            withinSlotRange = true;
                        }
                    });

                    if (!withinSlotRange) {
                        slotCounts[dateKey].Others += 1;
                    }
                });
            });

            return slotCounts;
        };

        // console.log(slots);

        const getOtherMembers = (date) => {
            const selectedData = checkIndata.find(data => data.date === date);
            console.log("Selected data:", selectedData);
            if (!selectedData || !selectedData.data) {
                return [];
            }

            const otherMembers = selectedData.data.filter(memberData => {
                const checkInTime = new Date(memberData.intime);
                const time = checkInTime.getHours() * 60 + checkInTime.getMinutes();
                const withinSlotRange = slots.some(slot => {
                    const slotStart = slot.shrs * 60 + parseInt(slot.smins);
                    const slotEnd = slot.ehrs * 60 + parseInt(slot.emins);
                    return time >= slotStart && time <= slotEnd;
                });
                // console.log(withinSlotRange);
                return !withinSlotRange;
            });

            return otherMembers;
        };


        const getMembersForSlot = (date, slotName) => {

            const selectedData = checkIndata.find(data => data.date === date);
            console.log("Selected data:", selectedData);
            if (!selectedData || !selectedData.data) {
                return [];
            }
            console.log(date, slotName);
            if (slotName === "Total") {
                return selectedData.data
            }
            const selectedSlot = slots.find(slot => slot.name === slotName);
            if (!selectedSlot) {
                return [];
            }
            const slotStart = selectedSlot.shrs * 60 + parseInt(selectedSlot.smins);
            const slotEnd = selectedSlot.ehrs * 60 + parseInt(selectedSlot.emins);


            const membersWithinSlotRange = selectedData.data.filter(memberData => {
                const checkInTime = new Date(memberData.intime);
                const time = checkInTime.getHours() * 60 + checkInTime.getMinutes();
                return time >= slotStart && time <= slotEnd;
            });

            return membersWithinSlotRange;
        };
        const handleClik = (data) => {

            setBasicData2({
                columns: ["S.no", 'Member', "Trainer", 'Date', 'Check-In', 'Check-Out', 'Hours Spent'],
                rows: []
            })
            if (data.slotName === "Others") {
                const members = getOtherMembers(data.date);
                console.log(members);
                setSelectedSlotMembers(members);
            } else {
                const members = getMembersForSlot(data.date, data.slotName);
                console.log(members);
                setSelectedSlotMembers(members);
            }
            // console.log(data);
            setDateArray(data)
            setBasicModal(true);

        };
        console.log(checkIndata);
        const generateTableData = () => {
            const slotCounts = calculateSlotCounts();


            const uniqueSlotNames = new Set(slots.map(slot => slot.name));
            // console.log(slotCounts);
            const tableData = Object.keys(slotCounts)
                .filter(date => Object.values(slotCounts[date]).reduce((acc, val) => acc + val, 0) > 0)
                .sort((a, b) => new Date(b) - new Date(a))
                .map((date, index) => {
                    const slotData = [...uniqueSlotNames].map(column => slotCounts[date][column] || 0);
                    const othersCount = slotCounts[date].Others || 0;
                    // const rowSum = slotData.reduce((acc, val) => acc + val, 0);
                    const rowSum = checkIndata.filter((item) => item.date === date)
                    // const date1=checkIndata[index].date
                    // console.log(rowSum);
                    const uniqueRowSum = rowSum[0].data && rowSum[0].data.reduce((acc, current) => {
                        const x = acc.find(item => item.id === current.id);
                        if (!x) {
                            return acc.concat([current]);
                        } else {
                            return acc;
                        }
                    }, []);

                    // console.log(uniqueRowSum);
                    // console.log(date1);
                    const row = [
                        date,
                        ...slotData.map((value, index) => (
                            <button className="popupmember button-link link-color"
                                key={index}
                                onClick={() => handleClik({ date: date, slotName: [...uniqueSlotNames][index] })}
                            >
                                {value}
                            </button>
                        )),
                        <button className="popupmember button-link link-color"
                            onClick={() => handleClik({ date: date, slotName: "Others" })}
                        >
                            {othersCount}
                        </button>,
                        <button className="popupmember button-link link-color"
                            onClick={() => handleClik({ date: date, slotName: "Total" })}
                        >
                            {uniqueRowSum.length}
                        </button>
                    ];
                    return row;
                });

            setBasicData({
                columns: [`Date`, ...slots.map(slot => `${slot.name} (${`${slot.shrs <= 12 ? slot.shrs : slot.shrs - 12}:${slot.smins} ${slot.shflag === "1" ? "AM" : "PM"} To ${slot.ehrs <= 12 ? slot.ehrs : slot.ehrs - 12}:${slot.emins} ${slot.ehflag === "1" ? "AM" : "PM"}`})`), 'Others', 'Total Check-Ins'],
                rows: tableData
            });
            setLoading(false)
        };
        generateTableData();
    }, [checkIndata, slots]);


    useEffect(() => {


        const popupRow = [];
        let snumber = 1

        selectedSlotMembers && selectedSlotMembers.length > 0 && selectedSlotMembers.forEach((item, index) => {

            const memberName = item.member[0].fname + " " + item.member[0].lname
            const attendance = item
            const rarray = [];

            rarray.push(snumber++)
            rarray.push(memberName);
            rarray.push(attendance.trainer ? attendance.trainer : "--");

            rarray.push(attendance.intime ? format(parseISO(attendance.intime), "yyyy-MM-dd") : "NA");
            rarray.push(
                new Date(attendance.intime ? attendance.intime : "NA")
                    .toLocaleTimeString("en-IN", {
                        timeZone: "Asia/Kolkata",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                    })
            );
            rarray.push(
                (new Date(attendance.outtime)
                    .toLocaleTimeString("en-IN", {
                        timeZone: "Asia/Kolkata",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                    })) === "Invalid Date" ? "NA" : new Date(attendance.outtime)
                        .toLocaleTimeString("en-IN", {
                            timeZone: "Asia/Kolkata",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                        })
            );
            if (attendance.intime) {

                const intime = new Date(attendance.intime);
                const outtime = new Date(attendance.outtime);

                if (!isNaN(intime) && !isNaN(outtime)) {
                    const timeDiff = Math.abs(outtime - intime);
                    const hours = Math.floor(timeDiff / (60 * 60 * 1000));
                    const minutes = Math.floor((timeDiff % (60 * 60 * 1000)) / (60 * 1000));

                    rarray.push(`${hours.toString().padStart(2, '0')}: ${minutes.toString().padStart(2, '0')}`);

                } else {
                    rarray.push("--");
                }
            } else {
                rarray.push("--");
            }

            popupRow.push(rarray);


        })

        setBasicData2({
            columns: ["S.no", 'Member', "Trainer", 'Date', 'Check-In', 'Check-Out', 'Hours Spent'],
            rows: popupRow
        })
    }, [selectedSlotMembers])

    const handleClickOutside = () => {
        setSelectedSlotMembers([]);

        toggleOpen();

    }


    const downloaddata = (data, title) => {
        ExcelExportButton(data, title);
    }

    useEffect(() => {

        const generatingGraphData = () => {
            const basrow = basicData.rows;
            const columns = basicData.columns;

            // Extracting labels (months)
            const labels = basrow.map(row => row[0]);

            // Extracting datasets
            const datasets = [];
            for (let i = 1; i < columns.length - 1; i++) {
                const columnName = columns[i];
                const data = basrow.map(row => parseInt(row[i].props.children));
                datasets.push({
                    label: columnName,
                    data,
                    backgroundColor: `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)})`,
                });
            }

            setGraphinfo({
                title: "Check-In Count",
                bar: "Check-In(s)",
                xtitle: "Check-In(s)",
                ytitle: `${DayFilter}(s)`,
            })
            setGraphData(
                {
                    labels,
                    datasets,
                });
        };
        const maxmin = () => {
            const sums = {};
            const basrow = basicData.rows;
            const columns = basicData.columns;
            // console.log(basrow, columns);

            for (let i = 1; i < columns.length - 2; i++) {
                const columnName = columns[i];
                sums[columnName] = 0;

                for (let j = 0; j < basrow.length; j++) {
                    const columnValue = basrow[j][i];

                    sums[columnName] += parseInt(columnValue.props.children);
                }
            }
            const labels = Object.keys(sums);
            const data = Object.values(sums);

            const mergedData = mergeLabelsAndData(labels, data);
            let { max = 0, maxLabel, min = 0, minLabel } = findMaxMinValuesWithLabels(mergedData);


            setCard({
                max: { label: maxLabel, value: max > 0 ? max : "--" },
                min: { label: minLabel, value: min > 0 ? min : "--" }
            })
        }
        generatingGraphData()
        maxmin()
    }, [basicData, DayFilter])


    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);






    const mergeLabelsAndData = (labels, data) => {
        const mergedData = {};
        labels.forEach((label, index) => {
            mergedData[label] = data[index];
        });
        return mergedData;
    };




    const findMaxMinValuesWithLabels = (data) => {
        let maxLabel, minLabel;
        let max = Number.MIN_SAFE_INTEGER;
        let min = Number.MAX_SAFE_INTEGER;
        for (const label in data) {
            if (data.hasOwnProperty(label)) {
                const value = data[label];
                if (value > max) {
                    max = value;
                    maxLabel = label;
                }
                if (value < min && value > 0) {
                    min = value;
                    minLabel = label;
                }
            }
        }

        return { max, maxLabel, min, minLabel };
    };


    return (
        <>
            {apiError ? (
                <PageError handleReload={handleReload} />
            ) : (<>
                <div >
                    <div className="d-flex align-items-center TrainerSummeryCustomHead">
                        <div className="d-flex">

                            <div class="filters_Count bg-success bg-opacity-10"><p class="text-center mb-1 fw-600">Highest Preferred Slot</p> {card.max ? card.max.label : "--"}: <b>{card.max ? card.max.value : 0} </b></div>
                            <div class="filters_Count bg-danger bg-opacity-10"><p class="text-center mb-1 fw-600">Lowest Preferred Slot</p>{card.min ? card.min.label : "--"}: <b> {card.min ? card.min.value : 0}</b></div>

                        </div>
                    </div>
                    <div className="trainerSummeryFilters d-flex align-items-center mb-3 mt-3">
                        <div className="gym-section w-100-mobile ms-3">
                            <label className="me-2 fw-bold"> Breakdown: </label>
                            <div className="mob-w-100 ">
                                <select
                                    name="shrs"
                                    id="shr"
                                    className="form-select placeholder-active active mob-w-100"
                                    onChange={(e) => setDayFilter(e.target.value)}
                                    value={DayFilter}
                                >
                                    <option value="Date">Date</option>
                                    <option value="Week">Weekly</option>
                                    <option value="Month">Monthly</option>
                                    <option value="Year">Yearly</option>

                                </select>
                            </div>
                        </div>
                        <div className="gym-section w-100-mobile ms-3">
                            <label className="me-2 fw-bold"> Days: </label>
                            <div className="mob-w-100 ">
                                <select
                                    name="shrs"
                                    id="shr"
                                    className="form-select placeholder-active active mob-w-100"
                                    onChange={(e) => setDayFilter1(e.target.value)}
                                    value={DayFilter1}
                                >
                                    <option value="7">All</option>
                                    <option value="1">Today</option>
                                    <option value="2">Yesterday</option>
                                    <option value="3">This Week</option>
                                    <option value="4">This Month</option>
                                    <option value="5">This Year</option>
                                    {/* <option value="Week">Weekly</option> */}
                                    {/* <option value="Month">Monthly</option>
                                    <option value="Year">Yearly</option> */}
                                    <option value="6">Custom</option>

                                </select>
                            </div>
                        </div>
                        {customdate && <div className="d-flex align-items-center ms-4 mob-w-100">
                            <label className="me-2 fw-bold"> Select Custom Date: </label>
                            <div className="gym-xlarge  gym-larg-pos mob-w-100">
                                <DatePicker
                                    dateFormat={"d/M/yyyy"}
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        getDates(update);
                                    }}
                                    isClearable={false}
                                    customInput={<CalenderInput />}
                                />
                            </div>
                        </div>}

                        <div className="mob-div-center mt-3 pt-1 filterApplyMobile">
                            <MDBBtn className="ms-2" onClick={(e) => handleApplyAndToggle()}>Apply</MDBBtn>
                            <MDBBtn className="ms-2" color="secondary" onClick={(e) => handleResetAndToggle()}>Clear</MDBBtn>
                            <MDBBtn className="ms-2" onClick={() => setGraphCheck(!graphCheck)}>{graphCheck ? <span><MDBIcon fas icon="table" className="me-1" />Table </span> : <span ><MDBIcon fas icon="chart-bar" className="me-1" />Graph</span>}</MDBBtn>
                            {!graphCheck && <MDBBtn className="ms-2" onClick={() => downloaddata(basicData, "Check-In Count")}>Export</MDBBtn>}
                        </div>
                    </div>

                    {!graphCheck && <div className="trainerSummeryDataTable">
                        {loading ? (<div className="loader-height"> <Loader /></div>) : <>
                            <MDBDatatable entries={25} paging={true} data={basicData} /></>}

                    </div>}
                    {/* {graphCheck && <div className={graphData.label && graphData.label.length < 10 ? "w-50 border p-4 mb-4 shadow-4" : (graphData.label && graphData.label.length < 20 ? `w-${Math.round((graphData.label && graphData.label.length) / 2)}0 border p-4 mb-4 shadow-4` : "w-100 border p-4 mb-4 shadow-4")}>
                        <ReportsGraph info={graphData} />
                    </div>} */}
                    {graphCheck && <div className="w-100 border p-4 mb-4 shadow-4 mob-w-100 mob-p-0">
                        {loading ? (<div className="loader-height"> <Loader /></div>) : <>
                            <ReportsGraph data={graphData} info={graphinfo} /></>}
                    </div>}
                </div>



                <MDBModal open={basicModal} setOpen={setBasicModal} tabIndex='-1'>
                    <MDBModalDialog size="lg">
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>{dateArray.slotName === "Total" ? "Total check-In Members" : `Slot: ${dateArray.slotName}`}</MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={() => { handleClickOutside() }}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                {basicModal && <div className="trainerMemberSummeryDataTable">
                                    <MDBDatatable entries={25} data={basicData2} />

                                </div>}
                            </MDBModalBody>
                            <MDBModalFooter className="pe-4 pb-3">
                                <MDBBtn className="ms-2" onClick={() => downloaddata(basicData2, dateArray.slotName === "Total" ? "Total check-In Members" : dateArray.slotName)}>download</MDBBtn>
                            </MDBModalFooter>

                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>

            </>)}
        </>
    );
}

export default CheckInCount;
