import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import tranImg from "./../components/images/Transactions.svg";
import { getTransaction, refundTransaction, getGBussinessInfo, sendReceiptMail, numbermessage, clearsessions, getInvoicehtmlcontent } from "../services/apiServices";
import Popup from "../components/Popup";
import Payments from "../views/Payments";
import ReceiptPrint from "../views/ReicptPrint";
import ReactGA from 'react-ga';
import { format, parseISO } from "date-fns";
import {
  MDBBtn,
  MDBRadio,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBCheckbox,
  MDBInput,
  MDBIcon,
  MDBDatatable
} from 'mdb-react-ui-kit';
import logo from "../components/images/GymLogo.png";
import ReactDOMServer from "react-dom/server";
import BillInvoice from "../views/BillInvoice.js";
import { googleAnalytic } from "./googleAnalytics";





export default function Footer(props) {
  const navigate = useNavigate();
  const [openPayment, setOpenPayment] = useState(false);
  const [openReceipt, setOpenReceipt] = useState(false);
  const [tData, setTData] = useState("");
  const [mem, setmem] = useState("");
  const [transDate, setTransDate] = useState(new Date())
  const [smessage, setSMessage] = useState(false);
  const [basicModal, setBasicModal] = useState(false);
  const toggleOpen = () => setBasicModal(!basicModal);
  const [refundContent, setRefundContent] = useState(false);
  const [percentrefund, setpercentrefund] = useState(1);
  const [refundvalue, setRefundvalue] = useState(0);
  const [refundreason, setRefundreason] = useState("");
  const [errr, seterrr] = useState("");
  const [busData, setBusdata] = useState("");
  const [userdata, setUserdata] = useState([])
  const [groupd, setGroupd] = useState("");
  const [DueTrans, setDueTrans] = useState(false);
  const [Rtoken, setRtoken] = useState(true);
  const [mailData, setMailData] = useState([])
  const [selectedEmails, setSelectedEmails] = useState(mailData || []);
  const [OtherData, setOtherData] = useState("")
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const [numberData, setNumberData] = useState([])
  const [selectedNumbers, setSelectedNumbers] = useState(numberData || []);
  const [selectType, setSelectType] = useState("email")
  const logourl = localStorage.getItem("loggedUserInfo") ? (JSON.parse(localStorage.getItem("loggedUserInfo")).gymlogoUrl ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymlogoUrl : logo) : logo;
  const [imageLoaded, setImageLoaded] = useState(true);
  const [clearSess, setClearSess] = useState(null);
  const [apiError, setApiError] = useState(false);

  useEffect(() => {

    const handleImageLoad = () => {
      setImageLoaded(true);
    };

    const handleImageError = () => {
      setImageLoaded(false);
    };
    const imgElement = document.createElement("img");
    imgElement.onload = handleImageLoad;
    imgElement.onerror = handleImageError;
    imgElement.src = logourl;

    return () => {
      imgElement.onload = null;
      imgElement.onerror = null;
    };
  }, [logourl]);
  console.log(props);
  useEffect(() => {
    async function fetchData(id, _id) {
      if (localStorage.getItem("loggedUserInfo")) {
        const response = await getTransaction(id);
        if (!response) {

          return;
        }
        const record = await response.json();
        if (!record || record.length === 0) {

          navigate("/");
          return;
        }

        setInvoiceUrl(record.length > 0 ? record[0].url : "")
        setUserdata(record)
        console.log(record);
        setTData([record[0]]);
        if (record[0].payment.balance !== 0 || (record[0].paymenthistory && record[0].paymenthistory.length !== 1)) {
          setDueTrans(true);
        } else {
          setDueTrans(false);
        }
        setmem(record);
        const uniqueItemIds = new Set();
        const uniqueRecords = [];
        let updatedRecord = record.sort((a, b) => b.updatedAt - a.updatedAt);
        updatedRecord.forEach((reco) => {
          const { itemid } = reco;
          if (!uniqueItemIds.has(itemid)) {
            uniqueRecords.push(reco);
            uniqueItemIds.add(itemid);
          }
        });
        setGroupd(uniqueRecords);
        console.log(uniqueRecords);
        const res = await getGBussinessInfo(JSON.parse(localStorage.getItem("loggedUserInfo")).gymid);
        const rec = await res.json();
        setBusdata(rec);

        // const dueRes = await dueTransaction(id);
        // const duedata = await dueRes.json();
        // console.log(duedata);
        // setDue(duedata);
      }
    }
    fetchData(props.id, props.transid);
    if (props.Rtoken) {
      setRtoken(false);
    }
  }, [navigate, props, clearSess]);

  async function processandReturn(event) {
    event.preventDefault();
    const billdata = [];
    const price = tData && tData[0].payment.totalamount;
    const discount = tData && tData[0].payment.discountpercent ? tData[0].payment.discountpercent : 0;
    const transactiond = tData && tData[0].tId;
    console.log(groupd);
    groupd && groupd.forEach((records, itemid) => {
      const itm = records;
      const discountValue = mem.filter(itmVal => itmVal.itemid === itm.itemid).reduce((memberAcc, member) => memberAcc + (member.payment.discount ? member.payment.discount.amount : 0), 0);
      const taxInculded = (itm.plan.length > 0 && itm.plan[0].taxcheck) ? (itm.plan[0].taxcheck === true ? "Included" : "Excluded") : (itm.addons.length > 0 && itm.addons[0].taxcheck === true ? "Included" : "Excluded");
      billdata.push({
        sno: billdata.length + 1,
        name: itm.plan.length > 0 ? itm.plan[0].name : (itm.addons.length > 0 && itm.addons[0].name),
        qty: itm.quantity,
        price: (itm.plan.length > 0 ? itm.plan[0].price : (itm.addons.length > 0 ? itm.addons[0].props[0].propvalue : 0)) * itm.quantity,
        check: itm.plan.length > 0 ? itm.plan[0].taxcheck : (itm.addons.length > 0 ? itm.addons[0].taxcheck : false),
        tax: busData.ugsttax && busData.ugsttax.filter(obj => obj.check).map(obj => parseFloat(obj.label)),
        taxd: busData && busData.dgsttax[0] === 1 ? "18" : 0,
        discount: discountValue,
        overAllTax: itm.payment.taxamounts ? itm.payment.taxamounts : [{ TaxCheck: `${taxInculded}` }],
        cost: ((itm.plan.length > 0 && itm.plan[0].taxcheck) || (itm.addons.length > 0 && itm.addons[0].taxcheck)) ?
          parseFloat(((itm.plan.length > 0 ? itm.plan[0].price : (itm.addons.length > 0 ? itm.addons[0].props[0].propvalue : 0)) * itm.quantity) /
            (1 + ((itm.payment.taxamounts && itm.payment.taxamounts.length > 0) ? (itm.payment.taxamounts.reduce((acc, obj) => acc + (obj.percentage ? (parseFloat(obj.percentage) / 100) : 0), 0)) : 0))).toFixed(2) :
          ((itm.plan.length > 0 ? itm.plan[0].price :
            (itm.addons.length > 0 ? itm.addons[0].props[0].propvalue : 0)) * itm.quantity),

        discountper: mem.filter(itmVal => itmVal.itemid === itm.itemid).reduce((memberAcc, member) => memberAcc + (member.payment.discount ? (
          member.payment.discount.discountType === "percent" ? member.payment.discount.EnterValue : ((member.payment.discount.amount /
            ((itm.plan.length > 0 && itm.plan[0].taxcheck) || (itm.addons.length > 0 && itm.addons[0].taxcheck)) ?
            parseFloat(((itm.plan.length > 0 ? itm.plan[0].price : (itm.addons.length > 0 ? itm.addons[0].props[0].propvalue : 0))) /
              (1 + ((itm.payment.taxamounts && itm.payment.taxamounts.length > 0) ? (itm.payment.taxamounts.reduce((acc, obj) => acc + (obj.percentage ? (parseFloat(obj.percentage) / 100) : 0), 0)) : 0))).toFixed(2) :
            ((itm.plan.length > 0 ? itm.plan[0].price : (itm.addons.length > 0 ? itm.addons[0].props[0].propvalue : 0)))
          ) * 100)
        ) : 0), 0),

      });
    });
    console.log(billdata);
    let userdetails = {
      name: busData.firstName + " " + busData.lastName,
      number: busData.contactNumber,
      state: busData.state,
      business: busData.businessName,
      city: busData.city,
      address: busData.address,
      membername: tData[0].members.fname + ' ' + tData[0].members.lname,
      membernumber: tData[0].members.number,
      memberaddress: tData[0].members.address,
      membercity: tData[0].members.city,
      membercuntry: tData[0].members.country,
      invoice: parseFloat(busData._id) + format(new Date(), "MM"),
      gst: busData.dgsttax.length > 0 && busData.dgsttax[0] === 1 ? busData.gstnumber : "",
    }
    console.log(userdetails);
    let taxdetails = busData;
    console.log(busData);
    let date = format(new Date(tData[0].createdAt), "dd-MM-yyyy");
    console.log(date);
    let payObj = {
      dueAmount: tData[0].payment.balance,
      paidAmount: tData[0].payment.paid,
      totalPrice: tData[0].payment.totalamount,
    }
    console.log(billdata);

    const updates = {
      logourl: logourl,
      userdetails: userdetails,
      taxdetails: taxdetails,
      payObj: payObj,
      billdata: billdata,
      transactiond: transactiond,
      totalPrice: payObj.totalPrice,
      date: date
    }
    const htmlContent = await getInvoicehtmlcontent(updates, transactiond)
    if (htmlContent === "false") {
      setApiError(true)
      return
    }
    const record = await htmlContent.json();

    console.log(record, '9999999999999999999');

    // Open a new window.
    const print_area = window.open('', '_blank');
    print_area.document.write(record.htmlContent);
    print_area.document.close();
    print_area.focus();
    setTimeout(() => {

      print_area.print();
    }, 500);
  }
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  function handleTransdate(date) {
    console.log("in transdetails handleTransDate", date);
    setTransDate(date)
  }
  async function Refundsubmit(id) {
    if (refundreason && refundvalue) {
      await googleAnalytic("Transactiondetails", 'Transaction', 'Refund Button', 'Refund Transaction amount')
      // ReactGA.event({
      //   category: 'Transaction',
      //   action: 'Refund Button',
      //   label: 'Refund Transaction amount',
      // });
      let percentageamount = percentrefund !== 1 && (tData[0].payment.paid - (tData[0].refund && tData[0].refund.length > 0 ? tData[0].refund.reduce((acc, obj) => acc + obj.refundamount, 0) : 0) * refundvalue / 100);
      let taxamt1 = (busData && busData.dgsttax[0] === 1 ? (Math.round((refundvalue * 1) * 0.18, 2)) : 0)
        + (busData.ugsttax ? busData.ugsttax.reduce((acc, obj) => acc + Math.round((refundvalue * 1) * (parseFloat(obj.check ? obj.label : 0)) / 100), 0) : 0)
      let taxamt2 = (busData && busData.dgsttax[0] === 1 ? (Math.round((percentageamount * 1) * 0.18, 2)) : 0)
        + (busData.ugsttax ? busData.ugsttax.reduce((acc, obj) => acc + Math.round((percentageamount * 1) * (parseFloat(obj.check ? obj.label : 0)) / 100), 0) : 0)
      let refund = {
        taxamt: percentrefund !== 1 ? taxamt2 : taxamt1,
        resultamt: refundvalue,
        refundamount: percentrefund !== 2 ? parseFloat(refundvalue) : percentageamount,
        reason: refundreason,
        percentrefund: percentrefund,
      }
      let newid = Math.random().toString().substring(2, 8);
      let updates = {
        refund: refund,
        members: mem,
        id: id,
        newid: newid,
      }
      console.log(updates);
      func();
      let resp = await refundTransaction(updates, id);
      let value = resp.json();
      console.log(value);
    }
    else {
      seterrr("Fields cannot be empty");
    }
  }
  const func = () => {
    props.oncancel()
    window.location.reload(true);
  }
  function payNow() {
    let tId = tData[0] ? tData[0].tId : 0;
    let balanceamt = tData[0] ? tData[0].payment.balance : 0;
    let totalPrice = tData[0] ? tData[0].payment.totalamount : 0;
    let selItems = groupd.map((item) => {
      let membership = item.plan.length > 0 ? item.plan[0] : (item.addons.length > 0 ? item.addons[0] : [])
      const taxIncluded = membership.taxcheck === true ? "Included" : "Excluded"
      const taxamounts = item.payment.taxamounts ? item.payment.taxamounts : [{ TaxCheck: `${taxIncluded}` }];
      return {
        Item: membership,
        idate: item.startdate,
        isplan: 3,
        members: mem.filter((meme) => meme.itemid === item.itemid).map((obj) => obj.members).flat(),
        quantity: item.quantity,
        taxamounts: taxamounts,
        token: tData[0].token,
        _id: item.itemid,
        price: balanceamt,
        ref_tId: tId,
      };
    });
    localStorage.setItem("transactionId", tId);
    localStorage.setItem(
      "allData",
      JSON.stringify({
        price: balanceamt,
        isplan: 3,
        ref_tId: tId,
        token: tData[0].token,
        totalPrice: totalPrice,
      })
    );
    localStorage.setItem(
      "selItems",
      JSON.stringify(selItems)
    );
    setOpenPayment(true);
  }
  function onPayemntDone(event) {
    setOpenReceipt(true);
  }
  function oncancelpayment() {
    setOpenPayment(false);
    setOpenReceipt(false);
  }
  function gotohome() {
    cancelThis();
  }

  function cancelThis(event) {
    clearAll();
  }

  function clearAll() {
    window.location.reload(true);
    setOpenPayment(false);
    setOpenReceipt(false);
  }

  async function clearSession(e, id) {

    console.log(id);
    setClearSess(null)
    await clearsessions(id)
    // setClearSess(null)
  }


  // function showThisInf(e, data) {
  //   console.log(data);
  //   console.log("data ::::::::::::::::: onlcick");
  //   console.log(memberData);
  //   let selMember = memberData.filter(
  //     (tmdata) =>
  //       tmdata.fname + " " + tmdata.lname == data[1]
  //   )[0];
  //   console.log(selMember);
  //   selMember.anotherColumn = valuee && valuee;
  //   selMember.Date11 = Date11;
  //   selMember.Date22 = Date22;
  //   selMember.filters = {
  //     inputText: mfilter.inputText,
  //     planNme: mfilter.planNme,
  //     gender: mfilter.gender,
  //     Ttype: mfilter.Ttype,
  //     tidName: mfilter.tidName,
  //     status: mfilter.status,
  //     mType: mfilter.mType
  //   }
  //   console.log(selMember);
  //   navigate("/mdetails", {
  //     state: selMember,
  //   });
  // }




  function handleClik(data) {
    console.log("hibhvdgsxh", data);
    let selMember = data.members

    localStorage.setItem("transaction_filter1", JSON.stringify(props.filters))
    selMember.Date11 = null;
    selMember.Date22 = null;
    selMember.TType = 3;
    selMember.addon = [];
    selMember.address = data.members && data.members.address ? data.members.address : "";
    selMember.age = data.members.age || "";
    selMember.anotherColumn = null;
    selMember.bId = data.members.bId;
    selMember.bloodgroup = data.members.bloodgroup || "";
    selMember.city = data.members.city || "";
    selMember.country = data.members.country || "";
    selMember.createdAt = data.members.createdAt;
    selMember.email = data.members.email || "";
    selMember.expirydate = data.members.expirydate;
    selMember.fname = data.members && data.members.fname;
    selMember.height = data.members && data.members.height ? data.members.height : null;
    selMember.lname = data.members && data.members.lname;
    selMember.mid = data.members.mid;
    selMember.member = data.members.member || "";
    selMember.number = data.members.number;
    selMember.others = data.members.others || "";
    selMember.plan = data.plan || [];
    selMember.planid = data.members.planid;
    selMember.referral = data.members.referral;
    selMember.sex = data.members.sex;
    selMember.startdate = data.members.startdate;
    selMember.updatedAt = data.members.updatedAt;
    selMember.weight = data.members.weight || null;
    selMember.__v = 0;
    selMember._id = data.members._id;
    selMember.return = "transactions"

    console.log(selMember);
    navigate("/mdetails", {
      state: selMember,
    });
  }
  let tmrow = [];
  mem && mem.forEach((itm) => {
    let rarray = [];
    rarray.push(itm.members && (<div className="link-color" onClick={() => handleClik(itm)}>{itm.members.fname + " " + itm.members.lname}</div>));
    rarray.push(itm.plan.length > 0 ? itm.plan[0].name : (itm.addons.length > 0 ? itm.addons[0].name : ""));
    rarray.push(
      new Date(itm.expirydate).toLocaleString("en-IN", {
        timeZone: "Asia/Kolkata",
      })
    );
    rarray.push(<div className="d-flex align-items-center justify-content-between"><span>{itm.session.length > 0 && itm.session[0].balsessions}</span>{itm.session.length > 0 && itm.session[0].balsessions < 0 && <MDBBtn size="sm" className="ms-2" onClick={(e) => setClearSess(itm.session[0]._id)}>Clear</MDBBtn>}</div>)
    tmrow.push(rarray);
  });
  ///**Refund paymentt**//////////
  let tmrowr = [];
  tData && tData[0] && tData[0].refund && tData[0].refund.forEach((itm) => {
    let rarray = [];
    rarray.push(itm.token);
    rarray.push(itm.reason);
    rarray.push(
      new Date(itm.date).toLocaleString("en-IN", {
        timeZone: "Asia/Kolkata",
      })
    );
    rarray.push("₹ " + itm.refundamount);
    tmrowr.push(rarray);
  });
  let newRow = [' ', ' ', 'Total ', "₹ " + (tData && tData[0].refund ? tData[0].refund.reduce((acc, obj) => acc + obj.refundamount, 0) : 0)];
  tmrowr.push(newRow);
  ////**Due amountttt**//////////
  let tmrowD = [];
  const transData = tData && tData.length > 0 && tData[0].paymenthistory ? tData[0].paymenthistory : []
  // console.log(transData);
  if (transData && transData.length > 0) {
    transData.sort((a, b) => b.sno - a.sno);
    // console.log('Sorted transData by sno in descending order:', transData);
  }
  transData.length > 0 && transData.forEach((itm) => {
    let rarray = [];
    rarray.push(
      new Date(itm.date).toLocaleString("en-IN", {
        timeZone: "Asia/Kolkata",
      }));
    rarray.push("₹ " + itm.paid);
    tmrowD.push(rarray);
  });
  let newRow1 = ['Total Paid', "₹ " + (tData && tData[0].payment.paid)];
  tmrowD.push(newRow1);
  let newRow2 = ['Total Due', <div>₹ {tData && tData[0].payment.balance}
    {tData && tData[0].payment.balance > 0 && <label className="link-color ms-3" onClick={(e) => { tData && (tData[0].payment.balance > 0 && payNow()) }}>Pay Now</label>}</div>];
  tmrowD.push(newRow2);
  const basicData = {
    columns: [
      "Members",
      "Plan/Addon",
      "Expire Date",
      "Session Remaining"
    ],
    rows: tmrow,
  };
  const basicDataR = {
    columns: [
      "Token",
      "Reason",
      "Date",
      "Amount",
    ],
    rows: tmrowr,
  };
  const basicDataD = {
    columns: [
      "Date",
      "Amount",
    ],
    rows: tmrowD,
  };


  const handleCheckboxEmailChange = (email) => {
    setSelectedEmails((prevSelectedEmails) => {
      if (prevSelectedEmails.includes(email)) {
        return prevSelectedEmails.filter((selectedEmail) => selectedEmail !== email);
      } else {
        return [...prevSelectedEmails, email];
      }
    });
  };
  const handleCheckboxNumberChange = (number) => {
    setSelectedNumbers((prevSelectedNumbers) => {
      if (prevSelectedNumbers.includes(number)) {
        return prevSelectedNumbers.filter((selectedNumber) => selectedNumber !== number);
      } else {
        return [...prevSelectedNumbers, number];
      }
    });
  };


  const handlesendReceipt = (e) => {
    e.preventDefault();
    // const uniqueEmailAddresses = ["tvamshif@gmail.com", "vamshibuss99@gmail.com"];
    console.log(userdata);

    console.log((JSON.parse(localStorage.getItem("loggedUserInfo"))).email);
    const uniqueEmailsSet = new Set();
    const uniqueNumbersSet = new Set()
    userdata.forEach(item => {

      if (item.members && item.members.email) {
        uniqueEmailsSet.add(item.members.email);
      }
      if (item.member && item.members.number) {
        uniqueNumbersSet.add(item.members.number)
      }

    });
    const uniqueEmailAddresses = Array.from(uniqueEmailsSet);
    uniqueEmailAddresses.push(localStorage.getItem("loggedUserInfo") ? (JSON.parse(localStorage.getItem("loggedUserInfo"))).email : "")
    const uniqueNumbers = Array.from(uniqueNumbersSet);
    uniqueNumbers.push(localStorage.getItem("loggedUserInfo") ? (JSON.parse(localStorage.getItem("loggedUserInfo"))).number : "")
    // console.log(uniqueEmailAddresses);
    setMailData(uniqueEmailAddresses)
    setSelectedEmails(uniqueEmailAddresses)
    setNumberData(uniqueNumbers)
    setSelectedNumbers(uniqueNumbers)
    toggleOpen()
  }




  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhoneNumber = (mobileNumber) => {
    // const mobileRegex = /^\d{10}$/;
    const mobileRegex = /^(?:(?:\+|0{0,2})91(\s*|[-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/;    //this validation of moblile number is valid for the below case
    //6856438922
    //7856128945
    //8945562713
    //9998564723
    //+91-9883443344
    //09883443344
    //919883443344
    //0919883443344
    //+919883443344
    //+91-9883443344
    //0091-9883443344
    //+91 9883443344
    //+91-785-612-8945
    //+91 999 856 4723
    return mobileRegex.test(mobileNumber);
  };

  const sendReceipt = async (e) => {
    e.preventDefault();

    console.log(selectedEmails);
    console.log(selectedNumbers);
    console.log("OtherMails", OtherData);

    const uniqueEmailsSet = new Set([...selectedEmails]);
    const uniqueNumbersSet = new Set([...selectedNumbers]);

    const otherDataArray = OtherData.split(',').map(data => data.trim());
    let response = "";
    if (selectType === "email") {
      otherDataArray.forEach(email => uniqueEmailsSet.add(email));
      otherDataArray.forEach(email => {
        if (isValidEmail(email)) {
          uniqueEmailsSet.add(email);
        } else {
          console.log(`Invalid email format: ${email}`);
        }
      });
      uniqueEmailsSet.forEach(email => {
        if (!isValidEmail(email)) {
          uniqueEmailsSet.delete(email);
        }
      });
      setMailData(Array.from(uniqueEmailsSet));

      // const content = recinf && recinf.length > 0 ? (recinf.length > 0 && recinf[0].Content) : "<p>Hello there<strong>!</strong> Below is the receipt for your most recent transaction. Please click on the download receipt button for the receipt. Feel free to click on the \"Download Receipt\" button to obtain your receipt. Appreciate your business!</p>"
      // console.log(content);

      const logo = localStorage.getItem("loggedUserInfo") ? (JSON.parse(localStorage.getItem("loggedUserInfo")).gymlogoUrl ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymlogoUrl : "") : "";

      console.log(logo, invoiceUrl, '77777777777777777777777777');

      let saverep = await sendReceiptMail(localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "", Array.from(uniqueEmailsSet), invoiceUrl, (logo ? logo : "")
      );
      // console.log(saverep);

      const responseData = await saverep.json();
      response = responseData.sendEmail
      console.log(responseData);
      console.log("email sent successfully ");
    } else {
      const number = "6281619099"
      otherDataArray.forEach(number => uniqueNumbersSet.add(number));
      otherDataArray.forEach(number => {
        if (isValidPhoneNumber(number)) {
          uniqueNumbersSet.add(number);
        } else {
          console.log(`Invalid number format: ${number}`);
        }
      });
      uniqueNumbersSet.forEach(number => {
        if (!isValidPhoneNumber(number)) {
          uniqueNumbersSet.delete(number);
        }
      });
      setNumberData(Array.from(uniqueNumbersSet));
      const respon = await numbermessage({ phone: number, url: invoiceUrl });
      console.log(respon);
      let resData = await respon.json();
      console.log(resData);
      response = resData.message
      console.log("sms sent successfully ");
    }
    toggleOpen()

    if (response === "success") {
      setSMessage(true);
      setTimeout(() => {
        setSMessage(false);
      }, 5000);
    }
    else {
      setSMessage(false);
    }
  };

  console.log(transDate, 'transdate');
  return (
    <>
      <div>
        <div className="gym-container" id="showcase">
          {smessage && (
            <div>
              {/* <Toast message={smessage} /> */}

              <div id="snackbar-submit" className={`show-submit alert ${selectType === 'email' ? (mailData && mailData.length > 0 ? "alert-success" : "alert-danger") : (numberData && numberData.length > 0 ? "alert-success" : "alert-danger")} alert-fixed SentMailAllert w-30 mob-w-100`}>

                <div className="text-left">
                  {selectType === 'email' ? (mailData && mailData.length > 0 ? "Email sent successfully!!" : "Email not sent sentsuccessfully!! ") : (numberData && numberData.length > 0 ? "Sms sent successfully" : "Sms not sent successfully")}
                  <div>
                    {selectType === 'email' ? " Email(s):" : "Moblie Number(s):"}
                  </div>
                  <div>
                    {selectType === 'email' && (mailData && mailData.length > 0 ? (
                      <span>{mailData.join(', ')}</span>
                    ) : (
                      <span>No emails available</span>
                    ))}
                    {selectType === 'number' && (numberData && numberData.length > 0 ? (
                      <span>{numberData.join(', ')}</span>
                    ) : (
                      <span>No number available</span>
                    ))}

                  </div>
                </div>
              </div>
            </div>
          )}

        </div>
        <div>
          {tData.length > 0 &&
            tData.map((item, index) => (
              <>
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-1">Date</p>
                  <p className="mb-1 fw-600">{format(parseISO(item.createdAt), "d-M-yyyy")}</p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-1">Transactions ID</p>
                  <p className="mb-1 fw-600">{item.tId}</p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-1">Token Number</p>
                  <p className={!Rtoken ? "mb-1 link-color fw-600" : "mb-1 fw-600"} onClick={() => { setRtoken(true); }}>
                    {item.token}
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-1">Quantity</p>
                  <p className="mb-1 fw-600 d-flex align-items-center"><MDBIcon far icon="user" className="me-1  fs-10" />{mem.length} </p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-1">Total</p>
                  <p className="mb-1 fw-600">
                    <img src={tranImg} alt="totalamount" width="10" className="me-1" />
                    {item.payment.totalamount}
                  </p>

                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-1">Tax</p>
                  <p className="mb-1 fw-600">
                    <img src={tranImg} alt="amount" width="10" className="me-1" />
                    {groupd && groupd.length > 0 ? (groupd.reduce((acc, obj) =>
                      acc + (obj.payment.taxamounts ? (obj.payment.taxamounts.reduce((innerAcc, val) =>
                        innerAcc + (val.amount ? val.amount : 0), 0)) : 0), 0)) : 0
                    }
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-1">Paid</p>
                  <p className="mb-1 fw-600">
                    <img src={tranImg} alt="paid" width="10" className="me-1" />
                    {item.payment.paid}
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-1">Due  {" "}
                    <span>{item && (item.payment.balance > 0 &&
                      <label className="link-color" onClick={(e) => { item && (item.payment.balance > 0 && payNow()) }}
                      >Pay Now</label>)
                    }</span></p>
                  <p className="mb-1 fw-600" >
                    <img src={tranImg} alt="balance" width="10" className="me-1" />
                    {item.payment.balance}
                  </p>
                </div>
                <p className="mb-1">Member(s):</p>

                <MDBDatatable data={basicData} className="Transaction__table" />

                {DueTrans &&
                  <>
                    <p className="my-1">Paid Amount:</p>
                    <MDBDatatable data={basicDataD} className="Transaction__table" />
                  </>}
                {item.refund && item.refund.length > 0 &&
                  <>
                    <p className="my-1">Refund:</p>
                    <MDBDatatable data={basicDataR} className="Transaction__table" />
                  </>}
                {!refundContent && Rtoken && item.payment.paid > 0 && (item.payment.paid) > (item.refund && item.refund.length > 0 ? item.refund.reduce((acc, obj) => acc + obj.refundamount, 0) : 0) &&
                  <p className="link-color my-1" onClick={() => setRefundContent((val) => !val)}>Refund Amount</p>}
                {refundContent &&
                  <>
                    <div className="d-flex align-items-center justify-content-between mt-3 mb-1">
                      <p className="my-1">Refund Amount</p>
                      <p className="w-70 text-end mb-1">
                        <big className="d-flex text-end justify-content-end">
                          <select className="gym-input form-select form-control w-40 height_37" onChange={(e) => { setpercentrefund(e.target.value); setRefundvalue("") }} value={percentrefund}>
                            <option value={1}>Amount</option>
                            <option value={2}>Percentage</option>
                          </select>
                          {percentrefund !== 2 ?
                            <input type="number" pattern="[0-9]*\.?[0-9]*" className={(errr !== '' && refundvalue === '') ? "w-30 ms-2 form-control is-invalid" : "w-30 ms-2 form-control"} placeholder="0/-"
                              onInput={(e) => {
                                const inputValue = parseFloat(e.target.value);
                                const paidAmount = tData && tData[0].payment.paid - (item.refund && item.refund.length > 0 ? item.refund.reduce((acc, obj) => acc + obj.refundamount, 0) : 0);
                                if (inputValue >= paidAmount) {
                                  e.target.value = paidAmount;
                                  setRefundvalue(paidAmount);
                                }
                              }}
                              onChange={(e) => {
                                const inputValue = parseFloat(e.target.value);
                                const paidAmount = tData && tData[0].payment.paid;
                                if (inputValue < paidAmount) {
                                  setRefundvalue(e.target.value);
                                }
                              }}
                            /> :
                            <input type='number' pattern="[0-9]*\.?[0-9]*" className={(errr !== '' && refundvalue === '') ? "w-30 ms-2 form-control is-invalid" : "w-30 ms-2 form-control"} placeholder="0%"
                              onInput={(e) => { e.target.value = e.target.value.slice(0, 3) <= 100 ? e.target.value.slice(0, 3) : 100 }}
                              onChange={(e) => setRefundvalue(e.target.value)} value={refundvalue}
                            />}
                        </big></p>
                    </div>
                    <label>Refund Reason:</label>
                    <textarea type="text" rows={2} className={errr !== '' && refundreason === '' ? 'form-control is-invalid mb-1' : 'form-control'}
                      placeholder="Enter reason for refund" onChange={(e) => setRefundreason(e.target.value)}
                    ></textarea>

                    <div className="ms-1" style={{ color: "red" }}>{errr}</div>
                  </>}
                {refundContent && <p className="link-color my-1" onClick={() => { setRefundContent((val) => !val); seterrr("") }} style={{ cursor: "pointer" }}>Clear Refund</p>}
              </>
            ))}
        </div>
      </div>
      <div className="modal-footer w-100">

        <p className="fs-small w-100  text-left">Note: All transactions are secure and encrypted</p>
        <div className="w-100 d-flex justify-content-between">
          <div>

            <button type="button" className="btn btn-primary float-start me-2" member-mdb-dismiss="modal" onClick={(e) => processandReturn(e)}>
              Print Receipt
            </button>
            <button type="button" className="btn btn-primary float-start" member-mdb-dismiss="modal" onClick={(e) => handlesendReceipt(e)}>
              Send Receipt
            </button>
          </div>
          {refundContent && <button type="button" className="btn btn-primary float-end" data-mdb-dismiss="modal" onClick={(e) => Refundsubmit(tData[0].tId)}>
            Refund
          </button>}

          <button type="button" className="btn btn-secondary float-end" data-mdb-dismiss="modal" onClick={props.oncancel}>
            Close
          </button>

        </div>



        <MDBModal show={basicModal} setShow={setBasicModal} open={basicModal} setOpen={setBasicModal} tabIndex='-1'>
          <MDBModalDialog>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Send Reciept</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <div className="d-flex align-items-center mb-2">

                  <MDBRadio
                    name='inlineRadio'
                    id='inlineRadio1'
                    value='email'
                    label='Email'
                    inline
                    checked={selectType === 'email'}
                    onChange={() => {
                      setSelectType('email')
                      setOtherData("")
                    }}
                  />
                  {/* <MDBRadio
                    name='inlineRadio'
                    id='inlineRadio2'
                    value='number'
                    label='Phone Number'
                    inline
                    checked={selectType === 'number'}
                    onChange={() => {
                      setSelectType('number')
                      setOtherData("")
                    }}
                  /> */}
                </div>
                {selectType === 'email' && mailData && mailData.length > 0 && mailData.map((email, index) => (
                  <div key={index}>
                    {email && <MDBCheckbox
                      id={`flexCheckDefault${index}`}
                      label={email}
                      checked={selectedEmails.includes(email)}
                      onChange={() => handleCheckboxEmailChange(email)}
                    />}
                  </div>
                ))}
                {selectType === 'number' && numberData && numberData.length > 0 && numberData.map((number, index) => (
                  <div key={index}>
                    {number && <MDBCheckbox
                      id={`flexCheckDefault${index}`}
                      label={number}
                      checked={selectedNumbers.includes(number)}
                      onChange={() => handleCheckboxNumberChange(number)}
                    />}
                  </div>
                ))}
                <div className="mt-2">
                  <label>
                    Enter other {selectType === 'number' ? "number" : "email"}(s):
                  </label>
                  <MDBInput value={OtherData} onChange={(e) => setOtherData(e.target.value)} />
                  <p className="text-secondary m-0 mt-2">Note: To enter multiple {selectType === 'number' ? "number" : "email"}, use comma (,) separated values. </p>
                </div>
              </MDBModalBody>

              <MDBModalFooter className="pe-4 pb-3">
                <MDBBtn color='secondary' onClick={toggleOpen}>
                  Close
                </MDBBtn>
                <MDBBtn
                  disabled={mailData.length > 0 ? false : true}
                  onClick={(e) => {
                    sendReceipt(e)
                  }}>Send</MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

        {openPayment && (
          <Popup
            title="Payment Information"
            ptype="modal-lg"
            content={
              <>
                <div>
                  <Payments onsubmit={onPayemntDone} oncancel={oncancelpayment} handleTransdate={handleTransdate} />
                </div>
              </>
            }
            handleClose={oncancelpayment}
          />
        )}
        {openReceipt && (
          <Popup
            title="Print Receipt"
            ptype="modal-lg"
            content={
              <>
                <div>
                  <ReceiptPrint gotohome={gotohome} transactionDate={transDate} />
                </div>
              </>
            }
            handleClose={gotohome}
          />
        )}
        {clearSess && (
          <Popup
            title="Confirm Clear"
            content={
              <>
                <div className="pb-3">
                  <span className="fs-6 pb-3">Are you sure you want to clear this session?</span>
                </div>
                <MDBModalFooter>
                  <MDBBtn color='secondary' onClick={(e) => setClearSess(null)}>
                    Cancel
                  </MDBBtn>
                  <MDBBtn color="danger" onClick={(e) => clearSession(e, clearSess)}>Clear</MDBBtn>
                </MDBModalFooter>
              </>
            }
            handleClose={setClearSess}
          />
        )}
      </div>
    </>
  );
}