import React from "react";

function BillInvoice(props) {
    console.log(props);
    const totalAmountForTax = (percentage) => {
        return props.tabledata.reduce((total, item) => {
            if (item.overAllTax && Array.isArray(item.overAllTax)) {
                const matchingTax = item.overAllTax.find((tax) => tax.percentage === percentage);
                if (matchingTax) {
                    total += matchingTax.amount || 0;
                }
            }
            return total;
        }, 0);
    };
    let totaldiscount = 0;
    let discountper = 0;
    if (props.tabledata && props.tabledata.length > 0) {
        props.tabledata.forEach((item) => {
            if (item.discount) {
                totaldiscount += item.discount;
            }
            if (item.discountper) {
                discountper += item.discountper;
            }
        });
    }


    // const actualprice = props.tabledata.reduce((memberAcc, member) => memberAcc + Number(member.cost), 0).toFixed(2)

    // let totalDiscountPercentage = parseFloat(((totaldiscount / actualprice) * 100).toFixed(2));;
    let totalDiscountPercentage = discountper / props.tabledata.length;
    console.log(props);
    return (
        <>
            <div className="billContent">
                <div className="header">
                    <img className="logo" src={props.imageUrl ? props.imageUrl : "https://gymadmin.online/GymLogo.png"} alt="Logo" />
                    <div className="header-address">
                        <h6>{props.userdata.business}</h6>
                        {/* <p>{(props.userdata.address || props.userdata.city || props.userdata.state) ? ', ' : ''}</p> */}
                        <p>{props.userdata.address}{props.userdata.address && (props.userdata.city || props.userdata.state) ? ', ' : ''} </p>
                        <p>{props.userdata.city}{props.userdata.city && props.userdata.state ? ', ' : ''}{props.userdata.state}</p>
                    </div>
                </div>
                <hr className="my-0" />

                <div className="content">
                    <table className="details-table">
                        <tr>
                            <td><span className="primary-color">Billed to</span>
                                <h3 className="primary-color">
                                    <strong>{props.userdata.membername}{props.userdata.membernumber && props.userdata.membernumber ? ", " : ''} {props.userdata.membernumber}</strong>
                                </h3>

                                <p className="primary-color">{props.userdata.memberaddress}{props.userdata.memberaddress && (props.userdata.membercity || props.userdata.membercuntry) ? ', ' : ''} <br />
                                    {props.userdata.membercity}{props.userdata.membercity && props.userdata.membercuntry ? ', ' : ''} {props.userdata.membercuntry}</p>
                            </td>
                            <td>
                                <p className="primary-color mb0">Invoice Number: {props.userdata.invoice}</p>
                                <p className="primary-color mb0">Invoice Date: {props.date}</p>
                                <h6 style={{ margin: '5px 0px' }} className="primary-color" >
                                    <strong>Transaction ID : {props.tId}</strong>
                                </h6>
                            </td>
                        </tr>
                    </table>

                    <table className="w100 table" cellPadding="0" cellSpacing="0">
                        <tr className="lightbg">
                            <th align="left">
                                <h6 className="primary-color">
                                    <strong>S.no</strong>
                                </h6>
                            </th>
                            <th align="left">
                                <h6 className="primary-color">
                                    <strong>Item</strong>
                                </h6>
                            </th>
                            <th align="left">
                                <h6 className="primary-color">
                                    <strong>Quantity</strong>
                                </h6>
                            </th>
                            <th align="left">
                                <h6 className="primary-color">
                                    <strong>Total Price</strong>
                                </h6>
                            </th>
                            <th align="left">
                                <h6 className="primary-color">
                                    <strong> Excluding Tax</strong>
                                </h6>
                            </th>
                            {props.tabledata.length > 0 && props.tabledata[0].overAllTax.length > 0 && props.tabledata[0].overAllTax.map((item, index) => (
                                item.title && (
                                    <th key={index} align="left">
                                        <h6 className="primary-color">
                                            <strong>{item.title}</strong>
                                        </h6>
                                    </th>
                                )
                            ))}
                            <th align="left">
                                <h6 className="primary-color">
                                    <strong>Discount</strong>
                                </h6>
                            </th>
                            <th align="left">
                                <h6 className="primary-color">
                                    <strong>Total Amount</strong>
                                </h6>
                            </th>
                        </tr>
                        {props.tabledata.map((item) =>
                            <tr className="tablerow" key={item.id}>
                                <td align="left">
                                    <h6 className="primary-color">{item.sno}</h6>
                                </td>
                                <td align="left">
                                    <h6 className="primary-color">{item.name}
                                        {/* {" "} {item.check ? "(Include Tax)" : "(Exclude Tax)"} */}
                                    </h6>
                                </td>
                                <td align="left">
                                    <h6 className="primary-color">{item.qty} </h6>
                                </td>
                                <td align="left">
                                    <h6 className="primary-color"><img src="https://gymadmin.online/Rupee.png" alt="Rupee" width="8" class="me-1" />{item.price}</h6>
                                </td>
                                <td align="left">
                                    <h6 className="primary-color"><img src="https://gymadmin.online/Rupee.png" alt="Rupee" width="8" class="me-1" />{item.cost}</h6>
                                </td>
                                {item.overAllTax.map((item1, index) => (
                                    item1.percentage && (
                                        <td key={index} align="left">
                                            <h6 className="primary-color">{item1.percentage}</h6>
                                        </td>
                                    )
                                ))}
                                <td align="left">
                                    <h6 className="primary-color">{((item.discountper) / item.qty).toFixed(2)}%</h6>
                                </td>
                                <td align="right">
                                    <h6 className="primary-color"><img src="https://gymadmin.online/Rupee.png" alt="Rupee" width="8" class="me-1" />{item.cost}</h6>
                                </td>
                            </tr>
                        )}
                        <tr className="lightbg">
                            <th align="left" colspan={5 + (props.tabledata.length > 0 ? (props.tabledata[0].overAllTax).length : 0) - 1}></th>
                            <th align="left" >
                                <h6 className="primary-color">Total</h6>
                            </th>
                            <th align="right">
                                <h6 className="right primary-color">
                                    <img src="https://gymadmin.online/Rupee.png" alt="Rupee" width="8" class="me-1" />{props.tabledata.reduce((memberAcc, member) => memberAcc + Number(member.cost), 0).toFixed(2)}
                                </h6>
                            </th>
                        </tr>
                        <tr className="tablerow">
                            <td align="left" colspan={5 + (props.tabledata.length > 0 ? (props.tabledata[0].overAllTax).length : 0) - 1}></td>
                            <td align="left">
                                <h6 className="primary-color">Discount:{parseFloat((totalDiscountPercentage).toFixed(2))}%</h6>
                            </td>
                            <td align="right">
                                <h6 className="primary-color">
                                    <img src="https://gymadmin.online/Rupee.png" alt="Rupee" width="8" class="me-1" />{(totaldiscount).toFixed(2)}
                                </h6>
                            </td>
                        </tr>

                        {(props.tabledata.length > 0 && (props.tabledata[0].overAllTax).length - 1) > 0 ? (
                            <>
                                <tr className="lightbg">
                                    <th align="left" colspan={5 + (props.tabledata.length > 0 ? (props.tabledata[0].overAllTax).length : 0) - 1}></th>
                                    <th align="left" colspan="2">
                                        <h6 className="primary-color">Taxes</h6>
                                    </th>
                                </tr>
                                {props.tabledata[0].overAllTax.map((obj, index) =>
                                    obj.percentage &&
                                    <tr className="tablerow">
                                        <td align="left" colspan={5 + (props.tabledata.length > 0 ? (props.tabledata[0].overAllTax).length : 0) - 1}></td>
                                        <td align="left" >
                                            <h6 className="primary-color">{`${obj.title} : ${obj.percentage}`}</h6>
                                        </td>
                                        <td align="right">
                                            <h6 className="primary-color">
                                                <img src="https://gymadmin.online/Rupee.png" alt="Rupee" width="8" class="me-1" />{totalAmountForTax(obj.percentage)}
                                            </h6>
                                        </td>
                                    </tr>)}
                                <tr className="tablerow">
                                    <td align="left" colspan={5 + (props.tabledata.length > 0 ? (props.tabledata[0].overAllTax).length : 0) - 1}></td>
                                    <td align="left" >
                                        <h6 className="primary-color">Total TAX</h6>
                                    </td>
                                    <td align="right">
                                        <h6 className="primary-color">
                                            <img src="https://gymadmin.online/Rupee.png" alt="Rupee" width="8" class="me-1" />{parseFloat(
                                                props.tabledata.reduce((memberAcc, member) =>
                                                    memberAcc + (
                                                        member.overAllTax ?
                                                            member.overAllTax.reduce((Acc, obj) => Acc + (obj.amount || 0), 0) :
                                                            0
                                                    ), 0).toFixed(2)
                                            )}
                                        </h6>
                                    </td>
                                </tr>
                            </>) : null}
                        <tr className="w-100 total-section">
                            <td align="left" colspan={5 + (props.tabledata.length > 0 ? (props.tabledata[0].overAllTax).length : 0) - 1}></td>
                            <td align="left" >
                                <h6 className="primary-color">Grand Total</h6>
                            </td>
                            <td align="right">
                                <h6 className="primary-color"><img src="https://gymadmin.online/Rupee.png" alt="Rupee" width="8" class="me-1" />{props.price}</h6>
                            </td>
                        </tr>
                        <tr className="w-100 tablerow">
                            <td align="left" colspan={5 + (props.tabledata.length > 0 ? (props.tabledata[0].overAllTax).length : 0) - 1}></td>
                            <td align="left" >
                                <h6 className="primary-color">Paid Amount</h6>
                            </td>
                            <td align="right">
                                <h6 className="primary-color"><img src="https://gymadmin.online/Rupee.png" alt="Rupee" width="8" class="me-1" />{props.payObj.paidAmount}</h6>
                            </td>
                        </tr>
                        <tr className="w-100 tablerow">
                            <td align="left" colspan={5 + (props.tabledata.length > 0 ? (props.tabledata[0].overAllTax).length : 0) - 1}></td>
                            <td align="left" >
                                <h6 className="primary-color">Due Amount</h6>
                            </td>

                            <td align="right">
                                <h6 className="primary-color"><img src="https://gymadmin.online/Rupee.png" alt="Rupee" width="8" class="me-1" />{props.payObj.dueAmount}</h6>
                            </td>
                        </tr>
                    </table>

                    {props.userdata.gst && <h6 className="primary-color"><strong>GST Number:</strong> {props.userdata.gst}</h6>}
                </div >
            </div >
        </>
    )
}
export default BillInvoice;