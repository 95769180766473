import axios from "axios";
import React, { useState, useEffect } from 'react';
import { MDBAlert, MDBBtn, MDBCard, MDBCardBody } from 'mdb-react-ui-kit';
import logo from "./../components/images/GymLogo.png";
import { getPaymentUrl } from "../services/apiServices.js";

import ReactGA from 'react-ga';
import { useLocation } from 'react-router';

function RedirectScreen() {
    function decryptId(encryptedId) {
        return atob(encryptedId);
    }
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const encryptedId = params.get('id') ? params.get('id') : "";

    const [data, setData] = useState(null);

    const [logourl, setLogourl] = useState(logo)
    const [imageLoaded, setImageLoaded] = useState(true);

    useEffect(() => {
        const handleImageLoad = () => {
            setImageLoaded(true);
        };

        const handleImageError = () => {
            setImageLoaded(false);
        };
        const imgElement = document.createElement("img");
        imgElement.onload = handleImageLoad;
        imgElement.onerror = handleImageError;
        imgElement.src = logourl;

        return () => {
            imgElement.onload = null;
            imgElement.onerror = null;
        };
    }, [logourl]);

    async function fetchData() {
        const response = await getPaymentUrl(decryptId(encryptedId));
        const recinfo = await response.json();
        console.log(recinfo);
        setData(recinfo);
        if (recinfo.gymInfo && recinfo.gymInfo.logourl) {
            setLogourl(recinfo.gymInfo.logourl);
        }
    }
    useEffect(() => {
        setTimeout(() => {
            fetchData();
        }, 2000)
    }, []);

    useEffect(() => {
        if (data && data.status === 0) {
            setTimeout(() => {
                fetchData();
            }, 3000)
        }
    }, [data]);

    function downloadHandler(e) {
        e.preventDefault();
        setTimeout(() => {
            window.location.href = data.pdfurl;
        }, 3000)
    }

    return (
        <React.Fragment>
            <>
                {data && data.status !== 0 ?
                    <div className="h-100vhWave">
                        <MDBCard className='waveForm'>
                            <MDBCardBody className='py-3'>

                                <div className="mobile-Head">
                                    <div className='d-flex align-items-center Waiver-logo'>
                                        <div className='d-flex align-items-center '>
                                            <img src={imageLoaded ? logourl : logo} className="w-20 mob-w-40" />
                                            <div className='d-flex align-items-start flex-column mt-2 border-start ps-2 border-2'>
                                                <h5 className='logoColor'>{(data && data.gymInfo && data.gymInfo.businessName) ? data.gymInfo.businessName : "GYM Admin Online"}</h5>
                                                <div className='d-flex'>
                                                    <p className='m-0'>
                                                        {(data && data.gymInfo) ? (
                                                            (data.gymInfo.address ? data.gymInfo.address : '') +
                                                            ((data.gymInfo.address && data.gymInfo.city) ? ', ' : '') +
                                                            (data.gymInfo.city ? (data.gymInfo.city) : '')
                                                        ) : "Suadaraiah Vignana Kendram, Gachibowli, Hyderabad"}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="routing-border">

                                    </div>
                                </div>

                                {(data && data.status === 1) ?
                                    // success screen
                                    <div className="success-screen">
                                        <div class="success-animation">
                                            <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                                        </div>
                                        <div className="success-text text-center">
                                            <h5>Payment done successfully!!</h5>
                                        </div>
                                        <MDBBtn
                                            onClick={(e) => downloadHandler(e)}
                                        >Download Receipt
                                        </MDBBtn>
                                    </div>
                                    :
                                    // failed payment 

                                    <div className="failure-screen">
                                        <div className="failure-animation">
                                            <svg className="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                <circle className="crossmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                <path className="crossmark__cross" fill="none" d="M16 16l20 20M16 36l20-20" />
                                            </svg>
                                        </div>
                                        <div className="failure-text text-center mt-3">
                                            <h5>Payment failed.</h5>
                                        </div>
                                    </div>
                                }
                            </MDBCardBody>
                        </MDBCard>

                        <div className="text-center d-block text-secondary mt-2">Powered by Gym Admin Online</div>
                    </div>
                    :
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="d-flex align-items-center justify-content-center flex-column py-4">
                            <h5 >Please wait for confirming payment.</h5>
                            <div class="fs-6 pb-3">Waiting for payment call back</div>
                            <div class="paymentLoader PaymentSpinner"></div>
                            <p class="mt-4 text-secondary">Please do not refresh or press back.</p>
                        </div>
                    </div>
                }

            </>
        </React.Fragment >
    )
}
export default RedirectScreen
