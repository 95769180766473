import Header from "../components/Header";
import LeftPane from "../components/LeftPane";
import React, { useEffect, useRef, useState } from "react";
import ReactGA from 'react-ga';
import Loader from "../components/Loader";
import PageError from "../views/PageError";
import { MDBBtn, MDBDatatable, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBSelect } from "mdb-react-ui-kit";
import ReactQuill from "react-quill";
import { useLocation, useNavigate } from "react-router";
import { getGymAddonsList, getGymPlansList } from "../services/apiServices";
const CreateForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    console.log(location);

    const [loading, setLoading] = useState(false)
    const [apiError, setApiError] = useState(false);
    const handleReload = () => {
        window.location.reload();
    }
    useEffect(() => {
        fetchPAData();
        ReactGA.pageview(window.location.pathname);
    }, []);
    const [planData, setPlanData] = useState([
        // { text: 'Select123', value: "Select" },
    ]);
    const [addonData, setAddonData] = useState([]);
    function goBack(e) {
        e.preventDefault();
        navigate("/forms")
    }
    async function fetchPAData() {
        if (localStorage.getItem("loggedUserInfo")) {
            const response = await getGymPlansList(
                JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
            );
            if (!response) {
                return;
            }
            if (response === "false") {
                setApiError(true)
            } else {
                const allplan = await response.json();
                const respo = await getGymAddonsList(
                    JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
                );
                const allAddons = await respo.json()
            }
        }
    }
    return (
        <>
            <LeftPane isactive="14" />
            <Header />
            {!apiError && loading && (<h4><Loader /></h4>)}
            {apiError ? <PageError handleReload={handleReload} /> :

                <div className="gym-main gym-main-div rightContent">
                    <div className="d-flex align-items-center flex-wrap ">
                        <MDBIcon fas icon="arrow-left" className="fs-3" onClick={(e) => goBack(e)} />
                        <h4 className="m-0 ms-4 title">
                            Create Registration Form
                        </h4>
                    </div>

                    <div className="d-flex align-items-center">
                        <label className="fw-bold me-2" >Title: </label>
                        <input type="text" name="" id="" className="BusinessPage__contentNew w-100 "
                        // value={businessForm.title}
                        // onChange={(e) => updateForm({ title: e.target.value })}
                        />
                    </div>
                    <div className="d-flex flex-column Consentcheckbox mt-2">
                        <label className="fw-bold mt-3">Description</label>
                        <ReactQuill
                        // value={businessForm.agreement}
                        // onChange={(value) => updateForm({ agreement: value })}
                        />
                    </div>
                    <div className="gym-section w-300 w-100-mobile ms-2">
                        <label className="fw-bold">Select Plans</label>

                        <div>
                            <MDBSelect
                                clearButton
                                placeholder="Select Plans"
                                multiple search
                                data={planData}
                            // value={mfilter.planNme}
                            // onChange={(e) => {
                            //     const selectedValues = e.map(item => item.value);
                            //     // setSelectValue(selectedValues);
                            //     setMfilter({
                            //         ...mfilter,
                            //         planNme: selectedValues
                            //     })
                            // }}
                            />
                        </div>
                    </div>
                    <div className="gym-section w-300 w-100-mobile ms-2">
                        <label className="fw-bold">Select Addons</label>
                        <div>
                            <MDBSelect
                                clearButton
                                placeholder="Select Addons"
                                multiple search
                                data={addonData}
                            // value={mfilter.planNme}
                            // onChange={(e) => {
                            //     const selectedValues = e.map(item => item.value);
                            //     // setSelectValue(selectedValues);
                            //     setMfilter({
                            //         ...mfilter,
                            //         planNme: selectedValues
                            //     })
                            // }}
                            />
                        </div>
                    </div>

                </div>
            }
        </>
    );
};

export default CreateForm;
