import React from "react";
import csImg from "./../components/images/comingsoon.png";
import Header from "../components/Header";
import LeftPane from "../components/LeftPane";

import { useNavigate } from "react-router";
import { MDBAnimatedNavbar } from "mdb-react-ui-kit";

export default function ComingSoon(props) {

  const navigate = useNavigate();

  function gotohome() {
    navigate("/dashboard");
  }
  return (
    <>
      <LeftPane isactive="4" />
      <Header />
      <div className="gym-main gym-main-div rightContent">
        <div className="gym-container gym-cnr" id="showcase">
          <div className="d-flex justify-center ">
            <img className="w-600" src={csImg} alt="" />
          </div>
          <h5 className="font-bold text-center mt-10">Stay Tuned!!</h5>
          <div className="text-center ft-sz mt-10">
            We are in the final stages of developing the features and working
            tirelessly towards their completion. Stay informed and be the first
            to know.
          </div>
          <div className="d-flex justify-center mt-10">
            <button type="button" className="btn btn-primary w-110 ft-sz" onClick={() => gotohome()}>
              Home
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
