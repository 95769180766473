import React, { useEffect, useState } from "react";
import { MDBAlert, MDBCheckbox, MDBInputGroup, MDBTimepicker, MDBTooltip } from 'mdb-react-ui-kit';
import DatePicker from "react-datepicker";
import { browserName, browserVersion } from "react-device-detect";
import { checkMembers, getCheckStaff, getGBussinessInfo, getPlanProfile, logEvent, memberActiveTransactions, membersessions, resumeMemberShip } from "../services/apiServices.js";
import ReactGA from 'react-ga';
import { googleAnalytic } from "./googleAnalytics.js";
import PageError from "../views/PageError.js";

export default function LogATime(props) {
  const [trainer, setTrainer] = useState("");
  const [trainerid, settrainerid] = useState();
  const [Check, setCheck] = useState(false);
  const [userdata, setUserData] = useState("");
  const [auto, setAuto] = useState("")
  const [apiError, setApiError] = useState(false);
  const now = new Date();
  const [TrainerForce, setTrainerForce] = useState(false);
  const [basicDanger, setBasicDanger] = useState(false);
  const currentHour = now.getHours();
  let hourValue = currentHour > 12 ? currentHour - 12 : currentHour;
  const currentMinute = now.getMinutes();
  console.log(currentHour + "" + currentMinute);
  const ttimeh = (currentHour + 2) % 12 || 12;
  const ttimem = (currentMinute) % 60;
  const fday2 = (currentHour + 2) >= 12 ? 'PM' : 'AM';
  const [Errorr, setErrorr] = useState({
    trainer: "",
    fromTime: "",
    toTime: "",
  })
  const [actTransactions, setActTransactions] = useState([])
  const [selectedplan, setSelectedplan] = useState(null)
  const [sessionData, setSessionData] = useState([])
  const [sessionvalue, setSessionvalue] = useState(null)
  const [cpCheck, setCpCheck] = useState(false)
  const [form, setForm] = useState({
    date: new Date(),
    ftimeh: hourValue.toString().padStart(2, '0'),
    ftimem: currentMinute.toString().padStart(2, '0'),
    fday1: currentHour >= 12 ? 'PM' : 'AM',
    ttimeh: ttimeh.toString().padStart(2, '0'),
    ttimem: ttimem.toString().padStart(2, '0'),
    autocheck: 0,
    fday2: fday2,
  });

  function retrunFromThis() {
    props.oncancel();
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);


  function updateForm(value) {
    return setForm((prev) => {
      return { ...prev, ...value };
    });
  }

  async function fetchData() {
    if (localStorage.getItem("loggedUserInfo")) {
      const response = await getCheckStaff(
        JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
      );
      if (!response) {
        return;
      }
      if (response === "false") {
        setApiError(true)
        return;
      }
      const usersData = await response.json();
      setUserData(usersData);
      const resp = await membersessions(props.data._id);

      if (!resp) {
        return;
      }
      if (resp === "false") {
        setApiError(true)
        return;
      }
      const sessionData = await resp.json();
      console.log(sessionData, 'sessionData');
      setSessionData(sessionData)

      // const businessResponse = await getGBussinessInfo(
      //   JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
      // );
      // if (!resp) {
      //   return;
      // }
      // if (businessResponse === "false") {
      //   setApiError(true)
      //   return;
      // }
      // const recinfo = await businessResponse.json();
      // // setBusinessinfo(recinfo);
      // console.log(recinfo);

      const transactions = await memberActiveTransactions(props.data._id)
      if (!transactions) {
        return;
      }
      if (transactions === "false") {
        setApiError(true)
        return;
      }
      const trans = await transactions.json();

      const transdata = trans
      // trans.filter(item => item.isplan === "1" && item.plan.length > 0)
      //     const transactionsWithPause = transdata.filter(transaction => transaction.pause.length > 0);

      //  console.log(transactionsWithPause);
      //  const transactionsWithoutPause = transdata.filter(transaction => transaction.pause.length === 0|| transaction.pause.every(item => item.resume === false));
      //  console.log(transactionsWithoutPause);
      transdata.sort((a, b) => {
        if (a.isplan === "1" && b.isplan !== "1") {
          return -1;
        } else if (a.isplan !== "1" && b.isplan === "1") {
          return 1;
        } else {
          return 0;
        }
      });
      console.log(transdata, 'transdata');
      setActTransactions(transdata);
      if (transdata.length > 0 && transdata[0].isplan === "1" && transdata[0].plan[0].sessioncheck && sessionData !== null) {
        const session = sessionData.length > 0 && sessionData.filter(item => transdata[0].plan[0].sessioncheck
          ? transdata[0]._id === item.transid : [])
        console.log(session);
        setSessionvalue(session.length > 0 ? session : null)
      }

      // setSelectedplan(transdata[0])

      const plansRes = await getPlanProfile(props.data.planid);
      if (!plansRes) {
        return;
      }
      if (plansRes === "false") {
        setApiError(true)
        return;
      }
      const record = await plansRes.json();
      console.log(record);


      if (record) {
        if (record.forcetrainer) {
          setTrainerForce(true);
        }
      }
    }
  }

  useEffect(() => {
    fetchData();
    const now = new Date();
    const currentHour = now.getHours();
    let fday1 = "AM";
    fday1 = currentHour >= 12 ? "PM" : "AM";
    setForm((prevForm) => ({
      ...prevForm,
      fday1: fday1,
    }));

  }, []);

  const updateForcetrainer = async () => {
    const plansRes = await getPlanProfile(selectedplan.itemid);
    if (!plansRes) {
      return;
    }
    if (plansRes === "false") {
      setApiError(true)
      return;
    }
    const record = await plansRes.json();
    console.log(record);


    if (record) {
      if (record.forcetrainer) {
        setTrainerForce(true);
      }
    }
  }

  useEffect(() => {
    if (selectedplan !== null && selectedplan && selectedplan.isplan == "1") {
      updateForcetrainer()
    }

  }, [selectedplan])

  useEffect(() => {
    let chflg = props.value === "Check-Out" ? "3" : (props.value === "Check-In" ? "2" : "1");
    if (TrainerForce && trainer === "" && chflg !== "3") {
      setErrorr({
        ...Errorr,
        trainer: "Trainer is required",
      });

    } else {
      setErrorr({
        ...Errorr,
        trainer: "",
      })
    }

  }, [TrainerForce, trainer]);

  const filteredData = actTransactions && actTransactions.length > 0 && actTransactions.filter(item => item.isplan === "1");
  console.log(filteredData);
  const plantrans = filteredData && filteredData.length > 0 ? filteredData[0] : actTransactions[0]
  console.log(selectedplan !== null ? selectedplan : plantrans);

  async function onSubmit(e) {
    e.preventDefault();
    // let complimanritylimit = process.env.REACT_APP_
    // const plantrans=actTransactions.filter()
    console.log(plantrans);
    let selectedtrans = selectedplan !== null ? selectedplan : plantrans
    console.log(selectedtrans);
    let chflg = props.value === "Check-Out" ? "3" : (props.value === "Check-In" ? "2" : "1");
    setSelectedplan(selectedtrans)
    if (((props.value !== 'Check-Out') || (props.value === "Log Time"))) {
      await resumeSubmitFunction(selectedtrans)
    }

    let browserinfo = browserName + "-" + browserVersion + ".0.0";
    let ip = localStorage.getItem("ipaddress");
    let Eventtype = "check-In/check-Outs(s)";
    let gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
    let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : "";
    let flag = `${props.value === "Check-In" ? "Member Check-In Successfull"
      : (props.value === "Check-Out" ? "Member Check-Out Successfull"
        : "Member Log Time Successfull")} (${props.data.fname} ${props.data.lname})`; // By chinna
    let updates = {};

    let ftimeh = Number(form.ftimeh);
    let ttimeh = Number(form.ttimeh);

    if (form.fday1 === 'PM' && ftimeh < 12) {
      ftimeh = ftimeh === 12 ? ftimeh : ftimeh + 12
      setForm({
        ...form,
        ftimeh: ftimeh === 12 ? ftimeh : ftimeh + 12
      })
    } if (form.fday1 === 'AM') {
      ftimeh = ftimeh === 12 ? "00" : ftimeh
      setForm({
        ...form,
        ftimeh: ftimeh === 12 ? "00" : ftimeh
      })
    }
    if (form.fday2 === 'AM') {
      ttimeh = ttimeh === 12 ? "00" : ttimeh
    } if (form.fday2 === 'PM' && ttimeh < 12) {
      ttimeh = ttimeh === 12 ? ttimeh : ttimeh + 12
    }
    console.log("its Hour" + ftimeh);
    const date = `${getDateTime(form.date).date} ${ftimeh}:${form.ftimem}:00 GMT+0530 (India Standard Time)`;
    const tdate = `${getDateTime(form.date).date} ${ttimeh}:${form.ttimem}:00 GMT+0530 (India Standard Time)`;
    console.log("its date" + date);
    await googleAnalytic("logatime", 'logatime', `${props.value} Button`, `Member ${props.value}`)

    switch (chflg) {
      case "1":
        /*****
         * from time and to time both are set 
         *  then this case will execute and inserts both checkin time and checkout time
        */
        // if (DayValidation(date)) {
        updates = {
          checkFlag: "1",
          checktype: "Log Time",
          mid: props.data._id,
          _id: props.data._id,
          autocheckout: 0,
          // date: date,
          gymid: localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "",
        };

        // saverep = await checkMembers(updates, props.data._id);
        updates = {
          checkFlag: "0",
          checktype: "Log Time",
          mid: props.data._id,
          _id: props.data._id,
          date: date,
          fdate: date,
          tdate: tdate,
          gymid: localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "",
          selectedplan: selectedtrans,
          sessionvalue: sessionvalue,
          Complementary: cpCheck,


        };
        if (trainer !== "") {
          updates = { ...updates, trainer: trainer, trainerid: trainerid, };
        }
        // if (selectedplan !== null) {
        //   updates = { ...updates, selectedplan: selectedplan };
        // }
        if (sessionvalue && sessionvalue.length > 0 && cpCheck && sessionvalue[0].usedcomp >= 20) {
          setBasicDanger(true)
        } else {
          await checkMembers(updates, props.data._id);
          logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);
          window.location.href = "/checkin";
        }
        break;
      case "2":
        /*********** Only checkin *************/
        const hours = Math.floor(auto);
        const minutes = Math.round((auto % 1) * 60);

        let totalHours = Number(ftimeh) + hours;
        let totalMinutes = Number(form.ftimem) + minutes;

        // If minutes exceed 60, adjust hours and minutes accordingly
        if (totalMinutes >= 60) {
          totalHours += Math.floor(totalMinutes / 60);
          totalMinutes %= 60;
        }

        // If hours exceed 24, adjust the value
        let value2 = totalHours > 24 ? (totalHours - 24) : totalHours;
        // console.log(value2, totalMinutes);

        form.autocheck = getDateTime(form.date).date + " " + value2 + ":" + (totalMinutes < 10 ? '0' : '') + totalMinutes + ":00 GMT+0530 (India Standard Time)";
        updates = {
          checkFlag: "1",
          mid: props.data._id,
          _id: props.data._id,
          date: date,
          autocheckout: auto !== "" ? form.autocheck : 0,
          gymid: gymid,
          selectedplan: selectedtrans,
          sessionvalue: sessionvalue,
          Complementary: cpCheck,
        };
        if (trainer !== "") {
          updates = { ...updates, trainer: trainer, trainerid: trainerid, };
        }

        if (sessionvalue && sessionvalue.length > 0 && cpCheck && sessionvalue[0].usedcomp >= 20) {
          setBasicDanger(true)
        } else {
          await checkMembers(updates, props.data._id);
          logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);
          props.onsave(updates, props.data);
        }

        break;
      case "3":
        /***** only checkout*****/
        updates = {
          checkFlag: "0",
          mid: props.data._id,
          _id: props.data._id,
          date: date,
          gymid: gymid,
        };
        await checkMembers(updates, props.data._id);
        logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);
        props.onsave(updates, props.data);
        break;
      default:
        // Default case if none of the above cases match
        console.error("Invalid checkFlag value:", chflg);
        break;
    }
    // console.log(updates);
  }


  let pauseData = [];
  if (selectedplan && selectedplan.pause && selectedplan.pause.length > 0) {
    pauseData = [selectedplan.pause.sort((a, b) => b.sno - a.sno)[0]];
  } else if (actTransactions && actTransactions.length > 0 && actTransactions[0].pause && actTransactions[0].pause.length > 0) {
    pauseData = [actTransactions[0].pause.sort((a, b) => b.sno - a.sno)[0]];
  }

  let id = JSON.parse(localStorage.getItem("loggedUserInfo")) ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
  let ip = localStorage.getItem("ipaddress");
  let browserinfo = browserName + "-" + browserVersion + ".0.0";
  let Eventtype = "Membership";
  let gymid = id;
  let username1 = JSON.parse(localStorage.getItem("loggedUserInfo")) ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : "";

  async function resumeSubmitFunction(selectedtrans) {
    if (pauseData.length > 0) {
      let transaction = selectedtrans;
      let pausedDate = new Date(pauseData[0].fromtime);
      let currentTime = new Date();
      currentTime.setHours(pausedDate.getHours());
      currentTime.setMinutes(pausedDate.getMinutes());
      currentTime.setSeconds(pausedDate.getSeconds());
      currentTime.setMilliseconds(pausedDate.getMilliseconds());

      let OldPausedDate = new Date(pauseData[0].totime);
      const dayDifference = (OldPausedDate - currentTime) / (1000 * 60 * 60 * 24);

      let enddate = new Date(transaction.expirydate);
      enddate.setDate(enddate.getDate() - Math.ceil(dayDifference));

      let updates = {
        date: pauseData[0].date,
        fromtime: pauseData[0].date,
        totime: currentTime,
        resume: false,
        sno: pauseData[0].sno,
        enddate: enddate,
        transaction: transaction,
        gymid: selectedtrans.gymid,
      };
      await resumeMemberShip(selectedtrans.gymid, updates);
      let flag = `Plan Resume Successfull (${props.data.fname} ${props.data.lname})`;
      await logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);
    }
  }


  function getDateTime(tdbtime) {
    var now = new Date(tdbtime);
    var year = now.getFullYear();
    var month = now.getMonth() + 1;
    var day = now.getDate();
    var hour = now.getHours();
    var minute = now.getMinutes();

    month = month.toString().padStart(2, "0");
    day = day.toString().padStart(2, "0");
    hour = hour.toString().padStart(2, "0");
    minute = minute.toString().padStart(2, "0");

    var dateTime = {
      date: year + "-" + month + "-" + day,
      time: hour + ":" + minute,
    };
    // console.log(dateTime);
    return dateTime;
  }

  const handleTimeChange = (selectedTime) => {
    const timeFormatRegex = /^(0?[1-9]|1[0-2]):[0-5][0-9]\s(AM|PM)$/;
    if (typeof selectedTime === 'string' && timeFormatRegex.test(selectedTime)) {
      setErrorr({
        ...Errorr,
        fromTime: "",
      });
      const [time, ampm] = selectedTime.split(" ");
      const [hours, minutes] = time.split(":");
      const updatedForm = {
        ...form,
        ftimeh: hours,
        ftimem: minutes,
        fday1: ampm,
      };
      // let hours22 = updatedForm.ftimeh;
      // console.log(hours, typeof hours, '9999999999999999000000000');
      // if (updatedForm.fday1 === 'PM') {
      //   hours22 = String(updatedForm.ftimeh = Number(updatedForm.ftimeh === "12" ? 0 : updatedForm.ftimeh) + 12)
      // } if (updatedForm.fday1 === 'AM') {
      //   hours22 = updatedForm.ftimeh === "12" ? "00" : updatedForm.ftimeh
      // }

      setForm(updatedForm);
    } else {
      setErrorr({
        ...Errorr,
        fromTime: "Please select a valid time.",
      });
    }
  };

  const handleTimeChange1 = (selectedTime) => {
    const timeFormatRegex = /^(0?[1-9]|1[0-2]):[0-5][0-9]\s(AM|PM)$/;

    if (typeof selectedTime === 'string' && timeFormatRegex.test(selectedTime)) {
      setErrorr({
        ...Errorr,
        toTime: "",
      });
      const [time, ampm] = selectedTime.split(" ");
      const [hours, minutes] = time.split(":");
      const updatedForm = {
        ...form,
        ttimeh: hours,
        ttimem: minutes,
        fday2: ampm,
      };
      // let hours22 = updatedForm.ttimeh;
      // if (updatedForm.fday2 === 'PM') {
      //   hours22 = String(updatedForm.ttimeh = Number(updatedForm.ttimeh === "12" ? 0 : updatedForm.ttimeh) + 12)
      // } if (updatedForm.fday2 === 'AM') {
      //   hours22 = updatedForm.ttimeh === "12" ? "00" : updatedForm.ttimeh
      // }

      setForm(updatedForm);
    } else {
      setErrorr({
        ...Errorr,
        toTime: "Please select a valid time.",
      });
    }
  };

  function onChangeTrainerFunction(event) {
    const selectedIndex = event.target.selectedIndex;
    const selectedUser = userdata[selectedIndex - 1];
    if (selectedUser) {
      setTrainer(selectedUser.firstname + ' ' + selectedUser.lastname);
      settrainerid(selectedUser._id);
    } else {
      setTrainer('');
      settrainerid();
    }
  }


  const HandlerDateChange = (selectedTime) => {
    if (selectedTime instanceof Date && !isNaN(selectedTime)) {
      updateForm({ date: selectedTime });
    } else {
      updateForm({ date: form.date });
    }
  };

  const CalendarInput = ({ value, onClick }) => (
    <input
      type="text"
      value={value}
      readOnly={true}
      className="w-100 form-control"
      onClick={onClick}
    />
  );

  const HandlerplanChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const selectedUser = actTransactions[selectedIndex];

    if (selectedUser.isplan === "1" && selectedUser.plan[0].sessioncheck) {
      const session = sessionData.length > 0 && sessionData.filter(item => selectedUser.plan[0]._id === item.planid && selectedUser._id === item.transid)
      setSessionvalue(session.length > 0 ? session.length > 1 ? null : session : null)
    } else {
      setSessionvalue(null)
    }
    setSelectedplan(selectedUser)
  }

  let PauseLastDate = (pauseData && pauseData.length > 0 && pauseData[0].totime) ? new Date(pauseData[0].totime) : new Date();
  let PauseLastDateString = `${PauseLastDate.getDate().toString().padStart(2, '0')}-${(PauseLastDate.getMonth() + 1).toString().padStart(2, '0')}-${PauseLastDate.getFullYear()}`

  const handleReload = () => {
    window.location.reload();
  }

  console.log(props);

  const isFormValid = () => {
    return Object.values(Errorr).every((value) => value === "");
  };
  console.log(Errorr);

  return (
    <>
      {apiError ? <PageError handleReload={handleReload} /> : <div className="w-100">

        <div className="">
          <MDBTooltip tag='div' wrapperProps={{ className: "view-name m-0 checkIn-Head" }}
            title={props.data.fname
              ? props.data.fname + " " + props.data.lname
              : props.data.firstname + " " + props.data.lastname} >
            {props.data.fname
              ? props.data.fname + " " + props.data.lname
              : props.data.firstname + " " + props.data.lastname}
          </MDBTooltip>
          <p className="text-dark m-0">
            {props.data.sex ? props.data.sex : ""}{((props.data.age || props.data.number) && props.data.sex) ? ', ' : ''}
            {props.data.age ? props.data.age + "y" : ""}{(!props.data.number || !props.data.age) ? '' : ', '}
            {/* {props.data.weight ? props.data.weight + "kgs" : ""}{(!props.data.number) ? ' ' : ', '} */}
            {props.data.number ? props.data.number + " " : ""}
          </p>
        </div>

        {sessionvalue && sessionvalue.length > 0 && sessionvalue[0].balsessions <= 0 ?
          <span >
            Plan Expired: <span className={"text-danger mt-3"}>Out of Session(s)</span></span> : <>
            {pauseData.length > 0 && pauseData[0].resume ? <div className="pb-3">
              <span className={"text-danger mt-3"}>
                {`Plan Paused Until ${PauseLastDateString}`}</span>
            </div> :
              <div className="">
                <span className={props.expires && (props.expires.includes('Expired')) ? "text-danger mt-3" : "text-dark mt-3"}>
                  {props.expires.includes('No Plan') ? "" : "Plan"} {props.expires}</span>
              </div>}</>}
        <form
          action="#"
          target="_blank"
          onSubmit={onSubmit}
        >
          {props.value !== 'Check-Out' && actTransactions && actTransactions.length > 0 && <div className="d-flex align-items-baseline ">
            <span className="w-30">Plan/Addon: </span>
            <div className="w-50">
              <div className="d-flex justify-content-end flex-column">

                <select
                  className="w-100 form-control form-select"
                  onChange={(e) => HandlerplanChange(e)}
                >

                  {actTransactions && actTransactions.map((data, index) => (
                    <option key={index} value={data}>
                      {data.plan && data.plan.length > 0 ?
                        data.plan[0].name !== "" && data.plan[0].name + (data.pause && data.pause.length > 0 ? (data.pause.every(item => item.resume === false) ? " (Plan)" : " (Plan-paused)") : " (Plan)") : (data.addons && data.addons.length > 0 ? data.addons[0].name + (data.pause && data.pause.length > 0 ? (data.pause.every(item => item.resume === false) ? " (Addon)" : " (Addon-paused)") : " (Addon)") : "")

                      }
                    </option>
                  ))}
                </select>
                {sessionvalue && sessionvalue.length > 0 && <div className=""><span className="text-dark">Session(s) Remaining: {sessionvalue[0].balsessions === 0 ? <span className="text-danger">0</span> : <span>{sessionvalue[0].balsessions}</span>}</span></div>}

              </div>
            </div>
          </div>}

          {/* {Errorr == 7 ? <label className="text-danger check-errorTxt valitTimeCheckin"> Please select a Plan/Addon.</label> : ""} */}

          <div className="d-flex align-items-baseline mt-3">
            <span className="w-30">Select Date: </span>
            <div className="w-50 d-flex justify-content-end flex-column">
              <DatePicker
                selected={form.date}
                dateFormat="MM-dd-yyyy"
                onChange={(date) => HandlerDateChange(date)}
                isClearable={false}
                customInput={<CalendarInput />}
                maxDate={new Date()}
              />
              {/* {form.date == null ? <label className="text-danger check-errorTxt"> Please select a date.</label> : ""} */}
              {/* {form.date != null && Errorr == 1 ? <label className="text-danger check-errorTxt"> The selected date should be within the business working days.</label> : ""} */}
              {/* {Errorr == 3 ? <label className="text-danger check-errorTxt"> The date selected must be from present or past dates.</label> : ""} */}
            </div>
          </div>

          <div className="d-flex align-items-baseline mt-3">
            <span className="w-30">Trainer: </span>
            <div className="w-50">
              <div className="d-flex justify-content-end">
                {props.value !== 'Check-Out' ?
                  <select
                    className="w-100 form-control form-select"
                    onChange={(e) => onChangeTrainerFunction(e,)}
                  >
                    <option value=''>Select None</option>
                    {userdata && userdata.map((data, index) => (
                      <option key={index} value={data}>
                        {(data.firstname !== "" || data.lastname !== "") ? data.firstname + ' ' + data.lastname : data.email}
                      </option>
                    ))}
                  </select>
                  :
                  <label className="me-3">
                    {props.data.attendance.length > 0 && props.data.attendance[0].trainer ? props.data.attendance[0].trainer : 'None Selected'}
                  </label>
                }
              </div>
              {/* {Errorr === 6 ? <label className="text-danger check-errorTxt">Please select a trainer.</label> : ""}
               */}
              {Errorr.trainer !== "" ? <label className="text-danger check-errorTxt"> {Errorr.trainer}</label> : ""}
            </div>
          </div>

          {(props.value === "Check-In" || props.value === "Check-Out") &&
            <div>
              <div className="d-flex align-items-baseline mt-3">
                <span className="w-30">{props.value}: </span>
                <div className="w-70  d-flex flex-column">
                  <div className="d-flex align-items-center">
                    <div className="w-50  d-flex"><MDBTimepicker inline className="h-auto" defaultValue={`${form.ftimeh}:${form.ftimem} ${form.fday1}`}
                      onChange={(time) => handleTimeChange(time)}
                    // justInput
                    />
                    </div>
                    <div className="ms-2">(Ex:- 09:30 AM)</div>
                  </div>
                  {/* {Errorr == 2 ? <label className="text-danger check-errorTxt"> The selected time should be within the business working hours.</label> : ""} */}

                  {Errorr.fromTime !== "" ? <label className="text-danger check-errorTxt"> {Errorr.fromTime}</label> : ""}
                </div>
              </div>
              {props.value !== '' &&
                <div className="d-flex align-items-center mt-2 autoChehckout">
                  {props.value === "Check-In" && <MDBCheckbox name='inlineCheck' className="p-0" id='inlineCheckbox1' label='Auto Check-Out' inline onClick={() => setCheck((obj) => !obj)} />}
                  {props.value === "Check-Out" && props.data.attendance[0] && props.data.attendance[0].autocheckout !== 0 && <label>Auto Check-Out:</label>}
                  {/* {props.value == "Check-Out" && props.data.checkinInfo[0] && props.data.checkinInfo[0].autocheckout != 0 && ":"} */}
                  {props.value === "Check-Out" && props.data.attendance[0] && props.data.attendance[0].autocheckout !== 0 &&
                    <div className="w-50  d-flex justify-content-end"><label>{
                      new Date(props.data.attendance[0].autocheckout)
                        .toLocaleTimeString("en-IN", {
                          timeZone: "Asia/Kolkata",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        })
                    }</label></div>}
                  {Check && <div className="w-55 pe-3  d-flex justify-content-end" >
                    <MDBInputGroup className='mb-3' textAfter='Hours' style={{ marginLeft: "9px" }}>
                      <input className='form-control' type='number' placeholder="Ex:1, 1.5"
                        onChange={(e) => { if (e.target.value >= 12) { setAuto(12) } else { setAuto(e.target.value) } }}
                        value={auto}
                        autoFocus
                      />
                    </MDBInputGroup>
                  </div>}
                </div>}
            </div>}
          {props.value !== "Check-Out" && sessionvalue !== null && <MDBCheckbox name='inlineCheck2' className="p-0" id='inlineCheckbox2' checked={cpCheck} label='Complementary' inline onChange={() => setCpCheck(!cpCheck)} />}

          {(props.value === "Log Time") &&
            <div className="d-flex align-items-baseline mt-3">
              <span className="w-30"> {!props.value || props.value === "Log Time" ? "Log Time" : "Log Time"}</span>
              <div className="w-70 d-flex justify-content-end flex-column textTimeLabel">
                <MDBInputGroup className='mb-3 d-flex align-items-center' noBorder textBefore='From'>
                  <MDBTimepicker inline defaultValue={`${form.ftimeh}:${form.ftimem} ${form.fday1}`}
                    onChange={(time) => handleTimeChange(time)} className="w-150px"
                  />
                  <div className="ms-2">(Ex:- 09:30 AM)</div>
                </MDBInputGroup>
                {/* {Errorr == 2 ? <label className="text-danger check-errorTxt">The selected time should be within the Gym hours.</label> : ""} */}
                {/* {Errorr == 5 ? <label className="text-danger check-errorTxt valitTimeCheckin"> Please select a valid time.</label> : ""} */}
                {Errorr.fromTime !== "" ? <label className="text-danger check-errorTxt valitTimeCheckin"> {Errorr.fromTime}</label> : ""}
                <MDBInputGroup className='mb-3 ps-0 d-flex align-items-center' noBorder textBefore='To'>
                  <MDBTimepicker inline defaultValue={`${form.ttimeh}:${form.ttimem} ${form.fday2}`}
                    onChange={(time) => handleTimeChange1(time)} className="w-150px"
                  />
                  <div className="ms-2">(Ex:- 09:30 AM)</div>
                </MDBInputGroup>
                {Errorr.toTime !== "" ? <label className="text-danger check-errorTxt valitTimeCheckin"> {Errorr.toTime}</label> : ""}
              </div>
            </div>
          }
          <MDBAlert
            color='danger'
            autohide
            position='top-right'
            delay={2000}
            appendToBody
            open={basicDanger}
            onClose={() => setBasicDanger(false)}
          >
            You have exceeded the allowed maximum entry of the Complimentary
          </MDBAlert>
          <div className="modal-footer justify-content-start">
            <button
              type="submit"
              className="btn btn-primary"
              data-mdb-dismiss="modal"
              // disabled={Errorr == 0 ? false : true}
              disabled={!isFormValid()}
            >
              {props.value ? props.value : "Log Time"}
            </button>
            &nbsp;
            <button
              to="#"
              onClick={() => retrunFromThis()}
              className="btn btn-secondary"
              data-mdb-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>}
    </>
  );
}