import { MDBDatatable, MDBIcon } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from "react";
import { browserName, browserVersion } from "react-device-detect";
import { useNavigate } from "react-router";
import Header from "../components/Header";
import LeftPane from "../components/LeftPane";
import {
  deleteAddon,
  deletePlan,
  deleteSlot,
  getDiscount,
  getGymAddonsList,
  getPlans,
  getSlots,
} from "../services/apiServices";
import { deleteDiscount, logEvent } from "../services/apiServices.js";

import {
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane
} from 'mdb-react-ui-kit';

import ReactGA from 'react-ga';
import Popup from "../components/Popup";
import Toast from "../components/Toast";
import CreateAddon from "./CreateAddon";
import CreateDiscount from "./CreateDiscount";
import CreateSlot from "./CreateSlot";
import PlansHeader from "./PlansHeader";
import CreatePlan from "./createPlan";
import PageError from './PageError';
import { googleAnalytic } from '../components/googleAnalytics';


const PlansAndAddons = () => {
  const [discount, setDiscount] = useState([])
  const [plans, setPlans] = useState([]);
  const [editId, setEditId] = useState(null);
  const [planupdate, setPlanupdate] = useState(false);
  const [slotupdate, setSlotupdate] = useState(false);
  const [addonupdate, setAddonupdate] = useState(false);
  const [createDiscount, setcreateDiscount] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [smessage, setSMessage] = useState(null);
  const [addons, setAddons] = useState([]);
  const [slots, setSlots] = useState([]);
  const navigate = useNavigate();

  async function fetchDData() {

    const response = await getDiscount(
      localStorage.getItem("loggedUserInfo") && JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
    );

    if (response === "false") {
      setApiError(true)
    } else {
      const discount = await response.json();
      // console.log(discount);
      setDiscount(discount);
    }
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  async function deleteThisPlan(e, obj) {
    await googleAnalytic("PlansAndAddons", 'plan', 'Delete Button', 'plan Deleted')
    // ReactGA.event({
    //   category: 'plan',
    //   action: 'Delete Button',
    //   label: 'delete plan',
    // });
    e.preventDefault();
    let ip = localStorage.getItem("ipaddress");
    let browserinfo = browserName + "-" + browserVersion + ".0.0";
    let flag = `Plan Deleted Successfull (${obj.name})`;
    let Eventtype = "plan";
    if (window.confirm("Are you sure want to delete this Plan?")) {
      await deletePlan(obj._id);
      localStorage.setItem("setting_save_plan", "Plan deleted successfully");
      let gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
      let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : "";
      logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "");
      //window.location.reload(false);
      dUIPlan(obj);
    }
  }
  async function deleteThisAddon(e, obj) {
    await googleAnalytic("PlansAndAddons", 'Addons', 'Delete Button', 'Addon Deleted')
    // ReactGA.event({
    //   category: 'Addons',
    //   action: 'Delete Button',
    //   label: 'delete Addon',
    // });
    e.preventDefault();
    if (window.confirm("Are you sure want to delete this Addon?")) {
      await deleteAddon(obj._id);
      localStorage.setItem("setting_save_addon", "Addon deleted successfully");
      let ip = localStorage.getItem("ipaddress");
      let browserinfo = browserName + "-" + browserVersion + ".0.0";
      let flag = `Addon Deleted Successfull (${obj.name})`;
      let Eventtype = "addon";
      let gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
      let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : "";
      logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "");
      //window.location.reload(false));

      dUIAddon(obj);
    }
  }
  async function deleteThisSlot(e, obj) {
    await googleAnalytic("PlansAndAddons", 'Slot', 'Delete Button', 'Slot Deleted')
    // ReactGA.event({
    //   category: 'Slot',
    //   action: 'Delete Button',
    //   label: 'delete Slot',
    // });
    e.preventDefault();
    if (window.confirm("Are you sure want to delete this Slot?")) {
      await deleteSlot(obj._id);
      localStorage.setItem("setting_save_slot", "Slot deleted successfully");
      let ip = localStorage.getItem("ipaddress");
      let browserinfo = browserName + "-" + browserVersion + ".0.0";
      let flag = `Slot Deleted Successfull (${obj.name})`;
      let Eventtype = "slot";
      let gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
      let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : "";
      logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "");
      dUISlot(obj);
    }
  }
  async function deleteThisDiscount(e, obj) {
    await googleAnalytic("PlansAndAddons", 'Discount', 'Delete Button', 'Discount Deleted')
    // ReactGA.event({
    //   category: 'Discount',
    //   action: 'Delete Button',
    //   label: 'delete Discount',
    // });
    e.preventDefault();
    if (window.confirm("Are you sure want to delete this Discount?")) {
      await deleteDiscount(obj._id);
      localStorage.setItem("setting_save_discount", "discount deleted successfully");
      let ip = localStorage.getItem("ipaddress");
      let browserinfo = browserName + "-" + browserVersion + ".0.0";
      let flag = `Discount Deleted Successfull (${obj.name})`;
      let Eventtype = "discount";
      let gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : '';
      let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : "";
      logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "");
      dUIDiscount(obj);
    }
  }

  function openEditForm(e, obj) {
    // e.preventDefault();
    setEditId(obj.id);
    setPlanupdate(true);
  }
  function openSlotForm(e, obj) {
    //e.preventDefault();
    setEditId(obj.id);
    setSlotupdate(true);
  }
  function openAddonForm(e, obj) {
    //e.preventDefault();
    setEditId(obj.id);
    setAddonupdate(true);
  }

  function openDiscountForm(e, obj) {
    //e.preventDefault();
    setEditId(obj);
    setcreateDiscount(true);
  }
  function closeUpdate() {
    setEditId(null);
    setPlanupdate(false);
    setAddonupdate(false);
    setSlotupdate(false);
    setcreateDiscount(false);
  }
  function dUIPlan(item) {
    setSMessage(localStorage.getItem("setting_save_plan"));
    setTimeout(() => {
      setSMessage(null);
      localStorage.removeItem("setting_save_plan");
    }, 5000);
    console.log(item);
    if (plans.filter((obj) => obj._id === item._id).length > 0) {
      let remaining = plans.filter((obj) => obj._id !== item._id);
      setPlans([...remaining]);
    }

    closeUpdate();
  }
  function dUIAddon(item) {
    setSMessage(localStorage.getItem("setting_save_addon"));
    setTimeout(() => {
      setSMessage(null);
      localStorage.removeItem("setting_save_addon");
    }, 5000);
    console.log(item);
    if (addons.filter((obj) => obj._id === item._id).length > 0) {
      let remaining = addons.filter((obj) => obj._id !== item._id);
      setAddons([...remaining]);
    }

    closeUpdate();
  }
  function dUISlot(item) {
    setSMessage(localStorage.getItem("setting_save_slot"));
    setTimeout(() => {
      setSMessage(null);
      localStorage.removeItem("setting_save_slot");
    }, 5000);
    console.log(item);
    if (slots.filter((obj) => obj._id === item._id).length > 0) {
      let remaining = slots.filter((obj) => obj._id !== item._id);
      setSlots([...remaining]);
    }
    closeUpdate();
  }
  function dUIDiscount(item) {
    setSMessage(localStorage.getItem("setting_save_discount"));
    setTimeout(() => {
      setSMessage(null);
      localStorage.removeItem("setting_save_discount");
    }, 5000);
    console.log(item);
    if (discount.filter((obj) => obj._id === item._id).length > 0) {
      let remaining = discount.filter((obj) => obj._id !== item._id);
      setDiscount([...remaining]);
    }
    closeUpdate();
  }
  function updateSlot(item) {
    setSMessage(localStorage.getItem("setting_save_slot"));
    setTimeout(() => {
      setSMessage(null);
      localStorage.removeItem("setting_save_slot");
    }, 5000);
    console.log(item);

    let allslots = [];
    if (slots.filter((obj) => obj._id === item._id).length > 0) {
      let remaining = slots.filter((obj) => obj._id !== item._id);
      allslots = [...remaining, item]
    } else {
      allslots = [...slots, item]
    }

    try {
      let sortedlist = allslots.sort((a, b) => a.name.localeCompare(b.name));
      setSlots(sortedlist);
    } catch (e) {
      console.log("Error ::::::::::::::: " + e);
    }

    closeUpdate();
  }
  function updateAddon(item) {
    setSMessage(localStorage.getItem("setting_save_addon"));
    setTimeout(() => {
      setSMessage(null);
      localStorage.removeItem("setting_save_addon");
    }, 5000);

    console.log(item);
    let alladdons = [];
    if (addons.filter((obj) => obj._id === item._id).length > 0) {
      let remaining = addons.filter((obj) => obj._id !== item._id);
      alladdons = [...remaining, item];
    } else {
      alladdons = [...addons, item];
    }
    try {
      let sortedlist = alladdons.sort((a, b) => a.name.localeCompare(b.name));
      setAddons(sortedlist);
    } catch (e) {
      console.log("Error ::::::::::::::: " + e);
    }

    closeUpdate();
  }
  function updateDiscount(item) {
    console.log(item);
    setSMessage("Discount Updated successfully");
    setTimeout(() => {
      setSMessage(null);
      localStorage.removeItem("setting_save_plan");
    }, 5000);

    let allDiscount = [];
    if (discount.filter((obj) => obj._id === item._id).length > 0) {
      let remaining = discount.filter((obj) => obj._id !== item._id);
      console.log("remaining...");
      console.log(remaining);
      allDiscount = [...remaining, item];
    } else {
      allDiscount = [...discount, item];
    }

    try {
      let sortedplans = allDiscount.sort((a, b) => a.name.localeCompare(b.name));
      setDiscount(sortedplans);
    } catch (e) {
      console.log("Error ::::::::::::::: " + e);
    }

    closeUpdate();
  }
  function updatePlan(item) {
    console.log(item);
    setSMessage(localStorage.getItem("setting_save_plan"));
    setTimeout(() => {
      setSMessage(null);
      localStorage.removeItem("setting_save_plan");
    }, 5000);

    let allPlans = [];
    if (plans.filter((obj) => obj._id === item._id).length > 0) {
      let remaining = plans.filter((obj) => obj._id !== item._id);
      console.log("remaining...");
      console.log(remaining);
      allPlans = [...remaining, item];
    } else {
      allPlans = [...plans, item];
    }

    try {
      let sortedplans = allPlans.sort((a, b) => a.name.localeCompare(b.name));
      setPlans(sortedplans);
    } catch (e) {
      console.log("Error ::::::::::::::: " + e);
    }

    closeUpdate();
  }

  function getSlotName(slotid) {
    let tobj = slots.filter((item) => item._id === slotid)[0];
    return tobj ? tobj.name : "";
  }
  async function fetchAddons() {

    const response = await getGymAddonsList(
      localStorage.getItem("loggedUserInfo") && JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
    );
    if (!response) {
      return;
    }
    if (response === "false") {
      setApiError(true)
    } else {
      setAddons(await response.json());
      console.log("plansInfo set..");
    }
  }
  console.log(addons);



  useEffect(() => {
    async function fetchPData() {

      const response = await getPlans(
        JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
      );
      console.log(response);

      if (!response) {
        return;
      }
      if (response === "false") {
        setApiError(true)
      } else {
        const plans = await response.json();
        console.log(plans);
        if (!plans) {
          // window.alert(`info with id  not found`);
          navigate("/");
          return;
        }
        setPlans(plans);
      }
    }

    async function fetchSData() {
      const response = await getSlots(
        localStorage.getItem("loggedUserInfo") && JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
      );
      if (!response) {
        return;
      }
      if (response === "false") {
        setApiError(true)
      } else {
        const slots = await response.json();
        console.log(slots);
        if (!slots) {
          window.alert(`info with id  not found`);
          navigate("/");
          return;
        }
        if (localStorage.getItem("setting_save_addon")) {
          setSMessage(localStorage.getItem("setting_save_addon"));
          setTimeout(() => {
            setSMessage(null);
            localStorage.removeItem("setting_save_addon");
          }, 5000);
        }
        if (localStorage.getItem("setting_save_plan")) {
          setSMessage(localStorage.getItem("setting_save_plan"));
          setTimeout(() => {
            setSMessage(null);
            localStorage.removeItem("setting_save_plan");
          }, 5000);
        }
        if (localStorage.getItem("setting_save_slot")) {
          setSMessage(localStorage.getItem("setting_save_slot"));
          setTimeout(() => {
            setSMessage(null);
            localStorage.removeItem("setting_save_slot");
          }, 5000);
        }
        setSlots(slots);
      }
    }

    if (localStorage.getItem("loggedUserInfo")) {
      fetchPData();
      fetchSData();
      fetchAddons();
      fetchDData();
    } else {
      navigate("/login")
    }
  }, [navigate]);

  const [basicActive, setBasicActive] = useState('tab1');

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  console.log(plans);
  /**
 * creating array of plans data .
  * created by vamshi
 * @array
 * 
 * @description
 * This planarry has been created on [4-11-2023] to include additional plans data in to table.
 */const planarry = plans.map((item, index) => {
    const array = [];
    array.push(index + 1);
    array.push(item.name);
    array.push(`${item.numberofdays} ${item.validityflag === 1 ? "Days" : item.validityflag === 2 ? "Months" : "Years"}`);
    array.push(item.sessioncheck ? item.sessionvalue : "--");
    array.push(
      item.discount === "1" ? "5" :
        item.discount === "2" ? "10" :
          item.discount === "3" ? "15" :
            item.discount === "4" ? "50" :
              "No Discounts"
    );

    array.push(
      item.pdays && item.pdays.length > 6 ? 'All days' :
        item.pdays.map(ite => (
          ite.day === 1 ? "Sun, " :
            ite.day === 2 ? "Mon, " :
              ite.day === 3 ? "Tue, " :
                ite.day === 4 ? "Wed, " :
                  ite.day === 5 ? "Thu, " :
                    ite.day === 6 ? "Fri, " :
                      "Sat"
        ))
    );

    const tooltipContent = item.pslots
      .map(slot => (getSlotName(slot._id) !== "" && getSlotName(slot._id)))
      .filter(Boolean)
      .join(", ");

    array.push(tooltipContent);

    array.push(item.ptype === 1 ? "One-time" : "Recurring");
    array.push(item.price ? "₹" + item.price : "");
    array.push(item.taxcheck ? "Included" : "Excluded");
    array.push(item.enable ? "Enabled" : "Disabled");
    array.push(item.forcetrainer ? "Enabled" : "Disabled");
    array.push(item.autocheckin ? "Enabled" : "Disabled");

    const editIcon = (
      <MDBIcon
        fas
        icon="edit"
        className="fs-6 ps-2"
        onClick={(e) => openEditForm(e, { id: item._id })}
      />
    );
    array.push(editIcon);

    const deleteIcon = (
      <MDBIcon
        fas
        icon="trash"
        className="fs-6 ps-2"
        onClick={(e) => deleteThisPlan(e, item)}
      />
    );
    array.push(deleteIcon);

    return array;
  });

  /**
* creating array of slots data .
 * created by vamshi
* @array
* 
* @description
* This slotarry has been created on [4-11-2023] to include additional slots data in to table.
*/
  const slotArry = slots.length > 0 ? slots.map((item, index) => {
    const array = [];
    array.push(index + 1);
    array.push(item.name);
    array.push(`${item.shrs}:${item.smins === "1" ? "00" : item.smins === "2" ? "15" : item.smins === "3" ? "30" : "45"} ${item.shflag === "1" ? "AM" : "PM"} To ${item.ehrs}:${item.emins === "1" ? "00" : item.emins === "2" ? "15" : item.emins === "3" ? "30" : "45"} ${item.ehflag === "1" ? "AM" : "PM"}`);

    const editIcon = (
      <MDBIcon
        fas
        icon="edit"
        className="fs-6 ps-2"
        onClick={(e) => openSlotForm(e, { id: item._id })}
      />
    );
    array.push(editIcon);

    const deleteIcon = (
      <MDBIcon
        fas
        icon="trash"
        className="fs-6 ps-2"
        onClick={(e) => deleteThisSlot(e, item)}
      />
    );
    array.push(deleteIcon);

    return array;
  }) : [];

  /**
* creating array of addon data .
* created by vamshi
* @array
* @description
* This addary has been created on [4-11-2023] to include additional addon data in to table.
*/

  const addary = addons.map((item, index) => {
    const array = [];
    array.push(index + 1);
    array.push(item.name);
    array.push(
      item.rentbasis === "0" ? "Hourly" :
        item.rentbasis === "1" ? "Daily" :
          item.rentbasis === "2" ? "Monthly" :
            "Yearly"
    );
    array.push(item.price ? "₹" + item.price : "₹" + item.props[0].propvalue);

    array.push(item.taxcheck ? "Included" : "Excluded");
    array.push(item.enable ? "Enabled" : "Disabled"); // Vamshi

    const editIcon = (
      <MDBIcon
        fas
        icon="edit"
        className="fs-6 ps-2"
        onClick={(e) => openAddonForm(e, { id: item._id })}
      />
    );
    array.push(editIcon);

    const deleteIcon = (
      <MDBIcon
        fas
        icon="trash"
        className="fs-6 ps-2"
        onClick={(e) => deleteThisAddon(e, item)}
      />
    );
    array.push(deleteIcon);

    return array;
  });

  const disarry = discount.length > 0 ? discount.map((item, index) => {
    const array = [];
    array.push(index + 1);
    array.push(item.title);
    array.push(item.apply);
    array.push(item.discount);
    array.push(item.mingroup);
    array.push(item.packagetype);
    array.push(item.couponcode);
    array.push(item.ontimerenewal ? "Yes" : "No");
    array.push(item.primarydiscount ? "Yes" : "No");

    const editIcon = (
      <MDBIcon
        fas
        icon="edit"
        className="fs-6 ps-2"
        onClick={(e) => openDiscountForm(e, item)}
      />
    );
    array.push(editIcon);

    const deleteIcon = (
      <MDBIcon
        fas
        icon="trash"
        className="fs-6 ps-2"
        onClick={(e) => deleteThisDiscount(e, item)}
      />
    );
    array.push(deleteIcon);

    return array;
  }) : [];
  const basicData = {
    columns: [
      { label: "S.no", field: "sNo" },
      { label: "Plan Name", field: "name" },
      { label: "Validity", field: "validity" },
      { label: "Session(s)", field: "session" },
      { label: "Discount(%)", field: "discount" },
      { label: "Valid Days", field: "validDays", sort: false },
      { label: "Allowed Slot", field: "allowedSlot" },
      { label: "Plan Type", field: "membershipType" },
      { label: "Price", field: "price" },
      { label: "Tax", field: "tax" },
      { label: "Enable/Disable", field: "Enable/Disable" },
      { label: "Force Trainer", field: "forceTrainer" },
      { label: "AutoCheck-In", field: "autoCheck-In" },
      { label: "Edit", field: "edit" },
      { label: "Delete", field: "delete" },
    ],
    rows: planarry
  };
  const basicData2 = {
    columns: [
      { label: "S.no", field: "sNo" },
      { label: "Slot Name", field: "slotName" },
      { label: "Slot Time", field: "slotTime" },
      { label: "Edit", field: "edit" },
      { label: "Delete", field: "delete" },
    ],
    rows: slotArry
  };
  const basicData3 = {
    columns: [
      { label: "S.no", field: "sNo" },
      { label: "Addon Name", field: "addonName" },
      { label: "Validity", field: "validity" },
      { label: "Price", field: "price" },
      { label: "Tax", field: "tax" },
      { label: "Enable/Disable", field: "checkbox" },
      { label: "Edit", field: "edit" },
      { label: "Delete", field: "delete" },
    ],
    rows: addary
  };

  const basicData4 = {
    columns: [
      { label: "S.no", field: "sNo" },
      { label: "Title", field: "Title" },
      { label: "Apply", field: "Apply" },
      { label: "Discount", field: "Discount" },
      { label: "Min Group", field: "MinGroup" },
      { label: "Package Type", field: "PackageType" },
      { label: "Coupon Code", field: "CouponCode" },
      { label: "On Time Renewal", field: "Renewal" },
      { label: "Primary discount", field: "primarydiscount" },
      { label: "Edit", field: "edit" },
      { label: "Delete", field: "delete" },
    ],
    rows: disarry
  };
  const handleReload = () => {
    window.location.reload();
  }

  return (
    <>
      <LeftPane isactive="7" />
      <Header />
      {apiError ? <PageError handleReload={handleReload} /> :
        <div
          className="gym-main rightContent"
        >
          {" "}
          {smessage && <Toast message={smessage} />}
          <PlansHeader
            onPlanAdd={openEditForm}
            onAddonAdd={openAddonForm}
            onSlotAdd={openSlotForm}
            onAddDiscount={openDiscountForm}
          />
          <div className="remaindersmains w-100">
            {/* <MDBInputGroup
            className="mb-3"
            textAfter={<MDBIcon fas icon="search" />}
          >
            <input
              className="form-control gym-input forminput"
              type="text"
              placeholder="Search by Name"
            // onChange={(event) => inputHandler(event)}
            />
          </MDBInputGroup> */}

            <MDBTabs className='mb-3'>
              <MDBTabsItem>
                <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
                  Plans
                </MDBTabsLink>
              </MDBTabsItem>
              <MDBTabsItem>
                <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
                  Slots
                </MDBTabsLink>
              </MDBTabsItem>
              <MDBTabsItem>
                <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
                  Addons
                </MDBTabsLink>
              </MDBTabsItem>
              <MDBTabsItem>
                <MDBTabsLink onClick={() => handleBasicClick('tab4')} active={basicActive === 'tab4'}>
                  discounts
                </MDBTabsLink>
              </MDBTabsItem>
            </MDBTabs>
            <MDBTabsContent>
              <MDBTabsPane show={basicActive === 'tab1'} open={basicActive === 'tab1'}>
                <MDBDatatable className="sno PlansDataTable"
                  fixedHeader
                  entries={50}
                  searchLabel="search here..." search
                  data={basicData} />
              </MDBTabsPane>
              <MDBTabsPane show={basicActive === 'tab2'} open={basicActive === 'tab2'}>
                <MDBDatatable className="sno"
                  fixedHeader
                  entries={50}
                  searchLabel="search here..." search
                  data={basicData2} />
              </MDBTabsPane>
              <MDBTabsPane show={basicActive === 'tab3'} open={basicActive === 'tab3'}>
                <MDBDatatable className="sno addOnsDataTable"
                  fixedHeader
                  searchLabel="search here..." search
                  entries={50}
                  data={basicData3} />

              </MDBTabsPane>
              <MDBTabsPane show={basicActive === 'tab4'} open={basicActive === 'tab4'}>

                <MDBDatatable className="sno discountsDataTable"
                  fixedHeader
                  searchLabel="search here..." search
                  entries={50}
                  data={basicData4} />

              </MDBTabsPane>
            </MDBTabsContent>
          </div>
        </div>}
      {planupdate && (
        <Popup
          title="Create/Edit Plan"
          ptype="modal-lg"
          content={
            <>
              <div>
                <CreatePlan
                  id={editId}
                  onsubmit={updatePlan}
                  onclose={closeUpdate}
                />
              </div>
            </>
          }
          handleClose={closeUpdate}
        />
      )}
      {slotupdate && (
        <Popup
          title="Create/Edit Slot"
          ptype="modal-lg"
          content={
            <>
              <div>
                <CreateSlot
                  id={editId}
                  ispopup="0"
                  onsubmit={updateSlot}
                  onclose={closeUpdate}
                  oncancel={closeUpdate}
                />
              </div>
            </>
          }
          handleClose={closeUpdate}
        />
      )}

      {addonupdate && (
        <Popup
          title="Create/Edit Addon"
          ptype="modal-lg"
          content={
            <>
              <div>
                <CreateAddon
                  id={editId}
                  onsubmit={updateAddon}
                  onclose={closeUpdate}
                />
              </div>
            </>
          }
          handleClose={closeUpdate}
        />
      )}

      {createDiscount && (
        <Popup
          title="Create/Edit Discount"
          ptype="modal-lg"
          content={
            <>
              <div>
                <CreateDiscount
                  id={editId._id}
                  data={editId}
                  onsubmit={updateDiscount}
                  onclose={closeUpdate}
                />
              </div>
            </>
          }
          handleClose={closeUpdate}
        />
      )}
    </>
  );
};

export default PlansAndAddons;