import React, { useEffect,useState, forwardRef } from "react";
import PageError from "./PageError.js";
import { MDBBtn, MDBDatatable, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle, MDBTooltip } from "mdb-react-ui-kit";
import { GetBussinessInfo } from "../services/apiServices.js";
import calImg from "./../components/images/calendar.svg";
import Loader from "../components/Loader.js";
import LeftPane from "../components/LeftPane.js";
import Header from "../components/Header.js";


function BussinessReport() {
    const [bussinessInfo, setbussinessInfo] = useState([])
    const [apiError, setApiError] = useState(false);
    const [DayFilter, setDayFilter] = useState("4");
    const [loading, setLoading] = useState(false)
    const [basicData, setBasicData] = useState({
        columns: [
            { label: 'S.no', field: 'Sno' },
            { label: 'Bussness Name', field: 'bussnessName' },
            { label: 'Email', field: 'email' },
            { label: 'Admin Name', field: 'adminName' },
            { label: 'created Date', field: 'createdDate' },
            { label: 'Active Member(s)', field: 'count' },
        ],
        rows: [],
    });
    const [customdate, setcustomdate] = useState(DayFilter === "6");
    const today = new Date();
    const currentTime = new Date();
    const startOfToday = new Date(currentTime);
    startOfToday.setHours(0, 0, 0, 0);

    const endOfToday = new Date(currentTime);
    endOfToday.setHours(23, 59, 59, 999);

    const yesterday = new Date(currentTime);
    yesterday.setDate(currentTime.getDate() - 1);
    const startOfYesterday = new Date(yesterday);
    startOfYesterday.setHours(0, 0, 0, 0);

    const endOfYesterday = new Date(yesterday);
    endOfYesterday.setHours(23, 59, 59, 999);

    const startOfWeek = new Date(currentTime);
    startOfWeek.setDate(currentTime.getDate() - currentTime.getDay());
    startOfWeek.setHours(12, 0, 0, 0);
    const endOfWeek = new Date(currentTime);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(12, 0, 0, 0);

    const startOfMonth = new Date(currentTime);
    startOfMonth.setDate(1);
    startOfMonth.setHours(0, 0, 0, 0);

    const endOfMonth = new Date(currentTime);

    const startOfYear = new Date(currentTime);
    startOfYear.setMonth(0, 1);
    startOfYear.setHours(0, 0, 0, 0);

    const endOfYear = new Date(currentTime);
    endOfYear.setMonth(11, 31);
    endOfYear.setHours(23, 59, 59, 999);
    today.setHours(23, 59, 0, 0);
    const [dateRange, setDateRange] = useState([startOfMonth, endOfMonth]);
    const [startDate, endDate] = dateRange;
    const [applyFilterClicked, setApplyFilterClicked] = useState(false)
    const [resetFilterClicked, setResetFilterClicked] = useState(false)
    const [mfilter, setMfilter] = useState({
        tidName: "",
        Ttype: "",
    })
    
  

    const getDates = (obj) => {
        setDateRange(obj);
    };



    /**
* handleApplyAndToggle function is used for applying filters .
* created by vamshi
* @function
* 
* @description
* This handleApplyAndToggle function has been create on [17-7-2024] used to set the filters.
*/
    const handleApplyAndToggle = () => {
        const sDate = DayFilter === "1" ? startOfToday : (DayFilter === "2" ? startOfYesterday : (DayFilter === "3" ? startOfWeek : (DayFilter === "4" ? startOfMonth : (DayFilter === "5" ? startOfYear : (DayFilter === "6" ? startDate : null)))))
        const eDate = DayFilter === "1" ? endOfToday : (DayFilter === "2" ? endOfYesterday : (DayFilter === "3" ? endOfWeek : (DayFilter === "4" ? endOfMonth : (DayFilter === "5" ? endOfYear : (DayFilter === "6" ? endDate : null)))))
        if (sDate !== null && eDate !== null) {
            setDateRange([sDate, eDate])
        } else {
            setDateRange([])
        }

        setApplyFilterClicked(true)
    }


    /**
    * handleResetAndToggle function is used to clear filters .
    * created by vamshi
    * @function
    * 
    * @description
    * This handleResetAndToggle function has been create on [17-7-2024] used for reset the filters .
    */
    const handleResetAndToggle = () => {

        setDayFilter("4")
        setDateRange([startOfMonth, endOfMonth])
        setMfilter({
            tidName: "",
            Ttype: "",
        })
        setResetFilterClicked(true)

    }
    let filter = {}
    // console.log(mfilter);
    async function fetchData() {
        setLoading(true)
        // let startOfDate = startDate
        // let endOfDate = endDate
        // let Dateobj = {}
        // filter.name = mfilter.tidName
        // filter.Ttype = mfilter.Ttype

        // if ((startOfDate && endOfDate)) {
        //     const startDatef = new Date(startOfDate)
        //     startDatef.setHours(0, 0, 0, 0)
        //     const utcstartDate = startDatef.toISOString();
        //     let lastoftheday = new Date(endOfDate);
        //     lastoftheday.setHours(23, 59, 59, 999);
        //     const utcEndDate = lastoftheday.toISOString();
        //     Dateobj = {
        //         startDate: utcstartDate,
        //         endDate: utcEndDate,
        //     }
        // }
        // filter.dateobj = Dateobj
        const response = await GetBussinessInfo(
            JSON.parse(localStorage.getItem("loggedUserInfo")).gymid,
        );

        // console.log(response);
        if (!response) {
            return;
        }
        if (response === "false") {
            setApiError(true)
        } else {


            const record = await response.json();
            if (!record) {
                return;
            }

            console.log(record);
            setbussinessInfo(record);

        }
        setApplyFilterClicked(false)
        setResetFilterClicked(false)

    }

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        setcustomdate(DayFilter === "6" ? true : false);
    }, [DayFilter]);


    console.log(basicData);
    console.log(bussinessInfo);




    useEffect(() => {
        const popupRow = [];
        bussinessInfo && bussinessInfo && bussinessInfo.length > 0 && bussinessInfo.forEach((item, index) => {
            const rowData = {
                Sno: index + 1,
                bussnessName: item.businessName,
                email: item.businessEmail,
                adminName: item.firstName ? item.firstName + " " + item.lastName : "",
                createdDate: <span className='trans-date'>{new Date(item.createdAt).toLocaleString("en-IN", { timeZone: "Asia/Kolkata", })}</span>,
                count: item.activeMemberCount,
            };
            popupRow.push(rowData);
        });
        setBasicData(prevData => ({
            ...prevData,
            rows: popupRow
        }));
        console.log(popupRow);
        setLoading(false)

    }, [bussinessInfo]);


    const handleReload = () => {
        window.location.reload();
    }



    return (
        <>
            <LeftPane isactive="13" />
            <Header />
            {apiError ? <PageError handleReload={handleReload} /> :
                <div className="mt-4 rightContent">


                    <div className=" d-flex flex-wrap">
                        {/* <div className="gym-section w-100-mobile">
                            <label className="fw-bold">Search by Member/Member Id:</label>
                            <div>
                                <input
                                    type="text"
                                    ref={searchRef}
                                    value={mfilter.tidName}
                                    className="form-control select-input placeholder-active form-controlMobile"
                                    // className="form-control"
                                    placeholder="Search here..."
                                    onChange={(e) => setMfilter({
                                        ...mfilter,
                                        tidName: e.target.value.toLowerCase()
                                    })}
                                />
                            </div>
                        </div> */}
                        {/* <div className="gym-section w-100-mobile ms-2">
                            <label className="fw-bold">Transaction type:</label>
                            <div>
                                <select
                                    name="emailReminder"
                                    id="emailReminder"
                                    className="form-control select-input placeholder-active form-select shadow-1 form-selectMobile"
                                    value={mfilter.Ttype}

                                    onChange={(e) => setMfilter({
                                        ...mfilter,
                                        Ttype: e.target.value
                                    })}
                                >
                                    <option value="">All</option>
                                    <option value="Renew">Renew</option>
                                    <option value="New">New</option>
                                </select>
                            </div>
                        </div> */}
                        {/* <div className="gym-section w-100-mobile ms-2">
                            <label className="me-2 fw-bold"> Day: </label>
                            <div className="mob-w-100 ">
                                <select
                                    name="shrs"
                                    id="shr"
                                    className="form-control select-input form-select placeholder-active me-3 shadow-1 form-selectMobile"
                                    onChange={(e) => setDayFilter(e.target.value)}
                                    value={DayFilter}
                                >
                                    <option value="7">All</option>
                                    <option value="1">Today</option>
                                    <option value="2">Yesterday</option>
                                    <option value="3">This Week</option>
                                    <option value="4">This Month</option>
                                    <option value="5">This Year</option>
                                    <option value="6">Custom</option>

                                </select>
                            </div>
                        </div> */}
                        {/* {customdate && <div className="gym-section w-100-mobile ms-3">
                            <span className="customRange align-items-center">
                                <label className="fw-bold">Custom Range</label>
                                <div className="gym-xlarge gym-larg-pos">
                                    <DatePicker
                                        dateFormat={"d/M/yyyy"}
                                        selectsRange={true}
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={(update) => {
                                            getDates(update)
                                        }}
                                        isClearable={false}
                                        customInput={<CalenderInput />}
                                    />
                                </div>
                            </span>
                        </div>} */}
                        {/* <div className="gym-section w-100-mobile ms-3">
                            <div className="mt-3 pt-1 filterApplyMobile">
                                <button
                                    type="submit"
                                    className="me-3 btn btn-primary"
                                    onClick={() => handleApplyAndToggle()}
                                >
                                    Apply
                                </button>
                                <button
                                    type="submit"
                                    className="mt-2 btn btn-secondary"
                                    onClick={() => { handleResetAndToggle() }}
                                >
                                    Clear
                                </button>
                                {(localStorage.getItem("loggedUserInfo")) && JSON.parse(localStorage.getItem("loggedUserInfo")).role === "admin" && <button type="submit" className="ms-3 btnheight btn btn-primary"
                                    onClick={(e) => downloaddata(basicData)}
                                >
                                    <MDBIcon fas icon="download" className="d-inline me-1" />Export
                                </button>}
                            </div>
                        </div> */}
                    </div>
                    <div className="">
                        {loading ? (<div className="loader-height"> <Loader /></div>) :
                            <MDBDatatable
                                fixedHeader
                                search

                                maxHeight='calc(100vh - 223px)'
                                entries={25}
                                paging={true}
                                data={basicData}
                            // onRowClick={handleRowClick}
                            />
                        }
                    </div>
                </div>

            }

        </>
    )
}

export default BussinessReport